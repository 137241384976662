/*
LDSMediaPlayer Events
(c) Copyright 2017 by Intellectual Reserve, Inc. All rights reserved.
*/

/*
Custom progress and media events

The custom event names are based on https://developer.mozilla.org/en-US/docs/Web/Events.
*/

export const progressEvents = {
  LOADSTART: "loadstart",
  PROGRESS: "progress",
  ERROR: "error",
  TIMEOUT: "timeout",
  LOAD: "load",
  LOADEND: "loadend"
};

export const mediaEvents = {
  ENDED: "ended",
  PAUSE: "pause",
  PLAY: "play",
  PLAYING: "playing",
  SEEKED: "seeked",
  TIMEUPDATE: "timeupdate",
  BUFFERING: "buffering",
  LOADEDMETADATA: "loadedmetadata",
  VOLUMECHANGE: "volumechange"
};

export const events = { ...progressEvents, ...mediaEvents };

// Dispatches a custom event of the given `type` on the given `element` using given `detail`.
export default function dispatchEvent(element, type, detail = {}) {
  let event;

  if (typeof window.CustomEvent === "function") {
    event = new CustomEvent(type, {
      bubbles: true,
      detail: detail
    });
  } else {
    event = document.createEvent("Event");
    event.initEvent(type, true, false);
    event.detail = detail;
  }

  element.dispatchEvent(event);
}
