import { initComponent } from "../../../utilities/common";

export default class OfferSelector {
  constructor(element) {
    this.element = element;
    this.options = [...this.element.querySelectorAll(`[data-offer-info]`)];
    this.inputs = [...this.element.querySelectorAll(`input`)];
    this.form = this.element.closest("form");
    this.offerType = this.form.querySelector(`[name="offerType"]`);
    this.offerId = this.form.querySelector(`[name="offerId"]`);
    this.sourceId = this.form.querySelector(`[name="sourceId"]`);
    this.offerTypeValues = [];
    this.offerIdValues = [];
    this.sourceIdValues = [];

    this.inputs.forEach((i) => {
      i.offerType = i.value;
      delete i["value"];
    });

    this.setupInputListeners();

    this.removeValue = (array, value) => {
      if (value) {
        const index = array.indexOf(value);
        if (index > -1) {
          array.splice(index, 1);
        }
      }
    };
  }

  setupInputListeners() {
    this.inputs.forEach((i) => {
      const input = this.options.filter(
        (o) => o.dataset.offerType === i.offerType
      )[0];

      i.addEventListener("change", () => {
        if (i.checked) {
          input.dataset.offerType
            ? this.offerTypeValues.push(input.dataset.offerType)
            : "";
          input.dataset.offerId
            ? this.offerIdValues.push(input.dataset.offerId)
            : "";
          input.dataset.sourceId
            ? this.sourceIdValues.push(input.dataset.sourceId)
            : "";
          this.sourceId.value = input.dataset.sourceId;
        } else {
          this.removeValue(this.offerTypeValues, input.dataset.offerType);
          this.removeValue(this.offerIdValues, input.dataset.offerId);
          this.removeValue(this.sourceIdValues, input.dataset.sourceId);
        }
        this.offerType.value = JSON.stringify(this.offerTypeValues);
        this.offerId.value = JSON.stringify(this.offerIdValues);

        //There can only be one sourceId sent with a form request. When checkboxes are used with the offer selector component, each offer must have the same source ID
        this.sourceId.value =
          this.sourceIdValues.length > 0 ? this.sourceIdValues[0] : "";
      });
    });
  }
}

export const init = () => {
  initComponent("offer-selector", (element) => new OfferSelector(element));
};
init();
