import { remoteComponentSubscribe } from "../remote-trigger/browser";
import cookies from "../cookies/browser";
import { initComponent } from "../../utilities/common.js";
import customEvent from "../custom-event/browser";

export default class Popup {
  constructor(element) {
    this.element = element;
    this.parent = element.parentElement;
    this.notch = element.querySelector("[data-type='notch']");
    this.closeButton = element.querySelector("[data-type='close-button']");
    this.overlay = element.dataset.overlay === "true";
    this.opened = false;
    this.triggered = false;
    this.cookie = element.dataset.cookieName;
    this.cookieExists = this.cookie ? cookies.get(this.cookie) : false;
    this.desktopMargin = parseInt(this.element.dataset.desktopMargin) || 0;
    this.mobileMargin = parseInt(this.element.dataset.mobileMargin) || 0;
    this.elementClassList = this.element.classList;

    const initialStateOpened = element.dataset.initialDisplay === "true";
    this.triggerDelay = this.getDelayTime();
    this.triggerDelayType = element.dataset.triggerdelaytype || "immediate";
    this.timerStarted = false;
    this.triggerOnce = element.dataset.triggeronce === "true";

    this.resizeEvent = () => {
      this.repositionPopup();
    };

    function buttonIdMatches(target) {
      const button = target.closest("button");
      if (button) {
        const buttonId = button.dataset?.remoteTriggerId;
        return buttonId === element.id;
      } else return false;
    }

    this.clickEvent = (e) => {
      if (
        !(buttonIdMatches(e.target) || element.contains(e.target)) ||
        this.closeButton?.contains(e.target)
      ) {
        this.handleTrigger();
      }
    };

    remoteComponentSubscribe(element.id, () => {
      this.handleTrigger();
    });

    //if initial state is set to "open" on "load" display the popup
    if (initialStateOpened && !this.cookieExists) {
      this.triggerPopup();
    }
    //this.opened needs to be set to the initialState of the popup box, so that if the popup Box has already been triggered, it won't trigger again when x number of time passes
    if (this.triggerDelay && this.triggerDelayType === "immediate") {
      this.setTriggerDelayTimeout();
    }

    this.consolidationStatus = element.dataset.consolidationstatus;
  }

  getDelayTime() {
    const time = this.element.getAttribute("data-triggerDelay");
    return time ? parseInt(time) * 1000 : "";
  }

  handleTrigger() {
    if (!this.triggerOnce || (this.triggerOnce && !this.triggered)) {
      if (
        this.triggerDelay &&
        (this.triggerDelayType === "manual" ||
          (this.triggerDelayType === "immediate" && this.timerStarted))
      ) {
        this.setTriggerDelayTimeout();
      }
      this.triggerPopup();
      this.triggered = true;
    }
  }

  triggerPopup() {
    if (this.opened) {
      window.removeEventListener("resize", this.resizeEvent);
      window.removeEventListener("click", this.clickEvent);
      this.elementClassList.replace("popup_above", "popup_below"); // popup always default starts with position below
      if (this.overlay) {
        customEvent(document, "hideScrim");
      }
    } else {
      this.repositionPopup();
      window.addEventListener("resize", this.resizeEvent);
      window.addEventListener("click", this.clickEvent);
      if (this.overlay) {
        customEvent(document, "showScrim");
      }
    }
    this.element.setAttribute("data-open", !this.opened);
    this.opened = !this.opened;
    if (this.cookie && !this.cookieExists) this.setCookie();
  }

  repositionPopup() {
    // make sure block is showing before doing math on it
    this.element.style.display = "block";
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const margin = screenWidth > 600 ? this.desktopMargin : this.mobileMargin;
    const notchHalfWidth = this.notch ? this.notch.offsetWidth / 2 : "";
    const popupHalfWidth = this.element.offsetWidth / 2;
    const parentWidth = this.parent.offsetWidth;
    const parentHalfWidth = this.parent.offsetWidth / 2;
    const bounding = this.parent.getBoundingClientRect();
    const elementBounding = this.element.getBoundingClientRect();

    const parentLeft = bounding.x; //space to the left of the parent container
    const parentRight = screenWidth - (bounding.x + parentWidth); //space to the right of the parent container
    const popupViewTop = elementBounding.top; //space from top of viewport to top of popup
    const elemTop = elementBounding.top + window.scrollY; //space from top of the page to top of popup
    const elemBottom = elementBounding.bottom + window.scrollY; //space from top of the page to bottom of popup
    const popupHeight = this.element.offsetHeight;

    if (popupHalfWidth + margin > parentHalfWidth + parentLeft) {
      //When half the popup box plus it's margin does not fit within the width of half of the parent container and the space to the left of the parent container, position the popup as far left as there is space available, offsetting the popup to the right
      this.element.style.left = parentLeft * -1 + "px";
      this.element.style.marginLeft = margin + "px";
      if (this.notch) {
        this.notch.style.left =
          parentLeft + parentHalfWidth - notchHalfWidth + "px";
        this.notch.style.marginLeft = margin * -1 + "px";
      }
    } else if (popupHalfWidth + margin > parentHalfWidth + parentRight) {
      //When half the popup box plus it's margin does not fit within the width of half of the parent container and the space to the left of the parent container, position the popup as far right as there is space available, offsetting the popup to the left
      this.element.style.left =
        (popupHalfWidth * 2 - (parentRight + parentWidth)) * -1 + "px";
      this.element.style.marginLeft = margin * -1 + "px";
      if (this.notch) {
        this.notch.style.left =
          popupHalfWidth * 2 -
          (parentRight + parentHalfWidth) -
          notchHalfWidth +
          "px";
        this.notch.style.marginLeft = margin + "px";
      }
    } else {
      // When there enough space on the left and right side of the parent container to fit the popup box and it's margin, center align the popup box with the parent container
      this.element.style.left = (popupHalfWidth - parentHalfWidth) * -1 + "px";
      this.element.style.marginLeft = "0px";
      if (this.notch) {
        this.notch.style.left = popupHalfWidth - notchHalfWidth + "px";
        this.notch.style.marginLeft = "0px";
      }
    }
    // Finds the parent element of popup with overflow: hidden
    const findParentByCSSProperty = (element, property, value) => {
      while (element !== null) {
        const style = window.getComputedStyle(element);
        const propValue = style.getPropertyValue(property);
        if (propValue === value) {
          return element;
        }
        element = element.parentElement;
      }
      return null;
    };
    const result = findParentByCSSProperty(this.element, "overflow", "hidden");
    const parentHeight = result?.offsetHeight;
    // Checks if any part of the popup is being cutoff. If so, reposition the popup above

    if (
      (parentHeight &&
        parentHeight < elemBottom &&
        elemTop + popupHeight > parentHeight) ||
      popupViewTop + popupHeight > screenHeight
    ) {
      this.elementClassList.replace("popup_below", "popup_above");
    }
    // hide it after positioning in case this was just an initial position. If the popup is open, the CSS will apply the display:block to keep it open
    this.element.style.display = "";
  }

  setCookie() {
    cookies.set(this.cookie, true, 365, this.consolidationStatus);
    this.cookieExists = true;
  }

  setTriggerDelayTimeout() {
    if (!this.timerStarted) {
      this.timer = setTimeout(() => {
        this.handleTrigger();
        this.timerStarted = false;
      }, this.triggerDelay);
    } else {
      clearTimeout(this.timer);
    }
    this.timerStarted = !this.timerStarted;
  }
}

export const init = () => {
  initComponent("popup", (element) => new Popup(element));
};
init();
