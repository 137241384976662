import visuallyHidden from "../visually-hidden-text";
import formStyles from "./form-styles/styles.css";
import svg from "../svg";
import { renderChecker } from "../server.js";

export default (props) => {
  return renderChecker(component, props, "form-verification-warning");
};

export const component = ({
  label,
  verificationText,
  additionalAttributes,
  optionalDescription
}) => {
  //prettier-ignore
  return `<div data-type="verification-warning" aria-live="assertive" class="${formStyles.validityMessage} ${optionalDescription ?
    formStyles.hasOptionalDescription : ""}" ${additionalAttributes || ""}>
        ${label ? visuallyHidden({ text: label }) : ""} 
        <div class="${formStyles.errorMessage}">${svg({ name: "warning" })}${verificationText}</div>
        ${optionalDescription ? `<div class="${formStyles.optionalDescription}">${optionalDescription}</div>` : ""}

    </div>`;
};
