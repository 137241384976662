// file to help clean up the readability of the browser file
export const flickityConfig = {
  // options
  wrapAround: false,
  pageDots: false,
  contain: true,
  adaptiveHeight: true,
  //prevNextButtons: false,
  dragThreshold: 10,
  arrowShape:
    "M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
};
