function handlePredeterminedFields(fields, formData) {
  const offerDetails = [...fields].reduce((prev, curr) => {
    const guid = curr.dataset.questionGuid;
    const qDetail = curr.dataset.questionDetail;
    if (!prev.some((item) => item.questionGuid === guid) && qDetail) {
      let detail;
      try {
        detail = JSON.parse(qDetail);
      } catch (error) {
        detail = {};
      }
      let response =
        curr.type === "text"
          ? { value: curr.value }
          : { selected: formData.getAll(guid) };
      prev.push({
        ...detail,
        response
      });
      formData.delete(guid);
    }
    return prev;
  }, []);
  formData.set("offerDetails", JSON.stringify(offerDetails));
  return formData;
}

function handleAddToNotes(fields, formData) {
  let note = "";
  for (const field of fields) {
    let detail = JSON.parse(field.getAttribute("data-question-detail"));
    let fieldType = field.getAttribute("type");
    let entry = "";
    if (fieldType === "checkbox" || fieldType === "radio") {
      if (field.checked === true) {
        entry = detail["text"] + " " + field.value + "\n";
      }
    } else {
      entry = detail["text"] + " " + field.value + "\n";
    }
    note += entry;
  }
  formData.set("note", note.slice(0, 250));
  return formData;
}

function handleSubscriptionData(formData) {
  const emailSubscription = formData.get("emailSubscription");
  const phoneSubscription = formData.get("phoneSubscription");
  if (emailSubscription) {
    formData.append("subscriptions", emailSubscription);
    formData.delete("emailSubscription");
  }
  // phoneSubscription doesn't require a value (it can be a blank string) so we specifically only check if it's null
  if (phoneSubscription !== null) {
    formData.append("messagingOptIn", true);
    formData.delete("phoneSubscription");
  }
  return formData;
}

export default function formDataEntries(formElement) {
  let formData = new FormData(formElement);
  const predeterminedFields = formElement.querySelectorAll(
    "[data-question-guid]"
  );
  const addToNotes = formElement.querySelectorAll('[data-add-to-note="true"]');
  const hasSubscriptions = formElement.querySelector("[data-conditional-field");

  if (predeterminedFields.length)
    formData = handlePredeterminedFields(predeterminedFields, formData);
  if (addToNotes.length) formData = handleAddToNotes(addToNotes, formData);
  if (hasSubscriptions) formData = handleSubscriptionData(formData);
  return Array.from(formData);
}
