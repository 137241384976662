export const renderChecker = (component, props, componentName) => {
  try {
    return component ? component(props) : "";
  } catch (error) {
    /* eslint-disable */
    const req = props?.req || props?.pageGlobals?.req;
    console.error(
      JSON.stringify({
        "cuclog-error": `Component Render issue: could not render ${componentName}`,
        url: `${req?.headers?.host}${req?._parsedUrl?.pathname}`,
        error: error.message
      })
    );
    /* eslint-enable */
    // if we aren't in production, make a big ole' ugly banner so we see the issue
    if (process.env.LANE !== "prod") {
      return `<div style="
      background: linear-gradient(-45deg, #ffcc00, #ff0000, #00ff00, #0000ff);
      background-size: 300% 300%;
      animation: gradientBG 3s ease infinite;
      padding: 10px;">
        <div style="
        text-align:center;
        background: linear-gradient(45deg, #ffcc00, #ff0000, #00ff00, #0000ff);
        background-size: 400% 400%;
        animation: gradientBG ${Math.random() * (8 - 3) + 3}s ease infinite;
        color:white;
        font-size: 100%;
        padding: 28px 16px;
        font-weight: bold;">Broken component: ${componentName}<br /> ${error}</div>
      </div>
      <style>@keyframes gradientBG {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }</style>`;
    } else {
      // on the off chance something makes it to prod, don't show anything
      return `<!-- broken component: ${componentName} -->`;
    }
  }
};
