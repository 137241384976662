import { enableScroll, disableScroll } from "../page/browser";

// Handles showing / hiding an "overlay"
document.addEventListener("showScrim", (e) => {
  document.body.setAttribute("scrim", "");
  document.body.setAttribute(`${e?.detail?.scrimType || "scrim-light"}`, "");
  const scrollPosition = window.pageYOffset;
  sessionStorage.setItem("scrollPosition", scrollPosition);
  disableScroll(scrollPosition);
});

document.addEventListener("hideScrim", (e) => {
  const scrollPosition =
    sessionStorage.getItem("scrollPosition") || window.pageYOffset;
  document.body.removeAttribute("scrim");
  document
    .querySelector("body")
    .removeAttribute(`${e?.detail?.scrimType || "scrim-light"}`);
  enableScroll(scrollPosition);
  sessionStorage.removeItem("scrollPosition");
});
