import styles from "./styles.css";
import { genericResult } from "./genericResult";
import { getParam, initComponent } from "../../utilities/common";
import { postAnalytics } from "../../utilities/analytics";

const RESULT_TYPES = {
  GENERIC: "GENERIC"
};

function functionForType(type) {
  switch (type) {
    case RESULT_TYPES.GENERIC:
      return genericResult;
  }
}

export default class SearchResults {
  constructor(element) {
    this.ENTER_KEY = 13;

    this.element = element;
    this.numResults = parseInt(this.element.dataset.numResults);

    this.currentResultType = RESULT_TYPES.GENERIC;
    this.searchResults = [
      ...this.element.querySelectorAll(`[data-search-result]`)
    ];
    this.noSearchResultsMessage = this.element.querySelector(
      `.${styles.noResultsMessage}`
    );

    this.currentPage = 0;
    this.pageButtons = this.element.querySelectorAll(`[data-results-page]`);

    this.customBSCIdField = this.element.querySelector(`[name=customBSCId]`);
    this.marketField = this.element.querySelector(`[name=market]`);
    this.customBSCId = this.customBSCIdField.value;
    this.market = this.marketField.value;

    this.leftArrow = this.element.querySelector("[data-result-left-arrow]");
    this.rightArrow = this.element.querySelector("[data-result-right-arrow]");

    this.facet = RESULT_TYPES.GENERIC;

    this.numPages = 0;

    this.searchResults.forEach((r) => {
      r.hidden = true;
    });

    this.processURLQuery();
    this.setupListeners();
  }

  // eslint-disable-next-line class-methods-use-this
  get currentQuery() {
    // The query parameter is updated by the search field in the pager-header-nav component
    return getParam("query");
  }

  search({ query, offset = 0 }) {
    if (query && query.length > 0) {
      query = query.trim();
      const req = new XMLHttpRequest();
      req.addEventListener("load", () => {
        postAnalytics({
          event: window.digitalDataEventsCUC.search,
          search: {
            info: {
              term: query,
              results: req.response?.matches || 0
            },
            category: { primary: "keyword" }
          }
        });
        if (req.status === 200) {
          const { results, matches } = req.response;
          //Makes sure we never have too many results. We may want to do this differently.
          this.loadNewResults({ results: results.splice(0, this.numResults) });

          if (offset === 0) {
            this.changePage(this.pageButtons[0], 0);
            this.addOrRemovePages(matches);
          }
        } else {
          this.loadNoResults();
        }
      });

      const endpoint =
        `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/search` +
        `?query=${query}` +
        `&offset=${offset}` +
        `&numResults=${this.numResults}` +
        `&facet=${this.facet}` +
        `&customBSCId=${this.customBSCId}` +
        `&market=${this.market}`;

      req.open("GET", endpoint);
      req.responseType = "json";
      req.send();
    }
  }

  loadNewResults({ results }) {
    this.noSearchResultsMessage.hidden = true;
    const resultBuilder = functionForType(this.facet);

    results.forEach((r, index) => {
      this.searchResults[index].innerHTML = "";
      let template = document.createElement("template");
      template.innerHTML = resultBuilder(r).trim();
      this.searchResults[index].appendChild(template.content.firstChild);
      this.searchResults[index].hidden = false;
    });

    let numResults = results.length;
    this.searchResults.forEach((r, index) => {
      if (index >= numResults) r.hidden = true;
    });
  }

  loadNoResults() {
    this.searchResults.forEach((r) => {
      r.innerHTML = "";
      r.hidden = true;
    });

    this.noSearchResultsMessage.hidden = false;

    this.addOrRemovePages(0);
  }

  addOrRemovePages(numMatches) {
    this.numPages =
      Math.floor(numMatches / this.numResults) +
      (numMatches % this.numResults > 0 ? 1 : 0);
    this.numPages = this.numPages >= 5 ? 5 : this.numPages;

    this.pageButtons.forEach((b, index) => {
      b.hidden = index >= this.numPages;
    });
  }

  processURLQuery() {
    this.search({ query: this.currentQuery });
  }

  changePage(nextPage, index) {
    this.pageButtons[this.currentPage].classList.remove(styles.activePage);
    nextPage.classList.add(styles.activePage);
    this.currentPage = index;
  }

  changePageAndSearch(nextPage, index) {
    this.changePage(nextPage, index);

    this.search({
      query: this.currentQuery,
      offset: this.currentPage * this.numResults
    });
  }

  setupListeners() {
    this.element.addEventListener("updated-search-query", () =>
      this.processURLQuery()
    );

    this.pageButtons.forEach((b, index) => {
      b.addEventListener("click", () => {
        this.changePageAndSearch(b, index);
      });
    });

    this.leftArrow.addEventListener("click", () => {
      if (this.currentPage !== 0) {
        const index = this.currentPage - 1;
        const button = this.pageButtons[index];
        this.changePageAndSearch(button, index);
      }
    });

    this.rightArrow.addEventListener("click", () => {
      if (this.currentPage < this.numPages - 1) {
        const index = this.currentPage + 1;
        const button = this.pageButtons[index];
        this.changePageAndSearch(button, index);
      }
    });
  }
}

export const init = () => {
  initComponent("search-results", (element) => new SearchResults(element));
};
init();
