import { onUpOrDown } from "../scroll-logic/browser";
import styles from "./styles.css";
import stylesCtaLink from "../persistent-cta-link/styles.css";
import { initComponent } from "../../utilities/common.js";

function scrollFunctions(element) {
  const collapse = () => {
    element.classList.add(styles.collapsedText);
    element.classList.add(stylesCtaLink.collapsedText);
  };
  const expand = () => {
    element.classList.remove(styles.collapsedText);
    element.classList.remove(stylesCtaLink.collapsedText);
  };
  onUpOrDown(collapse, expand);
}

export const init = () => {
  initComponent("persistent-cta", (persistentCta) => {
    scrollFunctions(persistentCta);
    setTimeout(() => {
      // delay removal of hidden a bit for css to apply and not cause a Content Layout Shift
      persistentCta.removeAttribute("hidden");
    }, 500);
  });
};
init();
