import styles from './styles.css';

export default class previewBack {
    constructor(element) {
        
        this.element = element;
        
        // client current url
        const hostname = window.location.hostname;
        const href = window.location.href;

        // client url must be in preview lanes
        if(hostname.includes("preview") || href.includes('previewback=true')) {
            
             // display go back by removing css that hide it
            this.element.classList.remove(`${styles.bannerGoBackHide}`);

            if(hostname.includes("preview")){
                // display the editable url depending of environment test, dev, or more
                let modes = this.element.querySelectorAll('a');

                modes.forEach(function(mode){
                    if(hostname.includes(mode.getAttribute('data-mode')))
                        mode.classList.remove(`${styles.bannerGoBackHide}`);
                });
            } else {
                this.element.querySelector('a').classList.remove(`${styles.bannerGoBackHide}`);
            }
        }
    }
}

[...document.querySelectorAll(`[data-type="preview-back"]`)].forEach((el) => new previewBack(el));