import MakeFit from "../make-fit/browser";
const boldStylesPrefix = "bold-display_";
import { initComponent } from "../../utilities/common";

export default class EmphasizedMediaTiles {
  constructor(element) {
    // only hook up ones that are bold-display
    // this needs to be called at the tileS level because the sync option needs to have them all in one instantiation
    new MakeFit(
      element.querySelectorAll("[bold-display-text-wrapper] [max-lines-fader]"),
      {
        sync: true,
        alsoAddClassTo: (elem) => elem.closest("[bold-display-text-wrapper]"),
        sizeClasses: `${boldStylesPrefix}smallDisplay ${boldStylesPrefix}smallerDisplay`,
        cushion: 3 // opensans has some weird metrics that causes it to be a few pixels off. This compensates for it. It is small enough that it won't be a whole row off and will still work for McKay as well.
      }
    );
  }
}
export const init = () => {
  initComponent(
    "emphasized-media-tiles",
    (element) => new EmphasizedMediaTiles(element)
  );
};
init();
