import { remoteComponentSubscribe } from "components/remote-trigger/browser";
import { initComponent } from "../../utilities/common";
import { postAnalytics } from "../../utilities/analytics";

export default class Chat {
  constructor(element) {
    this.injected = false;
    this.element = element;
    this.chatIframeWrapper = element.querySelector("[chatIframeWrapper]");
    this.churchChatLoaded = false;
    const chatIframeWrapper = this.chatIframeWrapper;
    const closeButton = chatIframeWrapper.querySelector("button");
    // get the PCTA FAB if it exists
    const pcta = document.querySelector("[data-type='persistent-cta'] [extra]");
    if (pcta) {
      // move chat into the PCTA so the FAB can react to it on mobile
      pcta.appendChild(element);
    }
    const fab = element.querySelector("[data-type='floating-action-button']");
    const docElem = document.documentElement;
    const showChat = document.querySelector("body[showchat]");

    // hack for ios 100vh bug https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
    const appHeight = () => {
      docElem.style.setProperty("--app-height", `${window.innerHeight}px`);
      // in case they open chat and reseize browser after that ... for some reason
      if (!sessionStorage.getItem("chatOpen") && !fab) {
        fab.show();
      }
      if (window.innerHeight >= 600) {
        fab.hide();
      }
      if (
        (window.innerWidth <= 599 || window.innerHeight <= 599) &&
        sessionStorage.getItem("chatOpen")
      ) {
        chatIframeWrapper.style.bottom = 0;
      }
    };

    chatIframeWrapper.show = () => {
      this.element.setAttribute("aria-hidden", "false");
      sessionStorage.setItem("showChat", "true");
    };
    chatIframeWrapper.open = () => {
      if (!this.injected) {
        this.injectChurchChat();
        this.injected = true;
      }
      chatIframeWrapper.setAttribute("aria-hidden", "false");
      if ((fab && window.innerWidth <= 599) || window.innerHeight <= 599) {
        fab.hide();
      } else {
        fab.show();
        fab.open();
      }
      window.addEventListener("resize", appHeight);
      appHeight();
      sessionStorage.setItem("chatOpen", "true");
    };
    chatIframeWrapper.close = () => {
      chatIframeWrapper.setAttribute("aria-hidden", "true");
      fab.show();
      window.removeEventListener("resize", appHeight);
      docElem.style.setProperty("--app-height", `100vh`);
      sessionStorage.removeItem("chatOpen");
      fab.close();
      this.sendChatAnalytics("close");
    };
    chatIframeWrapper.toggle = () => {
      if (chatIframeWrapper.getAttribute("aria-hidden") === "true") {
        chatIframeWrapper.open();
      } else {
        chatIframeWrapper.close();
      }
    };
    chatIframeWrapper.injectChurchChat = () => {
      this.injectChurchChat();
    };
    remoteComponentSubscribe(element.id, () => {
      chatIframeWrapper.toggle();
    });
    closeButton.addEventListener("click", () => {
      chatIframeWrapper.close();
      fab && fab.close();
    });
    // chatOpen means it should open the chat, meaning it was likely open on the previous page
    if (sessionStorage.getItem("chatOpen")) {
      // if we need to open the chat window because it was open on a previous page load, do it onload to make sure things are available.
      window.addEventListener("load", () => {
        chatIframeWrapper.open();
        fab && fab.open();
      });
    }
    // showChat (var or session) means to visibly put the chat into the page
    if (showChat || sessionStorage.getItem("showChat")) {
      window.addEventListener("load", () => {
        chatIframeWrapper.show();
      });
    }
    this.sendChatAnalytics = (event) => {
      postAnalytics({
        event: window.digitalDataEventsCUC.component.chat,
        component: {
          info: {
            name: "cuc",
            adobeId: window._satellite?.getVar("user_experience_cloud_id"),
            action: event
          },
          category: {
            primary: "chat"
          }
        }
      });
    };
  }
  injectChurchChat = () => {
    if (this.churchChatLoaded || document.querySelector("#churchchat")) {
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const lang = document
      ?.querySelector('meta[property="omni:language"]')
      ?.getAttribute("content");
    const adobeID = window._satellite?.getVar("user_experience_cloud_id");
    const page = encodeURIComponent(location.origin + location.pathname);
    const cid = searchParams.get("cid");
    const iframeSrc = `https:${
      window.PUBLIC_ENV.CHAT_ORIGIN
    }?lang=${lang}&embedded=true&adobeId=${adobeID}&startBot=true&source=${page}${
      cid ? `&cid=${cid}` : ""
    }`;
    this.chatIframeWrapper.insertAdjacentHTML(
      "afterBegin",
      `<iframe id="churchchat" allowfullscreen src="${iframeSrc}"/>`
    );
    this.sendChatAnalytics("open");
    window.addEventListener(
      "message",
      (event) => {
        const messageHostname = event.origin;
        if (messageHostname !== window.location.origin) {
          return;
        }
        if (
          event?.data?.action === "sent_first_message" &&
          !sessionStorage.getItem("sentFirstChatTracker")
        ) {
          this.sendChatAnalytics("start");
          sessionStorage.setItem("sentFirstChatTracker", "true");
        }
        if (event?.data?.action === "left_message") {
          const parentWrapper = window.parent.document.querySelector(
            "[chatIframeWrapper]"
          );
          const parentFab = window.parent.document.querySelector(
            "[data-type='floating-action-button']"
          );
          parentWrapper.setAttribute("aria-hidden", "true");
          parentFab.show();
          parentFab.close();
        }
      },
      false
    );
  };
}

export const init = () => {
  initComponent("chat", (element) => new Chat(element));
};
init();
