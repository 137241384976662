import { initComponent } from "../../../utilities/common";
import { Dependencies } from "../display-dependency/browser";

export default class OfferDetailsList {
  constructor(element) {
    this.displayDependencies = new Dependencies(element).watch();
  }
}

export const init = () => {
  initComponent(
    "offer-details-list",
    (element) => new OfferDetailsList(element)
  );
};
init();
