import { initComponent, callbackWhenInView } from "../../utilities/common";
import { remoteComponentSubscribe } from "../remote-trigger/browser";
export default class SmallCta {
  constructor(element) {
    this.element = element;
    this.collapse = this.element.hasAttribute("collapse");

    const mediaBlock = element.closest("[data-type='media-block']");
    // if inside a media-block hook up some more stuff
    if (mediaBlock) {
      // when acting as a VideoCTA it will be hidden until the right moment in the video to show. callbackWhenInView will allow us to wait to fire things until it has been injected at that right time
      callbackWhenInView({
        elems: [element],
        callback: () => {
          this.initialDisplay();
          // hook up pausing video when cta is clicked
          const remoteTriggerId = element.dataset.remoteTriggerId;
          remoteTriggerId &&
            remoteComponentSubscribe(remoteTriggerId, () => {
              element
                .closest("[data-type='media-block']")
                .querySelector("[data-type='media-player']")
                .pausePlayer();
              // if fullscreen is currently enabled
              // webkit is prefixed so need to check for both non-prefixed and prefixed
              if (
                document.fullscreenElement ||
                document.webkitFullscreenElement
              ) {
                const exitFS =
                  document.exitFullscreen || document.webkitExitFullscreen;
                // exit fullscreen
                exitFS.call(document);
              }
            });
        },
        root: mediaBlock,
        rootMargin: "0px"
      });
    } else {
      // otherwise just run the initial display
      this.initialDisplay();
    }

    element.show = () => this.showCTA();
  }
  initialDisplay = () => {
    if (this.collapse) {
      // to get initial transition in, it needs to be showing already, so delay just a bit
      setTimeout(() => {
        this.element.setAttribute("injected", "");
      }, 100);
      setTimeout(() => {
        this.element.removeAttribute("open");
      }, this.element.getAttribute("data-close-delay") * 1000);
    }
  };
}
export const init = () => {
  initComponent("small-cta", (element) => new SmallCta(element));
};
init();
