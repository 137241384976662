// This file is what is used to populate the main.js file. It references all browser.js files (which in turn includes any index.js and styles.css file they reference) and anything else directly referenced here.
// TODO: how to only include the specific lines of a style.css file that are referenced, not every class defined in the file
import Campaigner from "../components/campaigner/browser";

// Set campaign cookies
new Campaigner({
  id: "cid",
  cookieName: ["cid", "CID"],
  cookieDays: 365
});

new Campaigner({
  id: "mdcid",
  cookieName: ["mdcid", "MDCID"],
  cookieDays: null
});

window.cuc = window.cuc || {}; // setup a place we can store some "global" (in a cuc scope) stuff
// TODO: Move this somewhere?
// The media-player component needs some settings:
import mediaPlayer from "../components/media-player/browser";
mediaPlayer({
  youTubeOptions: {
    playsInline: 0
  }
});

// Require all the component "browser.js" files and put their exports in the `components` map using the component types as the keys.
const components = {};
const requireJS = require.context("../components", true, /browser\.js?$/);
requireJS.keys().forEach((filename) => {
  const type = filename.match(/\.\/([-a-z0-9]+)\//)[1];
  components[type] = requireJS(filename);
});

// save off a copy of the components into a global so they can be re-run as needed
window.cuc.components = components;

// For a given DOM element, finds the corresponding browser constructor and calls it.
export function setup(element) {
  const type = element.getAttribute("data-type");

  new components[type].default(element);
}

// Handles rendering the given `data`, adding it to the innerHTML of `container`, and then calling the component's constructor so it's browser JS is setup.
export function render(container, data) {
  if (typeof data === "string") {
    container.innerHTML = data;
  } else {
    const { type } = data;

    if (!type) {
      return;
    }

    const { render } = components[type];

    if (render) {
      container.innerHTML = render(data);
      setup(container.querySelector(`[data-type="${type}"]`));
    }
  }
}

// setup a way to re-run all inits. Not wise at this point as many need logic added to not run on the same component multiple times, but is here for if someone has a specific reason to do it
window.cuc.reInit = () => {
  for (const key in components) {
    components[key].init && components[key].init();
  }
};

// setup one for the fab for some AB testing. Could likely remove after 2020 LTW is done
window.cuc.setupFab = () => {
  components["cookie-based-display"].init(); // cuc.cbdInit();
  components["floating-action-button"].init(); // cuc.fabInit();
  components["popup"].init(); // cuc.popupInit();
  components["remote-trigger"].init(); // cuc.rtInit();
  components["speed-dial"].init(); // cuc.speedDialInit();
  components["icon"].init(); // cuc.iconInit();
};
