import LDSMediaPlayer from "../media-player/lds-media-player/lds-media-player";
import YouTube from "../media-player/lds-media-player/adapters/youtube";
import Brightcove from "../media-player/lds-media-player/adapters/brightcove";
import {
  mediaEvents,
  progressEvents
} from "../media-player/lds-media-player/events";
import {
  callbackWhenInView,
  checkYoutube,
  initComponent,
  sendMediaAnalytics
} from "../../utilities/common";
import { remoteComponentSubscribe } from "components/remote-trigger/browser";

// One way to handle site-specific configuration. In whatever file is actually adding this module to the browser, they can call the default export and add configuration.

export default class Video {
  constructor(element) {
    this.element = element;
    this.playerElement = element.querySelector("[data-player]");
    this.loadingElement = element.querySelector("[data-loading]");
    this.errorElement = element.querySelector("[data-error]");
    this.remoteTriggerId = element.getAttribute("id");
    this.setupPlayer();
    element.setAttribute("initialized", "");
  }

  async setupPlayer() {
    let youTubeOptions = { playsInline: true };
    let brightcoveOptions = {};
    let brightcovePlayerOptions = {
      embed: "default",
      playsInline: true // required for iOS to autoplay (using the play() function can be considered autoplay in some circumstances)
    };

    // Get the media ids
    const media = [];

    const isLooping = this.element.hasAttribute("looping");
    let mediaData = this.element.getAttribute("data-media");

    const {
      youTubePlaylistId,
      youTubeId,
      brightcoveId,
      brightcoveAccountId,
      brightcovePlayerId,
      autoplay,
      captions
    } = JSON.parse(mediaData);

    remoteComponentSubscribe(this.remoteTriggerId, () => {
      // Sometimes the remote trigger id is actually the parent of the video,
      // this prevents that initial trigger from continuing to mess with the player's own play/pause click events
      if (!this.playerElement.querySelector("video")) {
        this.playerElement.addEventListener("click", (event) =>
          event.stopImmediatePropagation()
        );
      }

      // Toggle play or pause based on current video state
      this.element.querySelector("[data-player]").hasAttribute("playing")
        ? this.playerElement.videoPause()
        : this.playerElement.videoPlay();
    });

    // setting properties for brightcove account ID and Player ID from props passed in
    brightcovePlayerOptions.account = brightcoveAccountId;
    brightcovePlayerOptions.player = brightcovePlayerId;

    let youTubePlaylistOptions = {
      list: youTubePlaylistId,
      listType: "playlist"
    };

    if (isLooping) {
      // hide the background video until it is ready to play, so the background image can display until it is ready. Need to use opacity as there appears to be visibility detection and it doesn't load if it is hidden via display:none. Opacity gets around that
      this.element.style.opacity = ".01";

      brightcovePlayerOptions = {
        ...brightcovePlayerOptions,
        autoplay: true,
        loop: true,
        muted: true,
        controls: false,
        dispatchEvents: autoplay,
        captions
      };
    }

    const ytAvailable = await checkYoutube();
    const ytId = youTubeId || youTubePlaylistId;
    let ytOptions = youTubePlaylistId ? youTubePlaylistOptions : youTubeOptions;
    const overrideYT = window.location.search.indexOf("player=yt") !== -1;

    if (autoplay) {
      ytOptions = {
        ...ytOptions,
        cc_load_policy: captions ? 1 : 0
      };

      if (!isLooping) {
        brightcovePlayerOptions = {
          ...brightcovePlayerOptions,
          captions
        };
      }
    }

    // Save bcAsset as a variable to send in download button
    const bcAsset =
      brightcoveId && brightcoveOptions
        ? new Brightcove(brightcoveId, brightcovePlayerOptions)
        : null;
    const useBrightcove =
      (isLooping || !youTubePlaylistId) && brightcoveId && !overrideYT;

    if (useBrightcove) media.push(bcAsset);
    if (ytAvailable && ytId) media.push(new YouTube(ytId, ytOptions));
    if (!useBrightcove && !ytAvailable && !ytId)
      throw new Error("No media added to player");

    // Sends video analytics for video events
    const sendAnalytics = (event, eventDetails) => {
      const { position: currentTime, duration, title: name } = eventDetails;
      sendMediaAnalytics({
        playerElement: this.playerElement,
        event,
        detail: {
          platform: media[0]?.platform?.name,
          videoId: media[0]?.platform?.id,
          currentTime,
          duration,
          name,
          ...(useBrightcove && {
            playerVersion:
              bcAsset?.brightcovePlayer?.bcAnalytics?.settings?.platformVersion // Only Brightcove has a player version
          })
        }
      });
    };

    this.playerElement.addEventListener(mediaEvents.PLAY, (event) => {
      if (!isLooping || brightcovePlayerOptions.dispatchEvents) {
        pauseAnyOtherPlayer(this.playerElement);
        this.playerElement.setAttribute("playing", "");
        sendAnalytics("unityplay", event.detail);
      }
    });

    // Use the `progress` event to hide any error element and show the loading element.
    this.playerElement.addEventListener(progressEvents.PROGRESS, () => {
      if (this.errorElement) this.errorElement.hidden = true;
      if (this.loadingElement) this.loadingElement.hidden = false;
    });

    // Show an error when something goes wrong.
    this.playerElement.addEventListener(progressEvents.ERROR, (event) => {
      if (this.errorElement) this.errorElement.hidden = false;
      sendAnalytics("unityerror", event.detail);
    });

    // Hide the loading element once the `loadend` event has dispatched.
    this.playerElement.addEventListener(progressEvents.LOADEND, () => {
      if (this.loadingElement) this.loadingElement.hidden = true;
    });

    // Corresponds to the native ended event which fires when playback stops at the end or no further data is available.
    this.playerElement.addEventListener(mediaEvents.ENDED, (event) => {
      sendAnalytics("unityended", event.detail);
    });

    // Triggers analytics for when the video is playing
    this.playerElement.addEventListener(mediaEvents.PLAYING, (event) => {
      if (!isLooping) sendAnalytics("unityplaying", event.detail);
    });

    // Corresponds to the native loadedmetadata event which fires when the metadata has been loaded. YouTube won't trigger this properly so we manually trigger the "loadedmetadata" event in the YouTube.js file
    this.playerElement.addEventListener(mediaEvents.LOADEDMETADATA, (event) => {
      sendAnalytics("unityloadedmetadata", event.detail);
    });

    // Corresponds to the native timeupdate event which fires when the video's current time has updated (e.g., periodically during playback).
    this.playerElement.addEventListener(mediaEvents.TIMEUPDATE, (event) => {
      sendAnalytics("unitytimeupdate", event.detail);
    });

    this.playerElement.addEventListener(mediaEvents.PAUSE, (event) => {
      this.playerElement.removeAttribute("playing");
      sendAnalytics("unitypause", event.detail);
    });

    this.playerElement.addEventListener(mediaEvents.VOLUMECHANGE, () => {
      this.playerElement.toggleAttribute("muted");
    });

    // Create the media player
    const player = new LDSMediaPlayer(this.playerElement, {
      media
    });

    // load but don't show
    this.playerElement.videoPreload = () => {
      player.load();
    };
    // load and show
    this.playerElement.videoLoad = () => {
      this.showPlayer();
      player.load();
    };
    // load, show and play
    this.playerElement.videoPlay = () => {
      this.showPlayer();
      player.play();
    };
    // load, show and restart at specified time (defaults to 0)
    this.playerElement.videoRestart = (time = 0) => {
      this.showPlayer();
      player.restart(time);
    };
    // load and seek specific time
    this.playerElement.videoSeek = (time) => {
      this.showPlayer();
      player.seek(time);
    };
    this.playerElement.videoPause = () => player.pause();
    this.playerElement.videoToggleMute = (force) => player.toggleMute(force);

    if (isLooping) {
      callbackWhenInView({
        elems: [this.playerElement],
        callback: () => {
          this.playerElement.videoPlay();
        },
        rootMargin: "500px"
      });
    }
  }
  showPlayer() {
    this.element.hidden = false;
  }
}

const pauseAnyOtherPlayer = (playerUserClicked) => {
  document.querySelectorAll('[data-type="video"] [playing]').forEach((el) => {
    if (!el.isSameNode(playerUserClicked)) el.videoPause?.();
  });
};

export const init = () => {
  initComponent("video", (element) => new Video(element));
};
init();
