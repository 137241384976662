import styles from "./styles.css";
import invertTextStyles from "../../invert-text-color";
import visuallyHiddenTextStyles from "../../visually-hidden-text/critical.css";
import { renderChecker } from "../../server.js";

export default (props) => {
  return renderChecker(component, props, "form-label");
};

export const component = (props) => {
  const { id, label, invertTextColor, extraClass, hideLabel } = props;
  // if we don't have a label, don't return anything
  if (!label) return "";
  // prettier-ignore
  return `
  <label class="${styles.label} ${invertTextStyles(invertTextColor)} ${hideLabel ? visuallyHiddenTextStyles.hide : ""} ${extraClass || ""}" ${id ? `for="${id}"` : ""}>${label}</label>`;
};
