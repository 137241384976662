import { remoteComponentSubscribe } from "../remote-trigger/browser";
import {
  initComponent,
  setTabIndex,
  toggleTrueFalseAttribute
} from "../../utilities/common";
export default class Drawers {
  constructor(element) {
    this.drawers = [
      ...element.querySelectorAll(
        '[data-type="drawer-body"]:not([drawerBodyInitialized])'
      )
    ];
    this.onlyOne = element.dataset.onlyOne === "true"; //This is for displaying only one drawer at a time
    this.formVariant = element.dataset.formVariant === "true"; // This lets us know if the drawer is a stepped-form variant which requires validation
    this.targetDrawer;
    this.trackPrefix = "track-";

    // Remove ability to tab through content of closed drawers
    setTabIndex(this.drawers, -1);

    this.drawers.forEach((drawer) => {
      const trigger = drawer.parentNode.querySelector(
        '[data-type="drawer-opener"]'
      );
      drawer.trigger = trigger;
      remoteComponentSubscribe(drawer.id, () => {
        if (this.formVariant) return; // Forms navigate with their own logic, utilizing the toggle function
        // Don't allow the drawer to trigger again until it has finished changing it's state, allowing the drawer to trigger before the drawer state has finished changing prevents the drawers from opening correctly. This happens when a user tries to double click the drawer.
        this.changeDrawerState(drawer, drawer);
        trigger?.focus();
      });
      // check if drawers are tracked and mark accordingly
      if (
        trigger?.hasAttribute("trackable") &&
        !this.formVariant &&
        localStorage.getItem(
          `${this.trackPrefix}${trigger.dataset.remoteTriggerId}`
        )
      ) {
        trigger.setAttribute("opened", "");
      }

      // allow external components to access/trigger functions
      drawer.toggle = () => this.changeDrawerState(drawer, drawer);
      drawer.isOpen = () => drawer.dataset.displayState === "opened";
      drawer.setValid = (validity) => {
        drawer.trigger.setAttribute("validated", "");
        drawer.trigger.dataset.isValid = validity;
      };
      drawer.isValid = () => drawer.trigger.dataset.isValid === "true";

      // on init track the open if one defaults to open
      if (trigger && drawer.isOpen()) {
        this.trackOpen(trigger);
      }

      drawer.setAttribute("drawerBodyInitialized", "");
      this.goToAnchor(drawer);
    });
  }

  // When the drawer is opening, after the drawer has finished it's transition, the max height needs to be removed so that if content inside the drawer changes size (such as the stepped form), the drawer will resize with it
  // When the drawer is closing, the max height needs to be set to the scroll height initially and then the setTimeout function is used to set the max height to zero, forcing the closing transition to happen on the drawer.
  // Without setting the max height initially to the scroll height the transition will not work going from "auto" to "0"
  // The timeout time is set to 200 when it opens because that is how long the transition is set on the drawer in the stylesheet (if that updates in the stylesheet, it must be updated here and vice versa)
  // The timeout time is set to 50 when it closes just so that the max height can be set long enough before the max height changes to 0

  changeDrawerState(drawer, targetDrawer) {
    const onlyOnce = drawer.hasAttribute("data-only-once");
    const doneOnce = drawer.doneOnce;
    const trigger = drawer.trigger;
    // flkty and steppedForm fix certain Aria errors with elements being tababble inside closed drawers
    const flkty = drawer.querySelector(
      "[data-type='enhanced-ui-slider-flickity']"
    );
    const steppedForm = drawer.querySelector(".stepped-form_stepCounter");

    // if we are opening the drawer
    if (trigger?.hasAttribute("trackable") && !drawer.isOpen()) {
      this.trackOpen(trigger);
    }
    if (!onlyOnce || (onlyOnce && !doneOnce)) {
      drawer.doneOnce = true;
      this.targetDrawer = targetDrawer;
      const newState = drawer.isOpen() ? "closed" : "opened";

      // Remove ability to tab through drawer when it is closed, add ability back when it is open
      if (newState === "opened") {
        setTabIndex([drawer], 0);
        flkty?.setAttribute("tabindex", "0");
        steppedForm?.setAttribute("tabindex", "0");
        drawer.setAttribute("aria-hidden", "false");
      } else {
        setTabIndex([drawer], -1);
        flkty?.setAttribute("tabindex", "-1");
        steppedForm?.setAttribute("tabindex", "-1");
        drawer.setAttribute("aria-hidden", "true");
        if (drawer?.trigger?.hasAttribute("validated")) {
          this.trackOpen(trigger, drawer.isValid());
        }
      }

      this.scrollHeight = drawer.scrollHeight;

      drawer.style.maxHeight = this.scrollHeight + "px";
      // if there is a css variable for drawerSpeed, use it
      let drawerSpeed =
        getComputedStyle(drawer).getPropertyValue("--drawerSpeed");
      if (drawerSpeed) {
        drawerSpeed = parseFloat(drawerSpeed.replace("s", "")) * 1000;
      }

      let time = newState === "closed" ? 50 : drawerSpeed || 200;
      // if operating system preferences are set to reduce motion, state change will occur instantly
      if (matchMedia("(prefers-reduced-motion)").matches) time = 0;

      setTimeout(() => {
        drawer.style.maxHeight = newState === "closed" ? 0 : "none";
      }, time);

      drawer.setAttribute("data-display-state", newState);

      const container = drawer.closest('[data-type="drawers-container"]');
      container.setAttribute("data-body-display-state", newState);

      const button = drawer.previousElementSibling;
      if (button) toggleTrueFalseAttribute(button, "aria-expanded");

      // This is for displaying one drawer at a time, currently this functionality is not available for drawers built with in Publisher because only one drawer can be built at a time in Publisher
      if (this.onlyOne && drawer === this.targetDrawer) {
        this.closeDrawers();
      }
    }
  }

  // This function is for displaying one drawer at a time, currently this function does not work for drawers built with in Publisher because only one drawer can be built at a time in Publisher
  closeDrawers() {
    this.drawers
      .filter((d) => d !== this.targetDrawer && d.isOpen())
      .forEach((d) => this.changeDrawerState(d, this.targetDrawer));
  }

  trackOpen(trigger, isValid = false) {
    if (this.formVariant && !isValid) return;

    trigger.setAttribute("opened", "");

    // Forms don't need localStorage tracking of drawers so we'll stop it here
    if (this.formVariant) return;

    localStorage.setItem(
      `${this.trackPrefix}${trigger.dataset.remoteTriggerId}`,
      "opened"
    );
    // track all drawers for the page
    // first see how many drawers are tracked on the page
    const trackedElems = document.querySelectorAll("[trackable]");
    if (trackedElems) {
      const trackedElemsCount = trackedElems.length;
      const trackedElemsOpened = document.querySelectorAll(
        "[trackable][opened]"
      );

      // log it into localStorage so it can be accessed on other pages
      // use the page path as the identifier
      localStorage.setItem(
        `track-${window.location.pathname}`,
        `${trackedElemsOpened.length}/${trackedElemsCount}`
      );
    }
  }

  /* eslint-disable */
  // Returns the fragment of the URL if there is one in not then returns empty string.
  getFragmentInUrl() {
    return window.location.hash.replace("#", "");
  }
  /* eslint-enable */

  // Opening drawers when anchored to onload
  goToAnchor(drawer) {
    const trigger = drawer.parentNode.querySelector(
      '[data-type="drawer-opener"]'
    );
    if (trigger?.dataset.remoteTriggerId === this.getFragmentInUrl()) {
      if (!drawer.isOpen()) {
        drawer.toggle();
      }
      trigger.focus();
    }
  }
}

export const init = () => {
  initComponent("drawers", (element) => new Drawers(element));
};
init();
