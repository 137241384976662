import { initComponent } from "../../utilities/common";

export const init = () => {
  initComponent("jumbo-cta", (cta) => {
    let contentOverlay = cta.querySelector('[data-type="content-overlay"]');

    checkAspectRatio(contentOverlay);

    window.addEventListener("resize", function () {
      checkAspectRatio(contentOverlay);
    });

    function checkAspectRatio(contentOverlay) {
      let width = contentOverlay.offsetWidth;
      let minimumHeight;
      if (window.innerWidth > 600) {
        minimumHeight = width / (8 / 3);
      } else {
        minimumHeight = width / (5 / 6);
      }
      contentOverlay.style.minHeight = minimumHeight + "px";
    }
  });
};
init();
