import MapsService from "@churchofjesuschrist/maps-service";

export const geolocate = () => {
  if (sessionStorage.getItem("general-location")) {
    const json = sessionStorage.getItem("general-location");
    return Promise.resolve(JSON.parse(json));
  } else
    return new Promise((resolve) => {
      const lang =
        document
          ?.querySelector('meta[name="twoCharacterLang"]')
          ?.getAttribute("content") || "en";
      const isProd = window.location.origin.match(
        /:\/\/(local|hannah|cuc-|dev|test|stage)/
      )
        ? false
        : true;

      MapsService.configure({
        client: "MormonOrg",
        language: lang,
        production: isProd
      });
      MapsService.geocodeIpAddress().then((result) => {
        if (result) {
          const location = result[0] || null;
          sessionStorage.setItem("general-location", JSON.stringify(location));
          resolve(location);
        } else {
          resolve(null);
        }
      });
    });
};
