import cookies from "../cookies/browser";

const barColorClass = "poll_barColor";
const cookieName = "pollVote";
import { initComponent } from "../../utilities/common.js";

export default class Poll {
  constructor(element) {
    this.element = element;
    this.question = element.querySelectorAll(
      '[data-type="question"]'
    )[0].innerText;
    this.id = element.querySelector("[data-poll-id]")
      ? element.querySelector("[data-poll-id]").dataset.pollId
      : element.querySelector("h3").innerText;
    this.group = element.querySelector("[data-poll-group]")
      ? element.querySelector("[data-poll-group]").dataset.pollGroup
      : "Polls";
    //setup buttons and things that rely on the buttons
    this.buttons = [...element.querySelectorAll('[data-type="answer"]')];
    this.possibleAnswers = this.buttons.reduce((obj, answer) => {
      obj[answer.dataset.voteid] = { ...answer.dataset };
      return obj;
    }, {});
    this.history = this.buttons.map((answer) => ({
      id: answer.dataset.voteid,
      total: answer.dataset.results || 0
    }));
    this.buttons.forEach((btn) => {
      btn.dataset.results = this.results[btn.dataset.voteid].percent;
      btn.onclick = () => this.btnClick(btn);
    });
    this.saveDataToDB(10).finally(() =>
      //do this last as it could stop the poll from allowing votes from this end user
      this.getCookie()
    );

    this.consolidationStatus = element.dataset.consolidationstatus;
  }
  get results() {
    let total = this.totalOfAllVotes();
    return Object.keys(this.possibleAnswers).map((answerId) => {
      const vote = this.history.find((vote) => answerId === vote.id.toString());
      const voteTotal = vote ? vote.total : 0;
      let percentOfAllVotes = (parseInt(voteTotal) / total) * 100;
      let formattedPercent = isNaN(percentOfAllVotes)
        ? 0
        : parseInt(percentOfAllVotes).toFixed(0);
      return { percent: formattedPercent + "%" };
    });
  }
  totalOfAllVotes() {
    let total = 0;
    this.history.forEach((vote) => (total += vote.total));
    return total;
  }
  getCookie() {
    const name = cookieName;
    if (cookies.get(name)) {
      const value = JSON.parse(decodeURIComponent(cookies.get(name)));
      const group = value[this.group];
      const vote = value["vote"];
      if (group && group.length > 0) {
        if (group.filter((i) => i === this.id).length > 0) {
          this.cookieHasId(vote[group.indexOf(this.id)]);
        }
      }
    }
  }
  setCookie(voteId) {
    const name = cookieName;
    let cookie;
    let group;
    let cookieVote;
    if (cookies.get(name)) {
      cookie = JSON.parse(decodeURIComponent(cookies.get(name)));
      group = cookie[this.group];
      cookieVote = cookie["vote"];
    } else {
      cookie = {};
    }
    if (group && !group.includes(this.id)) {
      group.push(this.id);
      cookieVote.push(voteId);
    } else {
      group = [this.id];
      cookieVote = [voteId];
    }
    cookie[this.group] = group;
    cookie["vote"] = cookieVote;
    cookies.set(name, JSON.stringify(cookie), 365, this.consolidationStatus);
    this.cookieHasId(voteId);
  }
  cookieHasId(voteId) {
    this.disableVoting(voteId);
  }
  disableVoting(voteId) {
    let results = [];
    this.buttons.forEach((btn) => {
      let percent = this.results[btn.dataset.voteid].percent;
      //let me know if there is a more elegant way to do this.
      if (document.dir === "rtl") {
        btn.firstElementChild.style.right =
          (100 - parseInt(percent)) * -1 + "%";
      } else {
        btn.firstElementChild.style.left = (100 - parseInt(percent)) * -1 + "%";
      }
      btn.dataset.results = percent;
      let buttonPercent = btn.querySelector(".poll_percent");
      buttonPercent.innerText = percent;
      //disable the buttons so that you can't vote again
      btn.setAttribute("disabled", "disabled");
      //check if button was the voted button and if it has the correct color
      if (
        btn.dataset.voteid === voteId &&
        btn.firstElementChild.classList.contains(barColorClass)
      ) {
        btn.firstElementChild.classList.remove(barColorClass);
      }
      results.push({ answer: btn.dataset.answer, percent: percent });
    });
    //display link
    let link = document.querySelector('[data-type="poll-link"]');
    if (link) {
      link.classList.remove("poll_hidden");
    }

    //set aria-live to assertive to announce that poll results have updated
    const visuallyHiddenElem = this.element.querySelector(
      "[data-type='visually-hidden']"
    );
    visuallyHiddenElem.setAttribute("aria-live", "assertive");
    visuallyHiddenElem.innerHTML = `${
      visuallyHiddenElem.dataset.pollUpdatedString
    }<div>${results
      .map((result) => `<span>${result.answer}: ${result.percent}</span>`)
      .join("")}`;

    setTimeout(() => {
      //set aria-live to "off" to prevent from accidentally being read again
      visuallyHiddenElem.setAttribute("aria-live", "off");
      visuallyHiddenElem.innerHTML =
        visuallyHiddenElem.dataset.pollUpdatedString;
    }, 1000);
  }
  saveDataToDB(voteId) {
    let url = `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/polls`;
    //"https://81s7jzg0vd.execute-api.us-east-1.amazonaws.com/come-unto-christ-api-prod/v1/poll"; //prod url
    let body = {
      question: this.question,
      vote: parseInt(voteId)
    };
    //    let API_KEY = "come-unto-christ-api-key-value-dev-e5PLmgl7wL0y"; //dev part will change based on the lane for aws and azure.
    //API_KEY FOR PROD IS come-unto-christ-api-key-value-prod-e5PLmgl7wL0y
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
        //        "x-api-key": API_KEY
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        let resData = response.json();
        return resData;
      })
      .then((resData) => {
        const data = JSON.parse(resData).data.map((total, id) => ({
          id,
          total
        }));
        this.history = data;
      })
      .then(() => {
        if (voteId !== 10) {
          //disable voting / set Cookie
          this.setCookie(voteId);
        }
      });
  }
  btnClick(btn) {
    //add your vote to others
    this.saveDataToDB(btn.dataset.voteid);

    //show that this is the vote you selected
    btn.firstElementChild.classList.remove(barColorClass);
  }
}

export const init = () => {
  initComponent("poll", (element) => new Poll(element));
};
init();
