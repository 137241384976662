/*
Controls the setup and playback of a media player. Also handles the UI of the media player using events dispatched by LDSMediaPlayer.
*/

import LDSMediaPlayer from "./lds-media-player/lds-media-player";
import YouTube from "./lds-media-player/adapters/youtube";
import Brightcove from "./lds-media-player/adapters/brightcove";
import { events } from "./lds-media-player/events";
import styles from "./styles.css";
import {
  callbackWhenInView,
  checkYoutube,
  initComponent,
  sendMediaAnalytics
} from "../../utilities/common";
import { scrollIntoViewIfNeeded } from "../scroll-logic/browser";
import MakeFit from "../make-fit/browser";
import dispatchCustomEvent from "../custom-event/browser";
import DownloadButton from "../download-button/browser";

// One way to handle site-specific configuration. In whatever file is actually adding this module to the browser, they can call the default export and add configuration.
let youTubeOptions = { playsInline: true };
let brightcoveOptions = {};
let brightcovePlayerOptions = {
  embed: "default",
  playsInline: true // required for iOS to autoplay (using the play() function can be considered autoplay in some circumstances)
};

// Sets some configuration for the module.
export default function (options) {
  Object.assign(youTubeOptions, options.youTubeOptions);
  Object.assign(brightcoveOptions, options.brightcoveOptions);
}

// Helper used within a couple event listeners below.
function preparePlay(mediaElement, posterElement, isLooping) {
  let mediaPlayer;
  if (posterElement !== null) {
    posterElement.hidden = true;
    mediaPlayer = posterElement.closest('[data-type="media-player"]');
  }

  if (mediaElement) mediaElement.setAttribute("played", "");

  if (mediaPlayer && !isLooping) {
    scrollIntoViewIfNeeded(mediaPlayer, {
      block: "nearest",
      behavior: "smooth"
    });
    posterElement.style.pointerEvents = "all";
    posterElement.click();
  }
}

// Sets up a single media player for a given `mediaElement`.
// TODO: This should be refactored into a class so the preparePlay stuff can just be included there.
export async function setupPlayer(mediaElement) {
  try {
    const playerElement = mediaElement.querySelector("[data-player]");
    const loadingElement = mediaElement.querySelector("[data-loading]");
    const errorElement = mediaElement.querySelector("[data-error]");
    const posterElement = mediaElement.querySelector("[data-poster]");
    const grippy = mediaElement.querySelector("[media-player-grippy]");
    const mediaBlock = mediaElement.closest('[data-type="media-block"]');
    const playButton = posterElement.querySelector("button");
    const downloadBtns =
      mediaBlock?.querySelectorAll('[data-type="download-button"]') || [];
    const modalContainerAutoPlayOverride = mediaElement.hasAttribute(
      "data-inherit-autoplay"
    );
    const boldVariantHeading = mediaElement.querySelector(
      "[bold-display-text-wrapper]"
    );
    let loadingType = mediaElement.dataset.loadingtype || "onclick";
    const isLooping = mediaElement.hasAttribute("looping");

    // Get the media ids
    const media = [];

    const mediaData = mediaElement.getAttribute("data-media");

    const {
      youTubePlaylistId,
      youTubeId,
      brightcoveId,
      brightcoveAccountId,
      brightcovePlayerId,
      overlays,
      timeTriggers
    } = JSON.parse(mediaData);

    // setting properties for brightcove account ID and Player ID from props passed in
    brightcovePlayerOptions.account = brightcoveAccountId;
    brightcovePlayerOptions.player = brightcovePlayerId;
    brightcovePlayerOptions.overlays = overlays;
    brightcovePlayerOptions.timeTriggers = timeTriggers;

    const youTubePlaylistOptions = {
      list: youTubePlaylistId,
      listType: "playlist"
    };

    if (isLooping) {
      brightcovePlayerOptions = Object.assign({}, brightcovePlayerOptions, {
        autoplay: true,
        loop: true,
        muted: true,
        controls: false
      });
    }

    // new option to use autoplay in more cases than above with looping, vallue set in pageGlobals
    if (modalContainerAutoPlayOverride) {
      brightcovePlayerOptions = Object.assign({}, brightcovePlayerOptions, {
        autoplay: true,
        muted: true
      });
    }

    const ytAvailable = await checkYoutube();
    const ytId = youTubeId || youTubePlaylistId;
    const ytOptions = youTubePlaylistId
      ? youTubePlaylistOptions
      : youTubeOptions;
    const overrideYT = window.location.search.indexOf("player=yt") !== -1;

    // Save bcAsset as a variable to send in download button
    const bcAsset =
      brightcoveId && brightcoveOptions
        ? new Brightcove(brightcoveId, brightcovePlayerOptions)
        : null;
    const useBrightcove =
      (isLooping || !youTubePlaylistId) && brightcoveId && !overrideYT;

    if (useBrightcove) media.push(bcAsset);
    if (ytAvailable && ytId) media.push(new YouTube(ytId, ytOptions));
    if (!useBrightcove && !ytAvailable && !ytId)
      throw new Error("No media added to player");

    // Sends video analytics for video events
    const sendAnalytics = (event, eventDetails) => {
      const { position: currentTime, duration, title: name } = eventDetails;
      sendMediaAnalytics({
        playerElement,
        event,
        detail: {
          platform: media[0]?.platform?.name,
          videoId: media[0]?.platform?.id,
          currentTime,
          duration,
          name,
          ...(useBrightcove && {
            playerVersion:
              bcAsset?.brightcovePlayer?.bcAnalytics?.settings?.platformVersion // Only Brightcove has a player version
          })
        }
      });
    };

    // Hide the poster on a `play` event. Although the `posterElement` click listener defined below will already handle hiding the `posterElement`, this `play` listener is also added in case the player is programmatically told to play elsewhere.
    playerElement.addEventListener(events.PLAY, (event) => {
      preparePlay(mediaElement, posterElement);
      pauseAnyOtherPlayer(mediaElement);
      if (!isLooping) {
        dispatchCustomEvent(window, "pause-all-audios");
        mediaElement.setAttribute("playing", "");
        sendAnalytics("unityplay", event.detail);
      }
    });

    // Use the `progress` event to hide any error element and show the loading element.
    playerElement.addEventListener(events.PROGRESS, () => {
      if (errorElement) errorElement.hidden = true;
      if (loadingElement) loadingElement.hidden = false;
    });

    // Show an error when something goes wrong.
    playerElement.addEventListener(events.ERROR, (event) => {
      if (errorElement) errorElement.hidden = false;
      preparePlay(mediaElement, posterElement);
      sendAnalytics("unityerror", event.detail);
    });

    // Hide the loading element once the `loadend` event has dispatched.
    playerElement.addEventListener(events.LOADEND, () => {
      if (loadingElement) loadingElement.hidden = true;
    });

    // Corresponds to the native ended event which fires when playback stops at the end or no further data is available.
    playerElement.addEventListener(events.ENDED, (event) => {
      sendAnalytics("unityended", event.detail);
    });

    // Triggers analytics for when the video is playing
    playerElement.addEventListener(events.PLAYING, (event) => {
      if (!isLooping) sendAnalytics("unityplaying", event.detail);
    });

    // Corresponds to the native loadedmetadata event which fires when the metadata has been loaded. YouTube won't trigger this properly so we manually trigger the "loadedmetadata" event in the YouTube.js file
    playerElement.addEventListener(events.LOADEDMETADATA, (event) => {
      sendAnalytics("unityloadedmetadata", event.detail);
    });

    // Corresponds to the native timeupdate event which fires when the video's current time has updated (e.g., periodically during playback).
    playerElement.addEventListener(events.TIMEUPDATE, (event) => {
      sendAnalytics("unitytimeupdate", event.detail);
    });

    playerElement.addEventListener(events.BUFFERING, () => {
      if (posterElement) {
        posterElement.style.pointerEvents = "all";
        posterElement.click();
        posterElement.hidden = true;
        // poster is what holds the space open in some circumstances. Only remove poster when it is a looping video. We remove for video since it won't be visible anymore and may it shouldn't compete for resources with the main video when it starts playing
        if (isLooping) posterElement.parentNode.removeChild(posterElement);
      }
    });

    playerElement.addEventListener(events.PAUSE, (event) => {
      mediaElement.removeAttribute("playing");
      sendAnalytics("unitypause", event.detail);
    });

    // Create the media player
    const player = new LDSMediaPlayer(playerElement, {
      media
    });

    mediaElement.loadPlayer = () => player.load();
    mediaElement.pausePlayer = () => player.pause();

    // set up download buttons
    downloadBtns.forEach((dl) => {
      if (bcAsset)
        new DownloadButton({
          element: dl,
          onClick: () => player.downloadVideo(bcAsset)
        });
      else dl.remove();
    });

    // Add a click listener to the optional `posterElement` which hides the poster and plays the player. If no `posterElement`, load the player.
    if (posterElement) {
      posterElement.addEventListener("click", () => {
        // We don't just rely on the `play` listener to hide the poster because it causes a delay
        // for the user between the time he/she clicks and when the player is built and begins
        // playing. Instead, we will immediately hide the poster and the `progress` listener will
        // handle showing the loading element.
        preparePlay(mediaElement, posterElement, isLooping);

        // Original state of playerElement is opacity: .01, this event removes the class setting that opacity
        playerElement.classList.remove(styles.hideVideo);
      });
    }

    if (boldVariantHeading) {
      const boldStylesPrefix = "bold-display_";
      new MakeFit(boldVariantHeading, {
        sizeClasses: `${boldStylesPrefix}smallDisplay ${boldStylesPrefix}smallerDisplay`
      });
    }

    // adds a draggable div on top of the media player so it works with draggable interfaces
    if (grippy) {
      let mouseDownX;
      let mouseDownY;
      grippy.addEventListener("pointerdown", (e) => {
        mouseDownX = e.clientX;
        mouseDownY = e.clientY;
      });
      grippy.addEventListener("pointerup", (e) => {
        const playing = mediaElement.hasAttribute("playing");
        const moveEnough =
          Math.max(
            Math.abs(mouseDownX - e.clientX),
            Math.abs(mouseDownY - e.clientY)
          ) <= 10;

        if (moveEnough && playing) player.pause();
        else if (moveEnough) player.play();
      });
    }

    // override loadingType with a prop from pageGlobals for interconnected components like modal
    loadingType = modalContainerAutoPlayOverride ? "preload" : loadingType;
    switch (loadingType) {
      case "onscroll":
        loadOnScroll(playerElement, player, isLooping);
        break;
      case "preload":
        loadAutomatically(player);
        break;
      case "onclick":
        playOnClick(
          playerElement,
          posterElement,
          player,
          isLooping,
          playButton
        );
        break;
      case "loadonclick":
        loadOnClick(playerElement, posterElement, player, isLooping);
        break;
      default:
        loadOnScroll(playerElement, player, isLooping);
        break;
    }

    // When an error occurs, we have included a `errorElement` which when clicked will retry the build process.
    if (errorElement) {
      errorElement.addEventListener("click", (event) => {
        event.preventDefault();
        player.reset();
        player.play();
      });
    }

    return player;
  } catch (error) {
    return error;
  }
}

// Sets up all players on a page
function setupPlayers() {
  initComponent("media-player", (element) => {
    callbackWhenInView({
      elems: [element],
      callback: setupPlayer
    });
  });
}

function loadOnScroll(element, player, isLooping) {
  callbackWhenInView({
    elems: [element],
    callback: () => {
      player.load();
    },
    rootMargin: isLooping ? "500px" : "150px"
  });
}

function loadAutomatically(player) {
  player.load();
}

function playOnClick(element, poster, player, isLooping, playButton) {
  const play = () => {
    preparePlay(element, poster, isLooping);
    player.play();
  };

  element.addEventListener("click", (event) => {
    // Checking that that the target element is the same as the player element prevents the control bar buttons from unnecessarily playing the video
    if (event.target === element) play();
  });

  // Add event listener to play button so that video plays on keydown
  if (playButton) {
    playButton.addEventListener("keydown", (event) => {
      if (event.code === "Enter") play();
    });
  }
}

function loadOnClick(element, poster, player) {
  element.addEventListener("click", () => {
    player.load();
    if (poster !== null) {
      poster.hidden = true;
      player = poster.closest('[data-type="media-player"]');
    }
  });
}

function pauseAnyOtherPlayer(playerUserClicked) {
  document
    .querySelectorAll('[data-type="media-player"][playing]')
    .forEach((el) => {
      if (!el.isSameNode(playerUserClicked)) el.pausePlayer?.();
    });
}

window.addEventListener("pause-all-players", () => pauseAnyOtherPlayer());

// Start setting up players once the page is ready
export const init = () => {
  document.readyState !== "loading"
    ? setupPlayers()
    : document.addEventListener("DOMContentLoaded", setupPlayers);
};
init();
