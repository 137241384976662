export default class GeolocateBtn {
  constructor({
    element,
    onPositioned = (pos) => pos,
    handleNoBrowserSupport = () => {},
    handleUserBlockedGeo = () => {},
    getCurrentPositionOptions = {},
    spinner
  }) {
    this.element = element;
    this.icon = element?.querySelector('[data-icon-name="location-target"]');
    this.spinner = spinner;

    this.getCurrentPositionOptions = getCurrentPositionOptions;
    this.handlePosition = onPositioned;
    this.handleNoBrowserSupport = handleNoBrowserSupport;
    this.handleUserBlockedGeo = handleUserBlockedGeo;

    element?.addEventListener("click", () => {
      this.getGPSLocation();
    });
  }

  getGPSLocation() {
    if (this.element.hasAttribute("geo-initialized")) {
      this.showLoadingGeo();
    } else {
      this.element.setAttribute("geo-initialized", "");
    }
    this.showLoadingGeo();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.hideLoadingGeo();
          this.element.removeAttribute("geo-inactive", "");
          this.handlePosition(position);
        },
        () => {
          this.handleUserBlockedGeo();
          this.hideLoadingGeo();
          this.element.setAttribute("geo-inactive", "");
        },
        this.getCurrentPositionOptions
      );
    } else {
      this.handleNoBrowserSupport();
      this.hideLoadingGeo();
      this.element.setAttribute("geo-inactive", "");
    }
  }

  showLoadingGeo() {
    this.spinner?.setAttribute("data-spinner-visible", "");
  }

  hideLoadingGeo() {
    this.spinner?.removeAttribute("data-spinner-visible", "");
  }
}
