import SteppedForms from "../../stepped-form/browser";
import { initComponent } from "../../../utilities/common";

export default class CheckBox {
  constructor(element) {
    const checkBox = element.querySelector("input[type='checkbox']");
    const conditionalFieldName = element.dataset.conditionalField;
    const form = element.closest("form");
    const conditionalInputContainer =
      form && conditionalFieldName
        ? form.querySelector(`[data-type="${conditionalFieldName}"]`)
        : "";
    const conditionalInputField = conditionalInputContainer
      ? conditionalInputContainer.querySelector("input")
      : "";

    if (conditionalInputField) {
      const conditionalInputValidityDiv = conditionalInputContainer.querySelector(
        "[data-custom-message]"
      );
      const conditionalValidityMessage = element.dataset.conditionalMessage;

      const conditionalInputFieldOriginallyRequired =
        conditionalInputField.required;

      checkBox.addEventListener("change", () => {
        if (checkBox.checked) {
          conditionalInputField.setAttribute("required", "");
          if (conditionalValidityMessage) {
            conditionalInputValidityDiv.innerHTML = conditionalValidityMessage;
          }
        } else if (!conditionalInputFieldOriginallyRequired) {
          conditionalInputField.removeAttribute("required");
          SteppedForms.setAsValid(conditionalInputField);
          conditionalInputField.setCustomValidity("");
        }
      });
    }
  }
}

export const init = () => {
  initComponent("check-box", (element) => new CheckBox(element));
};
init();
