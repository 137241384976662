import { remoteComponentSubscribe } from "components/remote-trigger/browser";
import customEvent from "../custom-event/browser";
import { initComponent } from "../../utilities/common";

export default class SpeedDial {
  constructor(element) {
    this.element = element;
    this.remoteTrigger = element.id;
    this.options = element.querySelector("[data-type='options']");
    this.bottomPaddingMobile = parseInt(element.dataset.bottomPaddingMobile);
    this.bottomPaddingDesktop = parseInt(element.dataset.bottomPaddingDesktop);
    this.body = document.body;
    this.initialHeight = 0;
    this.opened = false;

    this.setPadding();

    this.resize = () => {
      this.setPadding();
      this.overWrap();
    };

    this.closeOptions = (event) => {
      const triggerWasClicked =
        this.remoteTrigger ===
        event.target.closest("[data-remote-trigger-id]")?.dataset
          .remoteTriggerId;

      if (this.options.contains(event.target) || !triggerWasClicked) {
        this.opened = true;
        this.changeButtonState(event.detail);
      }

      // Trigger custom "overlay-closing" event can be used to trigger the closing of any buttons that were used to trigger the opening of the speed dial
      // Only do this if the button being clicked isn't the trigger
      if (!triggerWasClicked) {
        customEvent(element, "overlay-closing", {
          bubbles: true,
          detail: { remoteTriggerId: this.remoteTrigger }
        });
      }
    };

    remoteComponentSubscribe(this.remoteTrigger, (event) => {
      this.changeButtonState(event?.detail);

      //Because the Speed Dial is set to display "none", the overwrap function should not be called until the Speed Dial is visible and the height can properly be calculated
      this.opened ? setTimeout(() => this.overWrap(), 100) : "";
    });
  }

  overWrap() {
    this.initialHeight =
      this.initialHeight === 0 ? this.options.offsetHeight : this.initialHeight;

    if (window.innerHeight >= this.bottomPadding + this.initialHeight) {
      this.options.removeAttribute("wrap");
    } else {
      this.options.setAttribute("wrap", "");
    }
  }
  changeButtonState(eventDetail) {
    if (this.opened) {
      customEvent(document, "hideScrim");
      this.element.setAttribute("aria-hidden", "true");
      window.removeEventListener("resize", this.resize);
      this.body.removeEventListener("click", this.closeOptions, true);
      this.opened = false;
    } else {
      customEvent(document, "showScrim");
      this.element.setAttribute("aria-hidden", "false");
      window.addEventListener("resize", this.resize);
      this.body.addEventListener("click", this.closeOptions, true);
      this.opened = true;
      if (eventDetail === 0) this.options.querySelector("a")?.focus();
    }
  }
  setPadding() {
    this.bottomPadding =
      window.innerWidth > 600
        ? this.bottomPaddingDesktop
        : this.bottomPaddingMobile;
    this.element.style.paddingBottom = this.bottomPadding + "px";
  }
}

export const init = () => {
  initComponent("speed-dial", (element) => new SpeedDial(element));
};
init();
