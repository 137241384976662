import { getParam } from "../../utilities/common";
import customEvent from "../custom-event/browser";
/* Functionality for checkboxes that correspond to unit types are commented out */
/*
const allYSAWards = {
  apiParams: [
    "ward.ysa",
    "ward.frenchysa",
    "ward.spanishysa",
    "ward.tonganysa",
    "ward.asianysa"
  ],
  URLParam: "ysa"
};
const allFamilyWards = {
  apiParams: [
    "ward",
    "ward.cambodian",
    "ward.cantonese",
    "ward.chinese",
    "ward.chuukicpohnpeic",
    "ward.english",
    "ward.fijian",
    "ward.french",
    "ward.german",
    "ward.haitiancreole",
    "ward.hmong",
    "ward.japanese",
    "ward.karen",
    "ward.kiribati",
    "ward.korean",
    "ward.laotian",
    "ward.malay",
    "ward.mandarin",
    "ward.marshallese",
    "ward.nepali",
    "ward.niuean",
    "ward.persian",
    "ward.pohnpeian",
    "ward.portuguese",
    "ward.russian",
    "ward.samoan",
    "ward.slovak",
    "ward.spanish",
    "ward.swahili",
    "ward.tagalog",
    "ward.tongan",
    "ward.vietnamese"
  ],
  URLParam: "ward"
};
const allSAWards = {
  apiParams: ["ward.singleadult"],
  URLParam: "sa"
};
*/
export default class MeetinghouseFilter {
  constructor(element) {
    this.element = element;
    this.langCode = element.dataset.languageCode;
    this.form = element.querySelector("form");

    this.form.addEventListener("change", () => {
      this.handleChange();
      this.handleSubmit();
    });

    this.languageOptions = [...this.element.querySelectorAll("option")].map(
      (o) => o.value
    );

    this.processInitialURL();
  }

  get filterData() {
    // Use a reduce to grab the data from the form and put it into an object
    return this.readForm("apiParams");
  }

  handleChange() {
    this.element.filterData = this.filterData;
    this.updateURL();
  }

  processInitialURL() {
    const languagesParam = getParam("languages");
    /* const unitTypesParam = getParam("unitTypes"); */

    if (languagesParam) {
      const languages = languagesParam.split(",");
      this.form.querySelector('[name="language"]').value = languages[0];
    } else if (this.languageOptions.includes(this.langCode)) {
      this.form.querySelector('[name="language"]').value = this.langCode;
    }

    /*
    if (unitTypesParam) {
      const unitTypes = unitTypesParam.split(",");
      unitTypes.forEach(u => {
        if (u) this.form.querySelector(`[name="${u}"]`).checked = true;
      });
    } else
      this.form
        .querySelectorAll('input [type="checkbox"]')
        .forEach(c => (c.checked = false));
    */
  }

  readForm(/*key*/) {
    const filterData = [...this.form].reduce(
      (acc, curr) => {
        if (curr.name === "language" && curr.value) {
          acc.languages.push(curr.value);
        }
        /*
        if (curr.type === "checkbox" && curr.checked) {
          switch (curr.name) {
            case "ysa":
              acc.unitTypes.push(allYSAWards[key]);
              break;
            case "sa":
              acc.unitTypes.push(allSAWards[key]);
              break;
            case "ward":
              acc.unitTypes.push(allFamilyWards[key]);
              break;
            default:
              acc.unitTypes.push(allFamilyWards[key]);
          }
        }
        */
        return acc;
      },
      { languages: [], unitTypes: [] }
    );
    filterData.languages = filterData.languages.flat();
    /* filterData.unitTypes = filterData.unitTypes.flat(); */
    return filterData;
  }

  updateURL() {
    const condensedData = this.readForm("URLParam");
    const languages = condensedData.languages.join(",");
    const unitTypes = condensedData.unitTypes.join(",");
    const searchParams = new URLSearchParams(window.location.search);
    languages
      ? searchParams.set("languages", languages)
      : searchParams.delete("languages");
    /* unitTypes
      ? searchParams.set("unitTypes", unitTypes)
      : searchParams.delete("unitTypes"); */
    window.history.replaceState(
      {
        location: searchParams.location,
        languages,
        unitTypes,
        unit: searchParams.unit || ""
      },
      `Applying filters ${this.filterData}`,
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  handleSubmit(event) {
    if (event) event.preventDefault();
    customEvent(this.element, "search-from-filter", {
      bubbles: true
    });
  }
}
