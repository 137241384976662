import styles from "./styles.css";
import SimpleCounter from "../simple-counter/browser.js";
import hashFnv32a from "../hashfnv32a/browser.js";
import CounterGroup from "../counter-group/browser";
import { initComponent } from "../../utilities/common";
import { remoteComponentSubscribe } from "../remote-trigger/browser";

export default class Counter {
  constructor({ element, disableButtonExtraFunctions = () => {} }) {
    this.element = element;
    this.counter = this.element.querySelector("[data-counter]");
    this.hiddenCounter = this.element.querySelector("[data-hidden-counter]");
    this.id = this.counter.dataset.counterId;
    this.group = this.counter.dataset.counterGroup;
    this.limit = Number(this.counter.dataset.counterLimit);
    this.button = this.element.querySelector('[data-type="button"]');
    this.link = this.element.dataset.counterLink;
    this.value = 0;
    this.digitElement = "div";
    this.disableButtonExtraFunctions = disableButtonExtraFunctions;

    this.simpleCounter = new SimpleCounter({
      group: this.group,
      id: this.id,
      onDataIsSet: () => {
        this.disableButton();
        this.limit = 0;
      },
      updateSocialCounter: (count, didIncrement) => {
        this.updateSocialCounter(count, didIncrement);
      },
      counter: element
    });

    this.enableButton();
    this.simpleCounter.checkForData();
    if (this.element.getAttribute("startwith")) {
      this.countTo(this.element.getAttribute("startwith"));
    }
    this.element.countTo = (count) => {
      this.countTo(count);
    };
    remoteComponentSubscribe(this.id, () => {
      // If the user has exceeded their limit or there is no limit the incrementCounter will not even be called. otherwise decrease the limit by one and continue
      if (this.limit === undefined || this.limit <= 0) return;
      this.limit--;

      this.incrementCounter(false, this.limit);
    });
  }

  updateSocialCounter(count, didIncrement = false) {
    this.countTo(count);
    if (didIncrement) {
      this.navigateToLink();
    }
  }

  disableButton() {
    if (this.button) {
      this.button.disabled = true;
      this.disableButtonExtraFunctions(this.button);
    }
  }

  enableButton() {
    if (this.button && this.group && this.id) {
      this.button.addEventListener("click", () => {
        this.disableButton();
        this.incrementCounter(true, 0);
      });
    }
  }

  incrementCounter(buttonClicked, remainingTries) {
    this.counter.setAttribute("aria-live", "assertive");

    const currentTime = Math.round(new Date().getTime()) + "";
    const hashed = hashFnv32a(currentTime, true, "WuN9P62Arf2E"); //random seed here
    this.simpleCounter
      .incrementSocialCounter(currentTime, hashed, remainingTries)
      .catch(() => {
        // If there's an error, the counter button was clicked, and the vote is not sent to the db: the user could still click
        if (this.button && buttonClicked) this.button.disabled = false;

        this.simpleCounter.checkForData();
      });
  }

  // Moves the counter to a given `value`.
  countTo(value) {
    value = parseInt(value);

    // First make sure we have a valid integer `value`
    if (
      !(
        typeof value === "number" &&
        isFinite(value) &&
        Math.floor(value) === value
      )
    )
      return;
    // don't let the value go down
    if (value < this.value) return;

    // Now split the `value` into an array of digits. We reverse it because we will add the digit modifier classes below starting with the "ones" digit.
    var valueDigitArray = (value + "").split("").reverse();

    var length = valueDigitArray.length;

    this.counter.classList.add(`${styles.count}-${length}`);

    // Make sure we have enough digit elements, +1 to have a zero padded number
    this.setDigitElements(length + 1);

    // Create an array of all the digit elements. It is reversed because we will add the digit modifier classes below starting with the "ones" digit.
    this.valueNumbers = Array.prototype.slice
      .call(this.counter.querySelectorAll('[data-type="digit"]'), 0)
      .reverse();

    // Loop through the digit elements and apply the modifier class for the corresponding digit in the given `value`.
    this.valueNumbers.forEach(function (number, index) {
      // Add a timeout to so the "ones" digit animates before the "tens" and so on.
      setTimeout(function () {
        number.className = `${styles.digit} ${`${styles.digit}--${
          valueDigitArray[index] || 0
        }`}`;
      }, index * 250);
    });

    this.value = value;

    if (this.counter.getAttribute("aria-live") === "assertive") {
      this.hiddenCounter.innerText = `${this.counter.dataset.counterUpdatedLabel}: ${value}`;
      setTimeout(() => {
        this.counter.setAttribute("aria-live", "off");
      }, 1000);
    } else {
      this.hiddenCounter.innerText = value;
    }
  }

  // Adds more digit child elements if the given `digits` is greater than the current number of digits.
  setDigitElements(digitCount) {
    if (digitCount < 3) digitCount = 3;
    const currentDigitCount = this.digitCount();
    const toClone = this.counter.querySelector("[data-type='digit']");

    // Add more digits if the currentDigitCount is less than the given `digitCount`.
    if (currentDigitCount < digitCount) {
      for (let i = 0; i < digitCount - currentDigitCount; i++) {
        this.counter.insertBefore(toClone.cloneNode(true), toClone);
      }
    }
  }

  // Returns the current number of digits present in the counter
  digitCount() {
    return this.counter.querySelectorAll('[data-type="digit"]').length;
  }

  navigateToLink() {
    if (this.link && this.link !== null) {
      window.location = this.link;
    }
  }
}
export const init = () => {
  const counters = [];
  initComponent("counter", (element) => {
    counters.push(new Counter({ element }));
  });
  new CounterGroup(counters, 10000).watch();
};
init();
