import { initComponent } from "../../utilities/common";
import {
  remoteComponentSubscribe,
  triggerAttribute
} from "components/remote-trigger/browser";
import cookies from "../cookies/browser";

export default class SaveData {
  constructor(element) {
    remoteComponentSubscribe(element.dataset[triggerAttribute], () => {
      const key = element.dataset.key;
      const value = element.dataset.value;
      const method = element.dataset.method;
      if (method === "session") {
        sessionStorage.setItem(key, value);
      } else if (method === "local") {
        localStorage.setItem(key, value);
      } else {
        cookies.set(key, value, 365, true);
      }
    });
  }
}

export const init = () => {
  initComponent("save-data", (element) => new SaveData(element));
};
init();
