import styles from "./styles.css";
import { stepChangeAnalytics } from "../../utilities/analytics.js";
import {
  callbackWhenInView,
  initComponent,
  setTabIndex
} from "../../utilities/common.js";

export default class CardStack {
  constructor(element) {
    this.element = element;
    this.container = this.element.parentNode;
    this.width = this.container.offsetWidth;
    this.cards = this.element.querySelectorAll(`.${styles.card}`);
    this.numCards = this.cards.length;
    this.topCardWidth = this.container.offsetWidth;
    this.topCardHeight = this.element.offsetHeight;
    this.minHeight = 0;
    this.leftBtn = this.container.querySelector(`.${styles.leftArrow}`);
    this.rightBtn = this.container.querySelector(`.${styles.rightArrow}`);
    this.refreshBtn = this.element.querySelector("[data-refresh-button]");
    this.navigationBar = this.container.querySelector(
      `.${styles.navigationBar}`
    );
    this.navigationBarText = this.container.querySelector(
      `.${styles.navigationBarText}`
    );
    this.stepsTranslation = this.navigationBarText?.dataset?.stepsTranslation;
    this.originalVariant = this.element.classList.contains(styles.original);
    this.currentIndex = 0;
    this.viewedSteps = [];
    this.startX;
    this.startY;
    this.endX;
    this.endY;
    this.imgs = this.element.querySelectorAll("img");

    this.dragStart = (event) => this.draggingStarted(event);
    this.drag = (event) => this.dragging(event);
    this.dragEnd = (event) => this.draggingEnded(event);

    this.cards[0] ? this.addDragEvents(this.cards[0]) : "";
    // Remove analytics for the buttons so that they get triggered by the stepChange analytics
    this.leftBtn
      ?.querySelector("[use-analytics]")
      ?.removeAttribute("use-analytics");
    this.rightBtn
      ?.querySelector("[use-analytics]")
      ?.removeAttribute("use-analytics");
    this.leftBtn?.addEventListener("click", (e) => this.leftBtnClick(e));
    this.rightBtn?.addEventListener("click", (e) => this.rightBtnClick(e));
    this.refreshBtn?.addEventListener("click", () => this.resetDeck(0));
    window.addEventListener("resize", () => this.resizer());

    [...this.element.querySelectorAll(`.${styles.primBtn}`)].forEach((btn) => {
      btn.addEventListener("mousedown", () => {
        event.preventDefault();
        event.stopPropagation();
      });
      btn.addEventListener("mouseup", () => {
        event.preventDefault();
        event.stopPropagation();
      });
    });

    [...this.element.querySelectorAll(`.${styles.secBtn}`)].forEach((btn) => {
      btn.addEventListener("mousedown", () => {
        event.preventDefault();
        event.stopPropagation();
      });
      btn.addEventListener("mouseup", () => {
        event.preventDefault();
        event.stopPropagation();
      });
    });

    // Prevent the user from creating a ghost image when swiping
    this.imgs.forEach((img) => {
      img.setAttribute("draggable", "false");
    });

    this.updateNavigationBar();
    this.getMinCardHeight();
    this.initializeCardSizes();
    this.resizer();

    this.element.setAttribute("data-cards-visible", "");

    // Sometimes the cards getting new heights causes the scrollbar to appear
    // Which then changes the width of the container
    if (
      this.width !== this.container.offsetWidth ||
      this.container.classList.contains(styles.uncontrolled)
    ) {
      this.width = this.container.offsetWidth;
      this.topCardWidth = this.container.offsetWidth;
      this.initializeCardSizes();
    }
    setTabIndex(this.cards, this.currentIndex, false);
    callbackWhenInView({
      elems: [this.element],
      callback: () => {
        this.sendAnalytics();
      },
      rootMargin: "0px",
      threshold: "0.5"
    });
  }

  initializeCardSizes() {
    let zIndexNum = 0;
    this.cards.forEach((card) => {
      card.style.zIndex = this.numCards - zIndexNum;
      zIndexNum++;
    });
    if (!this.originalVariant) return;
    // Set the card heights and widths
    if (this.width < 580) {
      this.initMobileCards();
    } else {
      this.initDesktopCards();
    }
  }

  initMobileCards() {
    // 5:8 on small screens, 2:1 on larger screens
    let numVisCards = this.numCards > 5 ? 4 : this.numCards - 1;
    this.topCardWidth = this.topCardWidth - 4 * numVisCards - 32;
    this.topCardHeight = this.topCardWidth * 1.6;
    this.element.style.height = this.topCardHeight + "px";
    let i = 0;
    let zIndexNum = 0;
    this.cards.forEach((card) => {
      let cardHeight = this.topCardHeight;
      cardHeight = cardHeight - i * 16;
      let cardWidth = cardHeight * 0.625;
      card.style.height = cardHeight + "px";
      card.style.width = cardWidth + "px";
      card.style.minHeight = "0px";
      card.style.marginLeft = this.topCardWidth - cardWidth + 4 * i + 16 + "px";
      card.style.marginTop = 8 * i + "px";
      card.style.zIndex = this.numCards - zIndexNum;
      zIndexNum++;
      if (i < 4) {
        i++;
      }
    });
  }

  initDesktopCards() {
    if (
      this.container.classList.contains(styles.pop) &&
      window.innerWidth < 840
    ) {
      this.topCardWidth -= 100;
    } else if (this.container.classList.contains(styles.uncontrolled)) {
      this.topCardWidth -= 150;
    }
    let numVisCards = this.numCards > 5 ? 4 : this.numCards - 1;
    this.topCardWidth = this.topCardWidth - 8 * numVisCards;
    this.topCardHeight = this.topCardWidth * 0.5;
    this.element.style.height = this.topCardHeight + "px";
    this.element.style.minHeight = this.minHeight + "px";
    let i = 0;
    let zIndexNum = 0;
    this.cards.forEach((card) => {
      let cardHeight = this.topCardHeight;
      let minHeight = this.minHeight - i * 16;
      cardHeight = cardHeight - i * 16;
      let cardWidth = cardHeight * 2;
      card.style.height = cardHeight + "px";
      card.style.width = cardWidth + "px";
      card.style.minHeight = minHeight + "px";
      card.style.zIndex = this.numCards - i;
      let leftMargin = this.topCardWidth - cardWidth + 8 * i;
      this.container.classList.contains(styles.pop) && window.innerWidth < 840
        ? (leftMargin += 50)
        : this.container.classList.contains(styles.uncontrolled)
        ? (leftMargin += 75)
        : "";
      card.style.marginLeft = leftMargin + "px";
      card.style.marginTop = 8 * i + "px";
      card.style.zIndex = this.numCards - zIndexNum;
      zIndexNum++;
      if (i < 4) {
        i++;
      }
    });
  }

  addDragEvents(card) {
    card.addEventListener("mousedown", this.dragStart);
    card.addEventListener("mouseup", this.dragEnd);
    card.addEventListener("touchstart", this.dragStart);
    card.addEventListener("touchmove", this.drag);
    card.addEventListener("touchend", this.dragEnd);
  }

  removeDragEvents(card) {
    card.removeEventListener("mousedown", this.dragStart);
    card.removeEventListener("mouseup", this.dragEnd);
    card.removeEventListener("touchstart", this.dragStart);
    card.removeEventListener("touchmove", this.drag);
    card.removeEventListener("touchend", this.dragEnd);
  }

  draggingStarted(event) {
    this.startX = event.clientX ? event.clientX : event.changedTouches[0].pageX;
    this.startY = event.clientY ? event.clientY : event.changedTouches[0].pageY;
  }

  dragging(event) {
    const minValue = 5;

    this.clientX = event.clientX
      ? event.clientX
      : event.changedTouches[0].pageX - this.startX;
    this.clientY = event.clientY
      ? event.clientY
      : event.changedTouches[0].pageY - this.startY;

    if (Math.abs(this.clientX) > minValue && event.cancelable) {
      event.preventDefault();
    }
  }

  draggingEnded(event) {
    this.endX = event.clientX ? event.clientX : event.changedTouches[0].pageX;
    this.endY = event.clientY ? event.clientY : event.changedTouches[0].pageY;

    if (this.startX > this.endX && this.currentIndex < this.numCards - 1) {
      this.removeCard(this.cards[this.currentIndex], false);
    } else if (this.startX < this.endX && this.currentIndex > 0) {
      this.currentIndex--;
      this.bringCardBack(this.cards[this.currentIndex], false);
    } else if (
      this.startX === this.endX &&
      this.currentIndex !== this.numCards - 1
    ) {
      this.removeCard(this.cards[this.currentIndex], false);
    }
  }

  removeCard(card, focusSelectedCard) {
    card.classList.add(styles.disappear);
    this.removeDragEvents(card);
    this.currentIndex++;
    this.resizeCards();
    setTimeout(
      () => {
        card.classList.add(styles.removed);
        card.classList.remove(styles.disappear);
        this.addDragEvents(this.cards[this.currentIndex]);
        this.sendAnalytics();
      },
      matchMedia("(prefers-reduced-motion)").matches ? 0 : 400
    );
    setTabIndex(this.cards, this.currentIndex, focusSelectedCard);
    this.updateNavigationBar();
  }

  bringCardBack(card, focusSelectedCard) {
    card.classList.remove(styles.removed);
    card.classList.add(styles.reappear);
    this.removeDragEvents(this.cards[this.currentIndex + 1]);
    setTimeout(
      () => {
        card.classList.remove(styles.reappear);
        this.resizeCards();
        this.addDragEvents(card);
        this.sendAnalytics();
      },
      matchMedia("(prefers-reduced-motion)").matches ? 0 : 500
    );
    setTabIndex(this.cards, this.currentIndex, focusSelectedCard);
    this.updateNavigationBar();
  }

  sendAnalytics() {
    if (!this.viewedSteps.includes(this.currentIndex)) {
      stepChangeAnalytics(
        this.element,
        this.cards[this.currentIndex],
        this.currentIndex,
        this.numCards,
        "card-stack"
      );
    }
    this.viewedSteps.push(this.currentIndex);
  }

  resizeCards() {
    if (!this.originalVariant) return;
    let i = 0;
    for (let index = 0; index < this.numCards; index++) {
      let cardHeight = this.topCardHeight;
      let currentCard = this.cards[index];

      // Need to check, if card is before current index
      // If it is, height and width should be topCardHeight and topCardWidth
      if (index < this.currentIndex) {
        currentCard.style.height = this.topCardHeight + "px";
        currentCard.style.width = this.topCardWidth + "px";
      } else if (this.width < 580) {
        this.mobileResize(currentCard, cardHeight, i);
        if (i < 4) {
          i++;
        }
      } else {
        this.desktopResize(currentCard, cardHeight, i);
        if (i < 4) {
          i++;
        }
      }
    }
  }

  mobileResize(currentCard, cardHeight, i) {
    cardHeight = cardHeight - i * 16;
    let cardWidth = cardHeight * 0.625;
    currentCard.style.height = cardHeight + "px";
    currentCard.style.width = cardWidth + "px";
    currentCard.style.minHeight = "0px";
    currentCard.style.marginInlineStart =
      this.topCardWidth - cardWidth + 4 * i + 16 + "px";
    currentCard.style.marginTop = 8 * i + "px";
  }

  desktopResize(currentCard, cardHeight, i) {
    this.element.style.minHeight = this.minHeight + "px";
    cardHeight = cardHeight - i * 16;
    let cardWidth = cardHeight * 2;
    let minHeight = this.minHeight - i * 16;
    currentCard.style.height = cardHeight + "px";
    currentCard.style.width = cardWidth + "px";
    currentCard.style.minHeight = minHeight + "px";
    let leftMargin = this.topCardWidth - cardWidth + 8 * i;
    this.container.classList.contains(styles.pop) && window.innerWidth < 840
      ? (leftMargin += 50)
      : this.container.classList.contains(styles.uncontrolled)
      ? (leftMargin += 75)
      : "";
    currentCard.style.marginInlineStart = leftMargin + "px";
    currentCard.style.marginTop = 8 * i + "px";
  }

  resizer() {
    if (!this.originalVariant) return;
    this.width = this.container.offsetWidth;
    this.topCardWidth = this.container.offsetWidth;
    this.topCardHeight = this.container.offsetHeight;
    let numVisCards =
      this.numCards - this.currentIndex > 5
        ? 4
        : this.numCards - 1 - this.currentIndex;

    if (this.width < 580) {
      // 5:8 on small screens, 2:1 on larger screens
      this.topCardWidth = this.topCardWidth - 4 * (numVisCards - 1) - 32;
      this.topCardHeight = this.topCardWidth * 1.6;
      this.element.style.height = this.topCardHeight + "px";
    } else {
      this.topCardWidth = this.topCardWidth - 8 * this.numCards;
      if (
        window.innerWidth <= 840 &&
        this.container.classList.contains(styles.pop)
      ) {
        this.topCardWidth -= 100;
      } else if (this.container.classList.contains(styles.uncontrolled)) {
        this.topCardWidth -= 150;
      }
      this.getMinCardHeight();
      this.topCardHeight = this.topCardWidth * 0.5;
      this.element.style.height = this.topCardHeight + "px";
    }
    this.resizeCards();
  }

  updateNavigationBar() {
    const isCover = this.cards[this.currentIndex].classList.contains(
      styles.coverCard
    );
    const isLastCard = this.currentIndex === this.numCards - 1;

    this.navigationBar.dataset.isCover = isCover;
    this.navigationBar.dataset.isEnd = isLastCard;

    if (this.navigationBarText) {
      // If there is a cover, it doesn't count towards the "page count", so this logic remedies that
      const hasCover = [...this.cards].some((card) =>
        card.classList.contains(styles.coverCard)
      );
      const normalText = hasCover
        ? `${this.currentIndex}/${this.numCards - 1}` // 3 cards (includes Title) = 1/2 cards
        : `${this.currentIndex + 1}/${this.numCards}`; // 3 cards = 1/3 cards
      const coverText =
        this.stepsTranslation && this.numCards > 2 // We don't want it to just say "1 Steps", so this secondary logic prevents that
          ? `${this.numCards - 1} ${this.stepsTranslation}`
          : "";
      this.navigationBarText.innerText = isCover ? coverText : normalText;
    }
  }

  leftBtnClick(event) {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      // Check if the arrow was clicked with mouse vs keyboard. If clicked by keyboard, set second parameter to true so that select card receives focus
      this.bringCardBack(this.cards[this.currentIndex], event.detail === 0);
    }
  }

  rightBtnClick(event) {
    if (this.currentIndex < this.numCards - 1) {
      // Check if the arrow was clicked with the mouse vs keyboard. If clicked by keyboard, set second parameter to true so that select card receives focus
      this.removeCard(this.cards[this.currentIndex], event.detail === 0);
    }
  }

  resetDeck() {
    let currentCard = this.currentIndex;
    for (let i = currentCard; i > 0; i--) {
      this.currentIndex = i - 1;
      let card = this.cards[this.currentIndex];
      this.bringCardBack(card);
    }
  }

  getMinCardHeight() {
    if (!this.originalVariant) return;
    let height = 0;
    for (let i = 0; i < this.numCards; i++) {
      this.element.style.minHeight = "auto";
      this.element.style.height = "auto";
      this.cards[i].style.minHeight = "100%";
      this.cards[i].style.height = "auto";
      this.container.setAttribute("data-adjust-height", "");

      let cardHeight = this.cards[i].offsetHeight;
      height = cardHeight > height ? cardHeight : height;

      this.cards[i].style.minHeight = this.minHeight + "px";
      this.container.removeAttribute("data-adjust-height");
      this.element.style.height = this.topCardHeight + "px";
    }
    this.minHeight = height;
  }
}

export const init = () => {
  initComponent("card-stack", (element) => new CardStack(element));
};
init();
