import formStyles from "../form-styles/styles.css";
import SteppedForms from "../../stepped-form/browser";
import { initComponent } from "../../../utilities/common";

export default class EmailField {
  constructor(element) {
    this.input = element.querySelector(`.${formStyles.inputField}`);
    this.wrapper = this.input.closest(`[data-type="email-field"]`);
    this.validationSpan = this.wrapper.querySelector("[data-custom-message]");

    this.input.dataset.changed = true;
    this.input.addEventListener("input", () => {
      this.input.dataset.changed = true;
      this.input.classList.remove(formStyles.invalid);
    });
    this.input.addEventListener("change", () => {
      this.checkIsValid(false);
    });

    // These functions are called in the Stepped Forms Submit Handler. They are used to check that the input field is valid and to retrieve the phone field input value
    element.getValues = () => this.getValues(this.input);
    element.checkIsValid = (asynchronous = true) =>
      this.checkIsValid(asynchronous);
  }

  emailValidation(resolve, reject) {
    if (!this.input.required && !this.input.value) {
      this.input.dataset.changed = false;
      resolve();
      return;
    } else if (
      this.input.dataset.validity === "true" &&
      this.input.dataset.changed === "false"
    ) {
      resolve();
      return;
    } else if (this.input.required && !this.input.value) {
      const externalError = this.wrapper.dataset.conditionalFieldRequired; // This is used by the checkbox-radio, specifically with subscriptions which have custom errors
      reject(externalError || this.input.dataset.required);
      return;
    }
    this.input.dataset.changed = false;

    this.input.setCustomValidity("Checking email"); // set a default validity message so the form won't allow a submission until the email is done checking

    this.wrapper.setAttribute("checking", ""); // will cause a loading animation to be injected

    const url = `${
      window.PUBLIC_ENV.CONSOLIDATION_PREFIX
    }/api/emailVerify?address=${encodeURIComponent(this.input.value)}`;
    const req = new XMLHttpRequest();
    req.open("GET", url);

    req.addEventListener("load", () => {
      const response = JSON.parse(req.response)?.email;

      this.wrapper.removeAttribute("checking"); // remove the loading animation

      let validationMessage;
      if (!this.input.required && !this.input.value) {
        resolve();
      } else if (this.input.required && !this.input.value) {
        reject(this.input.dataset.required);
      } else if (response?.status === "invalid") {
        switch (response?.error_code) {
          case "email_address_invalid":
            validationMessage = this.input.dataset.malformed;
            break;
          case "email_domain_invalid":
            validationMessage = this.input.dataset.invalidDomain;
            break;
          case "email_account_invalid":
            validationMessage = this.input.dataset.invalidAccount;
            break;
        }
        reject(validationMessage);
      } else {
        resolve();
      }
    });

    req.send();
  }

  setValidity(asynchronous, isValid, validationMessage) {
    if (isValid) {
      SteppedForms.setAsValid(this.input);
      this.input.setCustomValidity("");
      return asynchronous && Promise.resolve(); // Only return a Promise if it's asynchronous
    } else {
      this.input.setCustomValidity(validationMessage);
      this.validationSpan.innerText = validationMessage;
      SteppedForms.setAsInvalid(this.input);
      return asynchronous && Promise.reject();
    }
  }

  // eslint-disable-next-line
  getValues = (input) => {
    const value = input.value;
    return value && value !== "" ? [value] : [];
  };

  checkIsValid = (asynchronous = true) => {
    return new Promise((resolve, reject) =>
      this.emailValidation(resolve, reject)
    ).then(
      () => this.setValidity(asynchronous, true),
      (error) => this.setValidity(asynchronous, false, error)
    );
  };
}

export const init = () => {
  initComponent("email-field", (element) => new EmailField(element));
};
init();
