import formStyles from "../form-styles/styles.css";
import radioStyles from "../../form-components/checkbox-radio/styles.css";
import styles from "./styles.css";
import { initComponent } from "../../../utilities/common";

export default class AddressField {
  constructor(element) {
    this.element = element;

    this.addressField = element.querySelector(`.${formStyles.inputField}`);
    this.validationContainer = element.querySelector(
      `.${styles.validationContainer}`
    );
    this.validationLabel = element.querySelector(`.${styles.validationLabel}`);
    this.validationOptionKeep = element.querySelector(
      `.${styles.validationOptionKeep}`
    );
    this.validationOption1 = element.querySelector(
      `.${styles.validationOption1}`
    );
    this.validationOption2 = element.querySelector(
      `.${styles.validationOption2}`
    );
    this.validationOptions = [
      this.validationOptionKeep,
      this.validationOption1,
      this.validationOption2
    ];
    // const submitButton = element.closest('form').querySelector('[type="submit"]');

    this.fieldIsValid = !this.addressField.required;
    this.awaitingAddressSelection = false;

    this.addressField.addEventListener("blur", () => {
      if (
        this.addressField.getAttribute("data-changed") === "false" ||
        (!this.addressField.required &&
          this.addressField.getAttribute("value") === null)
      ) {
        this.fieldIsValid = true;
        return;
      }
      this.checkValidation();
    });

    this.addressField.addEventListener("focus", () => {
      this.addressField.classList.remove(formStyles.valid, formStyles.invalid);
      this.validationContainer.hidden = true;
      this.validationOptions.forEach((v) => (v.hidden = true));
    });

    this.addressField.addEventListener("input", () => {
      this.addressField.setAttribute("data-changed", "true");
      this.addressField.setAttribute("data-user-modified", "true");
      this.fieldIsValid = false;
      this.awaitingAddressSelection = false;
    });

    this.validationOptions.forEach((o) => {
      o.addEventListener("click", () => {
        this.addressField.value = o.querySelector(`input[type=radio]`).value;
        this.fieldIsValid = true;
        this.awaitingAddressSelection = false;
        this.validationContainer.hidden = true;
        // submitButton.removeAttribute('disabled');
      });
    });

    // submitButton.addEventListener("click", () => {
    //     if(!fieldIsValid) {
    //         checkValidation()
    //     }
    // });
  }

  checkValidation() {
    if (!this.addressField.required && this.addressField.value === "") {
      this.fieldIsValid = true;
    } else if (this.addressField.required && this.addressField.value === "") {
      this.fieldIsValid = false;
    }

    const url = `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/addressVerify?address=${this.addressField.value}`;

    //Disable the submit button while validation is being processed.
    // submitButton.setAttribute('disabled', 'disabled');

    const req = new XMLHttpRequest();
    req.addEventListener("load", () => {
      let response = JSON.parse(req.responseText);

      this.validationContainer.hidden = false;
      this.validationOptionKeep.querySelector(`input[type=radio]`).value =
        this.addressField.value;
      this.validationOptionKeep.hidden = false;

      switch (response.length) {
        case 0:
          this.validationLabel.innerHTML =
            this.validationContainer.querySelector("[data-no-match]").innerHTML;
          break;
        case 1:
          this.validationLabel.innerHTML =
            this.validationContainer.querySelector(
              "[data-validation-choice]"
            ).innerHTML;
          this.mapValidationOption(this.validationOption1, response[0]);
          break;
        default:
          this.validationLabel.innerHTML =
            this.validationContainer.querySelector(
              "[data-validation-choice]"
            ).innerHTML;
          this.mapValidationOption(this.validationOption1, response[0]);
          this.mapValidationOption(this.validationOption2, response[1]);
          break;
      }

      this.awaitingAddressSelection = true;
    });
    req.open("POST", url);
    req.send();
  }

  mapValidationOption(option, candidate) {
    option.querySelector(`input[type=radio]`).value = [
      candidate.deliveryLine1,
      candidate.lastLine
    ].join(", ");
    option.querySelector(`.${radioStyles.label}`).innerHTML = [
      candidate.deliveryLine1,
      candidate.lastLine
    ].join(", ");
    option.hidden = false;
    option.querySelector(`input[type=radio]`).checked = false;
    this.validationLabel.hidden = false;
  }
}

export const init = () => {
  initComponent("address-field", (element) => {
    const obj = new AddressField(element);

    element.getValues = () => {
      const value = obj.addressField.value;
      return value !== "" && value !== null ? [value] : [];
    };

    element.checkIsValid = () => {
      return !obj.awaitingAddressSelection && obj.fieldIsValid;
    };
  });
};
init();
