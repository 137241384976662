import { initComponent } from "../../utilities/common";

export const init = () => {
  initComponent("select-box", (element) => {
    element.getValues = () => {
      const select = element.querySelector("select");
      return [select.options[select.selectedIndex].label];
    };
  });
};
init();
