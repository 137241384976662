import { unveil } from "../unveil/browser";
import { remoteComponentSubscribe } from "components/remote-trigger/browser";
import customEvent from "../custom-event/browser";
import A11yDialog from "a11y-dialog";
import { initComponent } from "../../utilities/common";

export default class Modal {
  constructor(element) {
    const modal = new A11yDialog(element);
    const closeButton = element.querySelectorAll("[data-modal-close]");

    element.addEventListener("mousedown", (event) => {
      event.target === element ? modal.hide() : "";
    });

    //Occassionally there are nested modals (ex: the address block verification modal inside of a form that is in within a modal).
    //To prevent a nested modal from closing the parent modal, only add the eventlistener to the close button if it's closest parent that contains the `data-type="modal"` is `element`
    closeButton.forEach((button) => {
      if (button.closest("[data-type='modal']") === element) {
        button.addEventListener("click", () => {
          modal.hide();
        });
      }
    });

    modal.on("show", () => {
      customEvent(document, "showScrim");
      [...element.querySelectorAll('[data-unveil="true"]')].forEach((image) =>
        unveil(image)
      );
    });
    modal.on("hide", () => {
      customEvent(document, "hideScrim");
      if (element.querySelector('[data-type="media-player"]'))
        window.dispatchEvent(new CustomEvent("pause-all-players"));
    });
    remoteComponentSubscribe(element.id, () => {
      modal.show();
    });
  }
}

export const init = () => {
  initComponent("modal", (element) => new Modal(element));
};
init();
