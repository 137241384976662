import formStyles from "../form-styles/styles.css";
import DOMPurify from "dompurify";
import SteppedForms from "../../stepped-form/browser";
import { initComponent } from "../../../utilities/common";
import { remoteComponentPublish } from "../../remote-trigger/browser";

export default class SelectGroup {
  constructor(element) {
    this.element = element;
    this.hasRemoteTriggers = this.element.dataset.hasRemoteTriggers === "true";
    const realRadioButtons = element.querySelectorAll(`input[type=radio]`);
    const realCheckBoxes = element.querySelectorAll(`input[type=checkbox]`);

    this.otherField = element.querySelector('[data-type="other-field" ]');

    this.otherInput = "";
    this.textArea = "";

    if (this.otherField) {
      this.otherInput = this.otherField.querySelector('[data-type="other"]');
      this.textArea = this.otherField.getElementsByTagName("TEXTAREA")[0];

      this.textArea.setAttribute("data-do-not-read", "");
      this.textArea.required = false;

      this.textArea.addEventListener("blur", () => {
        this.otherInput.value = DOMPurify.sanitize(this.textArea.value, {
          ALLOWED_TAGS: []
        });
      });
    }

    // Radio buttons don't trigger "change" event when the radio button becomes false so this manually triggers the "change" event
    if (this.hasRemoteTriggers && realRadioButtons) {
      this.element.addEventListener("change", () => {
        // Find the inputs with remoteTriggerIds and publish the event
        realRadioButtons.forEach((radio) => {
          const remoteTriggerId = radio?.dataset?.remoteTriggerId;
          const eventType = radio?.dataset?.remoteTriggerEventType;
          if (remoteTriggerId && eventType)
            remoteComponentPublish(remoteTriggerId, eventType);
        });
      });
    }

    [...realRadioButtons, ...realCheckBoxes].forEach((rb) =>
      rb.addEventListener("change", () => this.selectBoxOnChange())
    );

    // These functions are called in the Stepped Forms Submit Handler. They are used to check that the input field is valid and to retrieve the phone field input value
    element.getValues = () => this.getValues();
    element.checkIsValid = () => this.checkIsValid();

    // These functions especially help the select-group "blend in" with the other inputs when stepped form is doing mass validation of form components
    element.checkValidity = () => this.checkIsValid();
    element.name =
      element?.querySelector("[data-type='heading']")?.innerText?.trim() ||
      "Select Group";
  }

  selectBoxOnChange() {
    if (this.element.dataset.isRequired === "true") {
      SteppedForms.checkValidation(this.element);
    }
    if (this.otherField && this.otherInput.checked) {
      this.textArea.required = true;
      this.textArea.removeAttribute("data-do-not-read");
    } else if (this.otherField) {
      this.textArea.required = false;
      this.textArea.setAttribute("data-do-not-read", "");
      this.textArea.removeAttribute("data-validity");
      this.textArea.classList.remove(formStyles.invalid);
      this.textArea.classList.add(formStyles.valid);
    }
  }

  getValues() {
    const arr = [...this.element.querySelectorAll("input:checked")].map((i) =>
      i.dataset.type !== "other" ? i.parentElement.innerText : ""
    );
    return arr;
  }

  checkIsValid() {
    if (this.element.dataset.isRequired === "true") {
      const inputs = this.element.querySelectorAll("input");
      return [...inputs].some((i) => i?.checked); // See if any of the inputs are checked
    }
    return true;
  }
}

export const init = () => {
  initComponent("select-group", (element) => new SelectGroup(element));
};
init();
