// Dispatches a custom event. Uses the old syntax if CustomEvent is not available.
export default (element, type, dictionary = {}) => {
    let event;

    if (typeof window.CustomEvent === "function") {
        event = new CustomEvent(type, dictionary);
    } else {
        const {bubbles, cancelable, detail} = dictionary;
        event = document.createEvent('Event');
        event.initEvent(type, bubbles, cancelable);
        event.detail = detail;
    }

    element.dispatchEvent(event);
}
