import formStyles from "../form-styles/styles.css";
import styles from "./styles.css";
import selectBox from "../../select-box";
import DOMPurify from "dompurify";
import SteppedForms from "../../stepped-form/browser";
import { remoteComponentPublish } from "../../remote-trigger/browser";
import { callbackWhenInView, initComponent } from "../../../utilities/common";

let isUSA = false;
let isValidateCountry = false;
let nonUSAutoCompleteResponse = {};
let nonUSAutoCompleteResponseAddrCountry = {};
let nonUSAddressVerifyResponse = {};
let nonUSFullAddress = "";
let nonUSAddressSelectionList = [];
let nonUSCountryMatchFlag = "";
let nonUSAddressValidationFlag = "";
let nonUSOptionDetailList = [];
let nonUSAutoCompleteSelected = {};
let emptyStrikes = 0;

export default class AddressBlock {
  constructor(element) {
    this.element = element;

    const geocountry = document
      ?.querySelector('meta[name="geocountry"]')
      ?.getAttribute("content");

    this.preciselyValidateCountries = element.querySelector(
      `[data-type="precisely-validate-countries"]`
    );
    this.preciselyExcludeCountries = element.querySelector(
      `[data-type="precisely-exclude-countries"]`
    );
    this.preciselyIK = element.querySelector(`[data-type="precisely-ik"]`);
    this.autocompleteSetting = element.querySelector(
      `[data-type="autocompleteSetting"]`
    );
    this.nbrOfInitialChars = Number(
      element.querySelector(`[data-type="nbrOfInitialChars"]`)?.value
    );
    this.strikeLimit = Number(
      element.querySelector(`[data-type="strikeLimit"]`)?.value
    );
    this.triggerIntervalInChar = Number(
      element.querySelector(`[data-type="triggerIntervalInChar"]`)?.value
    );
    this.triggerIntervalInMilliSecond = element.querySelector(
      `[data-type="triggerIntervalInMilliSecond"]`
    );
    this.addrField = element.querySelector(
      `[data-type="address-block-street"]`
    );
    this.autocompleteWrapper = element.querySelector(
      "[data-autocomplete-wrapper]"
    );
    this.autocompleteArea = element.querySelector(
      `.${styles.autocompleteOptions}`
    );
    this.autocompleteOptions = [
      ...this.autocompleteArea.querySelectorAll(`[data-autocomplete-option]`)
    ];
    this.noAutocompleteOption = this.autocompleteArea.querySelector(
      `[data-no-autocomplete]`
    );
    this.isSingleAddressField =
      this.autocompleteWrapper.hasAttribute("data-single-field");
    this.countryWrapper = element.querySelector(
      `[data-type="address-block-country"]`
    );
    this.stateWrapper = element.querySelector(
      `[data-type="address-block-state"]`
    );

    // this.stateWrapper doesn't include the label and parent div; it's poorly named. This this.stateWrapperParent includes the whole block.
    this.stateWrapperParent = element.querySelector(`.${styles.stateWrapper}`);
    this.hiddenCountryLocationCode = element.querySelector(
      `[data-type="location-code"]`
    );
    this.hiddenAddressCountryISO3 = element.querySelector(
      `[data-type="address-country-ISO3"]`
    );
    this.addrLine2Field = element.querySelector(
      `[data-type="address-block-addrLine2"]`
    );

    this.revealer = element.querySelector(`[data-reveal-line2]`);
    this.addrLine2Area = element.querySelector(`[data-addr-line2-area]`);
    this.addrLine2Input = this.addrLine2Area?.querySelector("input");

    this.cityWrapper = element.querySelector(`.${styles.cityWrapper}`);
    this.cityField = element.querySelector(`[data-type="address-block-city"]`);

    this.zipField = element.querySelector(`[data-type="address-block-zip"]`);
    this.zipWrapper = element.querySelector(`.${styles.zipWrapper}`);
    this.cityStateZipContainer = element.querySelector(
      `.${styles.cityStateZipContainer}`
    );
    this.addr2Wrapper = element.querySelector(`.${styles.addr2Wrapper}`);
    // This checks if the zip field is too long and forces mobile styling if it is
    this.zipWrapper = element.querySelector(`.${styles.zipWrapper}`);
    if (this.zipWrapper) {
      callbackWhenInView({
        elems: [this.zipWrapper],
        callback: () => {
          const zipLabelWidth = element.querySelector(
            `[data-type="address-block-zip"] ~ label`
          )?.offsetWidth;
          let addressBlockWidth = this.element.clientWidth;

          if (
            this.zipWrapper &&
            zipLabelWidth >= this.zipWrapper?.clientWidth &&
            this.cityStateZipContainer
          )
            this.cityStateZipContainer.classList.add(styles.forceMobile);

          window.addEventListener("resize", () => {
            const zipWrapperWidth = this.zipWrapper?.clientWidth;
            if (
              zipLabelWidth < zipWrapperWidth &&
              zipWrapperWidth > addressBlockWidth
            ) {
              addressBlockWidth = this.element.clientWidth;
              if (this.cityStateZipContainer)
                this.cityStateZipContainer.classList.remove(styles.forceMobile);
            } else if (zipLabelWidth > zipWrapperWidth) {
              addressBlockWidth = this.element.clientWidth;
              if (this.cityStateZipContainer)
                this.cityStateZipContainer.classList.add(styles.forceMobile);
            }
          });
        }
      });
    }

    this.countryField =
      this.countryWrapper?.querySelector("[data-target-guid]");
    this.countryOverride = element.querySelector(
      `[data-type="country-override"]`
    )?.value;

    isUSA =
      this.countryField?.value === "United States" ||
      this.countryOverride === "United States";

    this.stateField = this.stateWrapper?.querySelector("[data-target-guid]");

    this.stateSelect = element.querySelector("input[name=state]");

    this.stateValidation = this.stateWrapper?.querySelector(
      "[data-validity-message]"
    ).innerText;
    this.stateLabel = this.stateWrapper?.dataset.stateLabel || "";
    // variant for single to expandable fields
    this.expandableAddrFields = this.cityStateZipContainer?.hasAttribute(
      "data-expandable-addr-fields"
    );

    this.postalCode = element.querySelector(
      '[data-type="Typeahead-postalCode-input"]'
    );
    this.modalCloseBtn = element.querySelector("[data-modal-close]");
    this.modal = element.querySelector('[data-type="verification-modal"]');
    this.radioButtons = this.modal.querySelectorAll('[type="radio"]');
    this.addressArray = [];
    this.buttonWrapper = this.modal.querySelector("[data-modal-buttons]");
    this.editButton = this.modal.querySelector("[data-modal-edit]");
    this.continueButton = this.modal
      .querySelector("[data-modal-continue]")
      .querySelector('[data-type="button"]');
    this.noMatchText = this.modal.querySelector("[data-no-match]");
    this.suggestedAddressText = this.modal.querySelector(
      "[data-suggested-address]"
    );
    this.correctAddressText = this.modal.querySelector(
      "[data-correct-address]"
    );
    this.addr1 = element.querySelector(
      '[data-type="Typeahead-AddressLine1-input"]'
    );
    this.typeaheadAddr2 = element.querySelector(
      '[data-type="Typeahead-AddressLine2-input"]'
    );
    this.typeaheadCity = element.querySelector(
      '[data-type="Typeahead-City-input"]'
    );
    this.typeaheadState = element.querySelector(
      '[data-type="Typeahead-State-input"]'
    );
    this.addr3 = element.querySelector(
      '[data-type="Typeahead-AddressLine3-input"]'
    );
    this.addr4 = element.querySelector(
      '[data-type="Typeahead-AddressLine4-input"]'
    );
    this.locality = element.querySelector(
      '[data-type="Typeahead-Locality-input"]'
    );
    this.postalCode = element.querySelector(
      '[data-type="Typeahead-PostalCode-input"]'
    );
    this.addressVerifyRecordId = element.querySelector(
      '[data-type="Precisely-addressVerifyRecordId"]'
    );
    this.addressVerifyResponseAddr1 = element.querySelector(
      '[data-type="Precisely-MainAddressLine"]'
    );
    this.addressVerifyResponseCity = element.querySelector(
      '[data-type="Precisely-AreaName3"]'
    );
    this.addressVerifyResponsePostalCode1 = element.querySelector(
      '[data-type="Precisely-PostCode1"]'
    );
    this.addressVerifyResponsePostalCode2 = element.querySelector(
      '[data-type="Precisely-PostCode2"]'
    );
    this.addressVerifyResponseState = element.querySelector(
      '[data-type="Precisely-AreaName1"]'
    );
    this.addressVerifyCountryISO3 = element.querySelector(
      '[data-type="Precisely-Country"]'
    );
    this.addressVerifyResponseSingle = element.querySelector(
      '[data-type="Precisely-FormattedAddress"]'
    );
    this.addressVerifyLat = element.querySelector('[data-type="Precisely-X"]');
    this.addressVerifyLng = element.querySelector('[data-type="Precisely-Y"]');
    this.addressVerifyCountryComparisonFlag = element.querySelector(
      '[data-type="Precisely-CountryComparisonFlag "]'
    );
    this.addressVerifyResponsePrecisionLevel = element.querySelector(
      '[data-type="Precisely-PrecisionLevel"]'
    );
    this.addressVerifyResponsePrecisionCode = element.querySelector(
      '[data-type="Precisely-PrecisionCode"]'
    );
    this.addressVerifyMatch = element.querySelector(
      '[data-type="Precisely-Match"]'
    );
    this.addressVerifyResponseCountry = element.querySelector(
      '[data-type="Precisely-CountryName"]'
    );
    this.addressVerifyCountrySource = element.querySelector(
      '[data-type="Precisely-CountrySource"]'
    );
    this.addressVerifyQualityMet = element.querySelector(
      '[data-type="Precisely-LDS_AddressValidationFlag"]'
    );
    this.addressVerifyBestGeocodingCoverageByCountry = element.querySelector(
      '[data-type="Precisely-BestGeocodingCoverageByCountry"]'
    );
    this.addressVerifyAccepted = element.querySelector(
      '[data-type="Precisely-User_Confirmed_InputAddress"]'
    );

    this.addressSelections = element.querySelector("[verify-option-text]");

    this.addressFields = [
      this.addrField,
      this.addrLine2Field,
      this.cityField,
      this.stateField,
      this.zipField
    ];

    this.expanded = false; // add listener the first time only there is value in the input for if they click off

    this.setupAutocompleteOptions();
    this.setupAddressField();
    this.setupListeners(this.addressFields);
    this.setupVerificationListeners();
    this.handleFieldDisabling(true);
    this.getCountries();
    if (geocountry) this.setupDefaultCountry(geocountry);

    element.getValues = () => this.getValues();
    element.checkIsValid = () => this.checkIsValid();
  }

  setIntlUserInputFields(index) {
    let entry =
      index === 0 ? nonUSAutoCompleteSelected : nonUSOptionDetailList[index];
    const isSPOD = Boolean(entry?.RecordID);
    if (isSPOD) {
      this.addr1.value = entry["AddressLine1.input"] || "";
      this.typeaheadAddr2.value = entry["AddressLine2.input"] || "";
      this.addr3.value = entry["AddressLine3.input"] || "";
      this.addr4.value = entry["AddressLine4.input"] || "";
      this.typeaheadCity.value = entry["City.input"] || "";
      this.typeaheadState.value = entry["StateProvince.input"] || "";
      this.locality.value = entry["Locality.input"] || "";
      this.postalCode.value = entry["PostalCode.input"] || "";
    } else {
      this.addr1.value = entry?.AddressLine1 || "";
      this.typeaheadAddr2.value = entry?.AddressLine2 || "";
      this.addr3.value = entry?.AddressLine3 || "";
      this.addr4.value = entry?.AddressLine4 || "";
      this.typeaheadCity.value = entry?.City || "";
      this.typeaheadState.value = entry?.StateProvince || "";
      this.locality.value = "";
      this.postalCode.value = entry?.PostalCode || "";
    }
  }

  setIntlUserInputFieldsFromSelectedAutocompleteOption(option) {
    this.addr1.value = option?.AddressLine1 || "";
    this.typeaheadAddr2.value = option?.AddressLine2 || "";
    this.addr3.value = option?.AddressLine3 || "";
    this.addr4.value = option?.AddressLine4 || "";
    this.typeaheadCity.value = option?.City || "";
    this.typeaheadState.value = option?.StateProvince || "";
    this.locality.value = "";
    this.postalCode.value = option?.PostalCode || "";
  }

  setPreciselyFields() {
    let output = nonUSAddressVerifyResponse?.Output[0];
    this.addressVerifyRecordId.value = output?.RecordID || "";
    this.addressVerifyResponseAddr1.value = output?.MainAddressLine || "";
    this.addressVerifyResponseCity.value = output?.AreaName3 || "";
    this.addressVerifyResponsePostalCode1.value = output?.PostCode1 || "";
    this.addressVerifyResponsePostalCode2.value = output?.PostCode2 || "";
    this.addressVerifyResponseState.value = output?.AreaName1 || "";
    this.addressVerifyCountryISO3.value = output?.Country || "";
    this.addressVerifyResponseSingle.value = output?.FormattedAddress || "";
    this.addressVerifyLat.value = output?.X || "";
    this.addressVerifyLng.value = output?.Y || "";
    this.addressVerifyCountryComparisonFlag.value =
      output?.CountryComparisonFlag || "";
    this.addressVerifyResponsePrecisionLevel.value =
      output?.PrecisionLevel || "";
    this.addressVerifyResponsePrecisionCode.value = output?.PrecisionCode || "";
    this.addressVerifyMatch.value = output?.Match || "";
    this.addressVerifyResponseCountry.value = output?.CountryName || "";
    this.addressVerifyCountrySource.value = output?.CountrySource || "";
    this.addressVerifyQualityMet.value =
      output?.LDS_AddressValidationFlag || "";
    this.addressVerifyBestGeocodingCoverageByCountry.value =
      output?.BestGeocodingCoverageByCountry || "";
  }

  setAddressFields() {
    this.addressFields =
      this.isSingleAddressField || isValidateCountry
        ? [this.addrField]
        : [
            this.addrField,
            this.addrLine2Field,
            this.cityField,
            this.stateField,
            this.zipField
          ];
  }

  handleExpansionFields() {
    if (!isValidateCountry) {
      if (this.addr2Wrapper) this.addr2Wrapper.hidden = false;
      if (this.cityStateZipContainer) this.cityStateZipContainer.hidden = false;
    }
  }

  navigateAutocompleteOptions(index) {
    const option = this.autocompleteOptions[index];
    if (option && !option.hidden) {
      option.focus();
    }
  }

  /* eslint-disable */
  intlAddressVerify(addr, countryComparisonFlag) {
    let api =
      `${
        window.PUBLIC_ENV.CONSOLIDATION_PREFIX
      }/api/intladdressverify?address=${encodeURIComponent(addr)}` +
      `&ISO3=${encodeURIComponent(this.hiddenAddressCountryISO3.value)}` +
      `&countryComparisonFlag=${encodeURIComponent(countryComparisonFlag)}`;

    const req = new XMLHttpRequest();
    req.addEventListener("load", () => {
      nonUSAddressVerifyResponse = req.response;
      this.setPreciselyFields();
    });
    req.open("POST", api);
    req.responseType = "json";
    req.send();
  }

  preciselyik() {
    // get an instance key from Precisely.
    const api = `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/preciselyik`;
    const req = new XMLHttpRequest();
    req.addEventListener("load", () => {
      this.preciselyIK.value = "true";
    });
    req.open("GET", api);
    req.responseType = "json";
    req.send();
  }

  /* eslint-enable */

  confirmedInputAddress(selectedAddress, selectIndex) {
    // Final evaluation on non US address
    if (isValidateCountry) {
      if (nonUSCountryMatchFlag === "N") {
        if (selectIndex === 0 || selectedAddress.source === "autocomplete") {
          // user selected user single line address or a typeahead address
          // resubmit the address to the SPOD with CountryComparisonFlag set to 'F' and use ISO3 country
          // set addressVerifyAccepted to 'N/A' (user has NOT verified their entered address over the SPOD address)
          this.intlAddressVerify(this.addrField.value, "F");
          this.addressVerifyAccepted.value = "N/A";
        } else if (selectedAddress.source === "addressVerify") {
          // user selected the SPOD suggested address, set addressVerifyAccepted to 'T'
          this.addressVerifyAccepted.value = "T";
        }
      } else {
        if (nonUSAddressValidationFlag === "T") {
          // if LDS_AddressValidationFlag = 'T', popup address suggestion will not be shown then
          // set addressVerifyAccepted to 'N/A'.
          this.addressVerifyAccepted.value = "N/A";
        } else if (nonUSAddressValidationFlag === "F") {
          if (selectIndex === 0) {
            // if LDS_AddressValidationFlag = 'F' and user selected user single line address then
            // set addressVerifyAccepted to 'F'.
            this.addressVerifyAccepted.value = "F";
          } else if (selectedAddress?.source === "addressVerify") {
            // if LDS_AddressValidationFlag = 'F' and user selected SPOD suggested address then
            // set addressVerifyAccepted to 'T'.
            this.addressVerifyAccepted.value = "T";
          } else {
            //  selectedAddress?.source === "autocomplete"
            // if LDS_AddressValidationFlag = 'F' and user selected a typeahead address then
            // set addressVerifyAccepted to 'N/A'.
            this.addressVerifyAccepted.value = "N/A";
            this.intlAddressVerify(this.addrField.value, "T");
          }
        } else {
          this.addressVerifyAccepted.value = "N/A";
          this.intlAddressVerify(this.addrField.value, "T");
        }
      }
      this.setIntlUserInputFields(selectIndex);
    }
  }

  addressValidation(resolve, reject) {
    nonUSAutoCompleteResponseAddrCountry = {};
    nonUSAddressVerifyResponse = {};
    let isValid = true;
    this.addressFields.forEach((field) => {
      if (field?.required && field?.value === "") isValid = false;
    });

    if (!isValid) {
      reject?.();
      return;
    }

    const originalAddress = DOMPurify.sanitize(
      this.bundleAddress(
        this.addressFields.map((field) =>
          field && field !== this.countryField ? field.value : ""
        )
      ),
      {
        ALLOWED_TAGS: []
      }
    );

    const api = isUSA
      ? `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/addressVerify?address=${originalAddress}`
      : `${
          window.PUBLIC_ENV.CONSOLIDATION_PREFIX
        }/api/intladdressverify?address=${encodeURIComponent(
          this.addrField.value
        )}&ISO3=${encodeURIComponent(this.hiddenAddressCountryISO3.value)}`;
    const req = new XMLHttpRequest();
    req.addEventListener("load", () => {
      if (isUSA) {
        this.handleValidationModalDisplay(
          req.response,
          originalAddress,
          resolve,
          reject
        );
      } else if (isValidateCountry) {
        nonUSAddressVerifyResponse = req.response;
        this.setPreciselyFields();
        const countrySource =
          nonUSAddressVerifyResponse?.Output[0]?.CountrySource;
        nonUSCountryMatchFlag =
          countrySource === "Both Sources Match" ||
          countrySource === "ISO3 country code"
            ? "Y"
            : "N";
        nonUSAddressValidationFlag =
          nonUSAddressVerifyResponse?.Output[0]?.LDS_AddressValidationFlag;
        if (nonUSCountryMatchFlag === "N") {
          this.preHandleValidationModalDisplay(
            nonUSAddressVerifyResponse,
            originalAddress,
            resolve,
            reject
          );
        } else {
          this.handleValidationModalDisplay(
            nonUSAddressVerifyResponse,
            originalAddress,
            resolve,
            reject
          );
        }
      }
    });
    req.open("POST", api);
    req.responseType = "json";
    req.send();
  }

  preHandleValidationModalDisplay( // For non-US, if ISO3 country doesn't match address country, get autocomplete suggestions using address country.
    addressSuggestions,
    originalAddress,
    resolve,
    reject
  ) {
    const country = addressSuggestions?.Output[0]?.CountryName;
    const api =
      `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}` +
      `/api/intlautocomplete?address=${encodeURIComponent(
        this.addrField.value
      )}` +
      `&country=${encodeURIComponent(country)}`;
    const req = new XMLHttpRequest();
    req.addEventListener("load", () => {
      nonUSAutoCompleteResponseAddrCountry = req.response;
      this.handleValidationModalDisplay(
        addressSuggestions,
        originalAddress,
        resolve,
        reject
      );
    });
    req.open("GET", api);
    req.responseType = "json";
    req.send();
  }

  handleValidationModalDisplay(
    addressSuggestions,
    originalAddress,
    resolve,
    reject
  ) {
    nonUSAddressSelectionList = [];
    nonUSOptionDetailList = [];
    this.continueButton.disabled = true;
    this.radioButtons.forEach((r) => (r.checked = false));
    this.suggestedAddressText.hidden = true;
    this.noMatchText.hidden = true;
    this.correctAddressText.hidden = true;
    this.buttonWrapper.classList.remove(styles.reverse);
    this.formattedAddresses = this.formatAddressOptions(addressSuggestions);

    const verificationOptions = this.modal.querySelectorAll("[verify-option]");
    // Display original address and suggested addresses with suggested changes
    const addrOptions = this.modal.querySelectorAll("[suggestedAddress]");

    addrOptions.forEach((option) => {
      // clear all the suggested address and their warnings
      option.classList.remove(styles.warning);
      option.innerHTML = "";
    });

    verificationOptions.forEach((option, index) => {
      const verifyOptionsText = option.querySelector("[verify-option-text]");
      if (index === 0 && originalAddress) {
        if (isValidateCountry) nonUSAddressSelectionList.push(originalAddress);

        if (isValidateCountry && nonUSCountryMatchFlag === "N") {
          verifyOptionsText.innerHTML = nonUSFullAddress;
        } else {
          verifyOptionsText.innerHTML = originalAddress;
        }
      } else if (index > 0 && this.formattedAddresses[index]) {
        if (isValidateCountry)
          nonUSAddressSelectionList.push(this.formattedAddresses[index]);

        let comparedResult =
          isValidateCountry && nonUSCountryMatchFlag === "N"
            ? this.compareAddresses(
                this.formattedAddresses[index],
                nonUSFullAddress
              )
            : this.compareAddresses(
                this.formattedAddresses[index],
                originalAddress
              );
        verifyOptionsText.innerHTML = comparedResult;
        option.hidden = false;
      } else {
        option.hidden = true;
      }
    });

    const warnings = [...this.modal.querySelectorAll(`.${styles.warning}`)];

    if (isUSA) {
      if (addressSuggestions?.length > 0 && warnings.length === 0) {
        this.fieldIsValid = true;
        this.awaitingAddressSelection = false;
        // Input address matches suggested address so no need to show the validation modal.
        resolve?.();
        return;
      } else if (addressSuggestions?.length > 0 && warnings.length > 0) {
        // can't find exact address. Provide suggested addresses correction.
        this.suggestedAddressText.hidden = false;
      } else {
        // no suggested address. Just show what user entered and ask user to make changes or keep it.
        this.noMatchText.hidden = false;
        this.buttonWrapper.classList.add(styles.reverse);
      }
    } else if (isValidateCountry) {
      if (nonUSAddressValidationFlag === "T" && nonUSCountryMatchFlag === "Y") {
        // non USA if the validation flag is true and country matches, no need to show the validation modal.
        this.fieldIsValid = true;
        this.awaitingAddressSelection = false;
        this.addressVerifyAccepted.value = "N/A";
        resolve?.();
        return;
      } else if (
        addressSuggestions?.Output?.length > 0 &&
        warnings.length > 0
      ) {
        // can't find exact address. Provide suggested addresses correction.
        this.suggestedAddressText.hidden = false;
      } else {
        // no suggested address. Just show what user entered and ask user to make changes or keep it.
        this.noMatchText.hidden = false;
        this.buttonWrapper.classList.add(styles.reverse);
      }
    }

    // The edit button and close button will reject with the value "stay", specifically to prevent preemptive navigation on drawer stepped forms
    this.editButton.addEventListener("click", () => {
      this.modalCloseBtn.click();
      this.awaitingAddressSelection = true;
      reject?.({ stay: true });
    });

    this.modalCloseBtn.addEventListener("mouseup", () => {
      this.awaitingAddressSelection = true;
      reject?.({ stay: true });
    });

    const resolver = () => {
      this.expandableAddrFields && this.handleExpansionFields();
      this.continueButton.removeEventListener("click", resolver);
      this.modalCloseBtn.click();
      this.awaitingAddressSelection = false;
      resolve?.();
    };

    this.continueButton.addEventListener("click", resolver);
    this.displayValidationModal();
  }

  // Compare original address to suggested address and display suggested changes in red
  // eslint-disable-next-line class-methods-use-this
  compareAddresses(suggested, original) {
    const dataSource = suggested?.source ? suggested?.source : "addressVerify";
    let countryName = suggested.CountryName
      ? AddressBlock.capitalizeFirst(suggested?.CountryName.toLowerCase())
      : "";
    let suggestedAddressArray = isUSA
      ? [suggested.addr, suggested.city, suggested.state, suggested.zipCode]
      : [suggested.addr, countryName];

    suggestedAddressArray.forEach((item, index) => {
      const regex = new RegExp(item, "g");
      const matchString = original.match(regex);
      if (
        !matchString ||
        (isValidateCountry &&
          nonUSCountryMatchFlag === "N" &&
          dataSource === "addressVerify")
      ) {
        suggestedAddressArray[
          index
        ] = `<span class="${styles.warning}" suggestedAddress>${item}</span>`;
      }
    });
    const suggestedAddressString = suggestedAddressArray.join(" ");
    return suggestedAddressString;
  }

  // Used to validate addresses outside of the "United States"
  internationalValidation() {
    let valid = true;
    this.addressFields.forEach((field) => {
      if (
        field &&
        ((field.required && field.checkValidity()) || !field.required)
      ) {
        SteppedForms.setAsValid(field);
      } else if (field) {
        SteppedForms.setAsInvalid(field);
        valid = false;
      }
    });
    return valid;
  }

  setFlags(ISO3) {
    isUSA = ISO3 === "USA";
    isValidateCountry =
      (this.preciselyValidateCountries.value === "ALL" ||
        this.preciselyValidateCountries.value.includes(ISO3)) &&
      !this.preciselyExcludeCountries.value.includes(ISO3) &&
      ISO3 !== "USA";
  }

  static capitalizeFirst(string) {
    const words = string.split(" ");
    return words.map((w) => w.slice(0, 1).toUpperCase() + w.slice(1)).join(" ");
  }

  static isDuplicate(addr) {
    const found = nonUSOptionDetailList.some((list) => {
      const country = list?.countryName
        ? this.capitalizeFirst(list?.CountryName?.toLowerCase())
        : list?.country || "";
      return (
        list?.FormattedAddress === addr ||
        list?.FormattedAddress === addr + " " + country ||
        list?.FormattedAddress + " " + country === addr ||
        list?.addr === addr ||
        list?.addr === addr + " " + country ||
        list?.addr + " " + country === addr
      );
    });
    return found;
  }

  getCountries() {
    if (this.countryWrapper) {
      this.countryField.addEventListener("change", () => {
        this.countryOnChange();
      });
      this.setupListeners([this.countryField]);
    } else if (this.countryOverride) {
      if (this.stateWrapper) {
        this.getStates(this.hiddenCountryLocationCode.value);
        this.setupListeners([this.stateField]);
      }
      this.handleFieldDisabling(false);
    }
  }

  updateNonUSFullAddress() {
    isValidateCountry &&
      (nonUSFullAddress =
        this.addrField?.value + " " + this.countryField?.value);
  }

  countryOnChange() {
    if (this.countryField.value !== "") {
      SteppedForms.setAsValid(this.countryField);
      const selected =
        this.countryField.options[this.countryField.selectedIndex];
      const countryCode = selected.getAttribute("data-country-code");
      const addresscountryISO3 = selected.getAttribute("data-country-alpha3");
      this.setFlags(addresscountryISO3);
      this.setAddressFields();
      this.hiddenCountryLocationCode.value = countryCode;
      this.hiddenAddressCountryISO3.value = addresscountryISO3;
      if (this.hiddenAddressCountryISO3.value !== "") {
        this.addrField.value = "";
        if (!this.isSingleAddressField) {
          if (isValidateCountry) {
            if (this.cityStateZipContainer) {
              this.cityWrapper.hidden = true;
              this.stateWrapper.hidden = true;
              this.zipWrapper.hidden = true;
              this.zipField.value = "";
              this.cityField.value = "";
              this.stateField.value = "";
              this.stateField.removeAttribute("required");
              this.cityField.removeAttribute("required");
            }
            if (this.addr2Wrapper) {
              this.addr2Wrapper.hidden = true;
              this.addrLine2Field.value = "";
            }
            this.updateNonUSFullAddress();
          } else {
            if (this.cityStateZipContainer) {
              this.cityWrapper.hidden = false;
              this.stateWrapper.hidden = false;
              this.zipWrapper.hidden = false;
              this.stateField.setAttribute("required", "");
              this.cityField.setAttribute("required", "");
            }
            this.addr2Wrapper && (this.addr2Wrapper.hidden = false);
            this.revealer && (this.revealer.hidden = false);
            this.addrLine2Area && (this.addrLine2Area.hidden = true);
          }
        }
      }
      this.stateWrapper && !isValidateCountry && this.getStates(countryCode);
      this.hiddenCountryLocationCode.value !== "" &&
        this.handleFieldDisabling(false);
    } else {
      SteppedForms.setAsInvalid(this.countryField);
      if (!isValidateCountry) {
        const stateValues = [{ text: "", value: "" }];
        this.stateField && this.createStateSelectBox(stateValues);
      }
    }
  }

  getStates(countryCode) {
    const req = new XMLHttpRequest();
    req.addEventListener("load", () => {
      const states = req.response;
      let stateValues = [];
      if (req.status !== 200) {
        this.stateWrapperParent.hidden = true;
        this.stateField.removeAttribute("required");
      } else {
        this.stateWrapperParent.hidden = false;
        this.stateField.setAttribute("required", "");
        let valKey = "commonName";
        if (countryCode === "251") {
          valKey = "abbrev";
        }
        stateValues = states.locations.location.map((s) => ({
          text: s.commonName,
          value: s[valKey]
        }));
      }

      stateValues.unshift({ text: "", value: "" });

      this.createStateSelectBox(stateValues);
    });

    req.open(
      "GET",
      `${window.PUBLIC_ENV.STATIC_DIRECTORY}/country/state-${countryCode}.json`
    );
    req.responseType = "json";
    req.send();
  }

  createStateSelectBox(stateValues) {
    const newStateSelectBox = selectBox(
      {
        name: "state",
        label: this.stateLabel,
        options: stateValues,
        validate: {
          include: this.stateField.required,
          message: this.stateValidation
        },
        verificationDisplay: false,
        invertTextColor: this.invertTextColor,
        countryList: []
      },
      true
    );

    const template = document.createElement("template");
    template.innerHTML = newStateSelectBox.trim();

    this.updateSelectBoxOptions(this.stateField, template.content.firstChild);

    // Set default to "state"
    this.stateField.value = "";

    this.stateField.addEventListener("change", () => {
      this.stateField.setAttribute("data-changed", "true");
      this.stateField.setAttribute("data-user-modified", "true");
      this.fieldIsValid = false;
      if (isUSA) {
        if (this.stateField.checkValidity()) {
          SteppedForms.setAsValid(this.stateField);
        } else {
          SteppedForms.setAsInvalid(this.stateField);
        }
      } else SteppedForms.setAsValid(this.stateField);
    });
  }

  // Handles disabling and enabling address fields. When the locID is empty all address fields, except the country field should be disabled, but when it isn't empty fields are enabled
  handleFieldDisabling(disable) {
    this.addressFields.forEach((i) => {
      if (i && i !== this.countryField) i.disabled = disable;
    });
  }

  /* eslint-disable */
  updateSelectBoxOptions = (originalSelectBox, updatedSelectBox) => {
    // Remove options on the original placeholder select box
    [...originalSelectBox.querySelectorAll("option")].forEach((option) => {
      originalSelectBox.removeChild(option);
    });

    // Add real options
    [...updatedSelectBox.querySelectorAll("option")].forEach((option) => {
      originalSelectBox.appendChild(option);
    });
  };
  /* eslint-enable */

  setupAutocompleteOptions() {
    this.autocompleteWrapper.addEventListener("focusout", (e) => {
      // On "focusout", if the new target element isn't inside of the autocomplete, then close the autocomplete
      if (
        e.relatedTarget &&
        !e.relatedTarget.closest("[data-autocomplete-wrapper]")
      )
        this.autocompleteArea.hidden = true;
    });

    document.addEventListener("click", () => {
      this.autocompleteArea.hidden = true;
    });

    this.autocompleteOptions.forEach((o, index) =>
      o.setAttribute("data-autocomplete-option-index", index)
    );

    this.autocompleteOptions.forEach((o) => {
      o.addEventListener("click", () => {
        const addr = isUSA
          ? this.bundleAddress([o.data.street_line, o.data.city, o.data.state])
          : o.data.FormattedAddress;
        this.expandableAddrFields && this.handleExpansionFields();

        if (this.autocompleteWrapper.hasAttribute("data-single-field")) {
          this.addrField.value = addr;
        } else {
          this.addrField.value = isUSA
            ? o.data.street_line
            : o.data.FormattedAddress;
          if (!isUSA && !this.addrField.value.includes(o.data.Country))
            this.addrField.value += ` ${o.data.Country}`;
          this.cityField.value = isUSA ? o.data.city : "";
          this.stateField.value = isUSA ? o.data.state : "";
          this.stateField.dispatchEvent(new Event("change", { bubbles: true }));
          this.addressFields.forEach((f) =>
            f?.classList.remove(formStyles.invalid)
          );
        }
        this.updateNonUSFullAddress();
        this.setIntlUserInputFieldsFromSelectedAutocompleteOption(o.data);
        nonUSAutoCompleteSelected = o.data;
        if (isUSA) {
          const req = new XMLHttpRequest(); // US only. Call Smartystreet to get the zip code.
          req.addEventListener("load", () => {
            if (req.status === 200) {
              const candidates = req.response;
              if (candidates.length > 0 && isUSA === true) {
                const zipcode = candidates[0].components["zipcode"];
                if (this.zipField) this.zipField.value = zipcode;
                else if (this.isSingleAddressField)
                  this.addrField.value += ` ${zipcode}`;
              }
            }
          });
          let api =
            `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/addressVerify?address=` +
            encodeURIComponent(addr);
          req.open("POST", api);
          req.responseType = "json";
          req.send();
        }
        this.autocompleteArea.hidden = true;
      });

      o.addEventListener("keyup", (e) => {
        const key = e.keyCode;
        switch (key) {
          case 38:
            this.navigateAutocompleteOptions(
              parseInt(o.getAttribute("data-autocomplete-option-index")) - 1
            );
            break;
          case 40:
            this.navigateAutocompleteOptions(
              parseInt(o.getAttribute("data-autocomplete-option-index")) + 1
            );
            break;
          case 13:
            e.preventDefault();
            this.expandableAddrFields && this.handleExpansionFields();
            o.click();
            break;
        }
      });
    });
  }

  setupAddressField() {
    this.addrField.addEventListener("focus", () => {
      this.setFlags(this.hiddenAddressCountryISO3.value);
      if (isValidateCountry && this.preciselyIK.value.trim().length === 0) {
        this.preciselyik();
      }
    });
    /* autocompleteSetting -  (JIRA 7615)
    'off': autocomplete will not fire.
    'unconstrained': autocomplete fires at every keypress.
    'time': autocomplete fires at every ${triggerIntervalInMilliSecond}.
    'char': autocomplete fires at every ${triggerIntervalInChar}.
    autocomplete will wait for ${nbrOfInitialChars} before firing the first autocompletion. */
    this.addrField.addEventListener("input", () => {
      this.updateNonUSFullAddress();
      nonUSAutoCompleteSelected = {};

      const { timeIntervalCheck } = this.checkIntervals();

      if (this.countryField?.value || this.countryOverride) {
        const countryValue = this.countryOverride
          ? this.countryOverride
          : this.countryField?.value;

        if (this.autocompleteQualification()) {
          // The timeout is used for the time-based interval checking, as soon as a user enters an input a timer is started.
          // If they start typing again it will reset the timer, once the timer is finished it will call the API
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = false;
          }
          this.timeoutId = setTimeout(
            () => {
              this.autocompleteEndpoint(countryValue);
            },
            timeIntervalCheck ? this.triggerIntervalInMilliSecond?.value : 0
          );
        }
      }
    });

    // Keydown is used instead of keyup due to the tabindex of the inputs
    this.expandableAddrFields &&
      !this.expanded &&
      this.addrField.addEventListener("keydown", (event) => {
        const key = event.keyCode;
        if (key === 13 || key === 9) {
          this.handleExpansionFields();
        }
      });

    this.addrField.addEventListener("keyup", (event) => {
      const key = event.keyCode;
      if (key === 40) {
        this.navigateAutocompleteOptions(0);
      }
      // catch other events if they don't finish the auto complete, expand fields
      this.expandableAddrFields &&
        !this.expanded &&
        this.addrField.value &&
        this.addrField.addEventListener("blur", () => {
          this.handleExpansionFields();
          this.expanded = true;
        });
    });

    if (this.revealer) {
      this.revealer.addEventListener("click", () => {
        this.addrLine2Area.hidden = false;
        this.addrLine2Input.focus();
        this.revealer.hidden = true;
      });
    }
  }

  setupDefaultCountry(detectedCountry) {
    if (detectedCountry) {
      const countryOption = this.countryField.querySelector(
        `[data-country-alpha3=${detectedCountry}]`
      );
      if (countryOption) {
        this.countryField.value = countryOption.value;
        this.countryField.dispatchEvent(new Event("change", { bubbles: true }));
      } else this.countryField.selectedIndex = 0;
    }
  }

  autocompleteQualification() {
    const { firstAutocompleteCheck, timeIntervalCheck, charIntervalCheck } =
      this.checkIntervals();

    return (
      (isUSA || isValidateCountry) &&
      this.autocompleteSetting?.value !== "off" &&
      emptyStrikes < this.strikeLimit &&
      (this.autocompleteSetting?.value === "unconstrained" ||
        firstAutocompleteCheck ||
        timeIntervalCheck ||
        charIntervalCheck)
    );
  }

  checkIntervals() {
    const valueLength = this.addrField?.value?.length;
    const pastInitialLimit = valueLength >= this.nbrOfInitialChars;

    const firstAutocompleteCheck = pastInitialLimit && !this.timeoutId;
    const timeIntervalCheck =
      this.autocompleteSetting?.value === "time" &&
      pastInitialLimit &&
      this.timeoutId;
    const charIntervalCheck =
      this.autocompleteSetting?.value === "char" &&
      pastInitialLimit &&
      valueLength % this.triggerIntervalInChar === 0 &&
      this.timeoutId;
    return { firstAutocompleteCheck, timeIntervalCheck, charIntervalCheck };
  }

  setupListeners(fields) {
    fields.forEach((field) => {
      field?.addEventListener("blur", () => {
        const active = document.activeElement;
        const activeFields = this.addressFields
          .map((f) => active === f)
          .filter((f) => f === true);

        if (activeFields.length === 0) {
          if (!isUSA) {
            this.internationalValidation();
          } else if (!this.fieldIsValid) {
            // this.smartyStreetsValidation();
            // document.location.href = "#address-validation-options";
          }
        }
      });

      field?.addEventListener("focus", () => {
        field.classList.remove(formStyles.valid, formStyles.invalid);
      });

      field?.addEventListener("input", () => {
        field.setAttribute("data-changed", "true");
        field.setAttribute("data-user-modified", "true");
        this.fieldIsValid = false;
        this.awaitingAddressSelection = false;
      });
    });
  }

  setupVerificationListeners() {
    for (let i = 0; i < this.radioButtons.length; i++) {
      this.radioButtons[i].addEventListener("click", () => {
        this.continueButton.disabled = false;
        this.replaceAddressFieldValues(this.formattedAddresses[i], i);
        this.fieldIsValid = true;
        this.awaitingAddressSelection = false;
        if (isValidateCountry) {
          this.confirmedInputAddress(this.formattedAddresses[i], i);
        }
      });
    }
  }

  autocompleteEndpoint(countryValue) {
    const req = new XMLHttpRequest();
    const autocompleteAPI = isUSA
      ? `${
          window.PUBLIC_ENV.CONSOLIDATION_PREFIX
        }/api/autocomplete?address=${encodeURIComponent(this.addrField.value)}`
      : `${
          window.PUBLIC_ENV.CONSOLIDATION_PREFIX
        }/api/intlautocomplete?address=${encodeURIComponent(
          this.addrField.value
        )}&country=${encodeURIComponent(countryValue)}`;
    req.addEventListener("load", () => {
      if (req.status === 200) {
        const options = isUSA ? req?.response : req?.response?.Output;
        if (!isUSA) nonUSAutoCompleteResponse = req?.response;
        this.autocompleteOptions.forEach((o) => (o.hidden = true));
        const noOptions = isUSA
          ? options?.length === 0
          : !options.some((address) => address.FormattedAddress);
        emptyStrikes = noOptions ? emptyStrikes + 1 : 0; // If there are no results, they get a strike, otherwise reset their strikes
        for (let i = 0; i < 10 && i < options.length; i++) {
          const adr = isUSA ? options[i].text : options[i].FormattedAddress;
          this.autocompleteOptions[i].innerText = adr;
          this.autocompleteOptions[i].hidden = false;
          this.autocompleteOptions[i].data = options[i];
          this.noAutocompleteOption.hidden = true;
        }
      } else {
        this.autocompleteOptions.forEach((o) => (o.hidden = true));
        this.noAutocompleteOption.hidden = false;
      }
      this.autocompleteArea.hidden = false;
    });
    req.open("GET", autocompleteAPI);
    req.responseType = "json";
    req.send();
  }

  // eslint-disable-next-line class-methods-use-this
  bundleAddress(addressArray) {
    return addressArray.join(" ");
  }

  displayValidationModal() {
    const elem = document.createElement("div");
    elem.dataset.remoteTriggerId = this.element.dataset.modalremotetrigger;
    remoteComponentPublish(elem);
    this.awaitingAddressSelection = true;
  }

  // eslint-disable-next-line class-methods-use-this
  processOptions(originalAddress, suggestedAddresses, origin, list) {
    let address,
      optionalValues = {};
    let country = "";
    if (origin === "addressVerify") {
      if (suggestedAddresses?.Output?.length > 0) {
        for (let i = 0; i < suggestedAddresses?.Output?.length; i++) {
          address = suggestedAddresses?.Output[i];
          country = address.CountryName
            ? AddressBlock.capitalizeFirst(address.CountryName.toLowerCase())
            : "";
          if (
            address.Status !== "F" &&
            originalAddress !== address.FormattedAddress
          ) {
            optionalValues = {
              addr: `${address.FormattedAddress} ${country}`,
              country: country,
              source: origin
            };
            const isDuplicated = AddressBlock.isDuplicate(
              address.FormattedAddress
            );
            if (!isDuplicated) {
              list.push(optionalValues);
              nonUSOptionDetailList.push(address);
            }
          }
        }
      }
    } else {
      const len =
        suggestedAddresses?.Output?.length > 2
          ? 2
          : suggestedAddresses?.Output?.length;
      for (let i = 0; i < len; i++) {
        address = suggestedAddresses?.Output[i];
        country = address.Country
          ? AddressBlock.capitalizeFirst(address.Country.toLowerCase())
          : "";
        if (originalAddress !== address.FormattedAddress) {
          optionalValues = {
            addr: `${address.FormattedAddress} ${country}`,
            country: country,
            source: origin
          };
          const isDuplicated = AddressBlock.isDuplicate(
            address.FormattedAddress
          );
          if (!isDuplicated) {
            list.push(optionalValues);
            nonUSOptionDetailList.push(address);
          }
        }
      }
    }
  }

  formatAddressOptions(suggestedValues) {
    // creates the address option list for the user to choose from.
    // suggestedValues is the suggested address from address verification api.
    let addressOptions = [];
    nonUSOptionDetailList = [];
    // The first option is the user's input address.
    addressOptions.push({
      addr: DOMPurify.sanitize(this.addrField.value.trim(), {
        ALLOWED_TAGS: []
      }),
      city: DOMPurify.sanitize(this.cityField?.value.trim(), {
        ALLOWED_TAGS: []
      }),
      state: DOMPurify.sanitize(this.stateField?.value.trim(), {
        ALLOWED_TAGS: []
      }),
      zipCode: DOMPurify.sanitize(this.zipField?.value.trim(), {
        ALLOWED_TAGS: []
      }),
      country: DOMPurify.sanitize(this.countryField?.value.trim(), {
        ALLOWED_TAGS: []
      })
    });
    if (isValidateCountry) nonUSOptionDetailList.push(addressOptions[0]);
    // the second option is the suggested address from the address verification API if one is found.
    if (isUSA) {
      if (suggestedValues && suggestedValues.length > 0) {
        for (let i = 0; i < suggestedValues.length; i++) {
          const address = suggestedValues[i];
          const optionalValues = {
            addr: address["delivery_line_1"],
            city: address.components["city_name"],
            state: address.components["state_abbreviation"],
            zipCode: address.components["zipcode"],
            country: "United States"
          };

          addressOptions.push(optionalValues);
        }
      }
    } else if (isValidateCountry) {
      // Process NON USA options begin
      // add addresss suggested by address verify api
      if (suggestedValues?.Output?.length > 0)
        this.processOptions(
          this.addrField.value,
          suggestedValues,
          "addressVerify",
          addressOptions
        );

      // For non USA, we add more options from autocomplete suggestions
      if (nonUSAutoCompleteResponse?.Output?.length > 0)
        this.processOptions(
          this.addrField.value,
          nonUSAutoCompleteResponse,
          "autocomplete",
          addressOptions
        );

      // For non USA, we add more options from autocomplete suggestions using address country, not ISO3
      if (nonUSAutoCompleteResponseAddrCountry?.Output?.length > 0)
        this.processOptions(
          this.addrField.value,
          nonUSAutoCompleteResponseAddrCountry,
          "autocomplete",
          addressOptions
        );
    }
    return addressOptions;
  }

  replaceAddressFieldValues(newAddressValues, index) {
    if (this.countryWrapper && this.countryField !== newAddressValues.country) {
      this.countryField.value = newAddressValues.country;
      if (isValidateCountry)
        this.countryField.dispatchEvent(new Event("change", { bubbles: true }));
    }
    if (isUSA) {
      if (!this.isSingleAddressField) {
        this.addrField.value = newAddressValues.addr;
        this.cityField.value = newAddressValues.city;
        this.stateField.value = newAddressValues.state;
        this.zipField.value = newAddressValues.zipCode;
        this.stateField.dispatchEvent(new Event("change", { bubbles: true }));
        if (
          this.addrLine2Field &&
          newAddressValues.addr
            .toLowerCase()
            .includes(this.addrLine2Field.value.toLowerCase()) &&
          index === 1
        )
          // If Unit, Apt, or Suite is added in address line 2, Smarty will combine address line2 with the street address and returns it in delivery_line_1.
          // Therefore if the user accepts the suggested address by Smarty, and if the street address already contains address line 2 data,
          // we blank out address line 2 to prevent duplicated data.
          this.addrLine2Field.value = "";
      } else {
        this.addrField.value = this.bundleAddress([
          newAddressValues.addr,
          newAddressValues.city,
          newAddressValues.state,
          newAddressValues.zipCode
        ]);
      }
    } else if (isValidateCountry) {
      this.addrField.value = this.bundleAddress([newAddressValues.addr]);
    }
  }

  getValues = () => {
    const values = [];
    let cityLine = "";

    if (this.cityField && this.cityField.value !== "") {
      cityLine += this.cityField.value;

      if (this.stateField && this.stateField.value !== "") {
        cityLine += `, ${this.stateField.value}`;
      }
    } else if (this.stateField && this.stateField.value !== "") {
      cityLine += this.stateField.value;
    }

    if (this.zipField && this.zipField.value !== "") {
      cityLine += ` ${this.zipField.value}`;
    }

    const address =
      this.addrField && this.addrField.value !== "" ? this.addrField.value : "";
    const line2 =
      this.addrLine2Field && this.addrLine2Field.value !== ""
        ? this.addrLine2Field.value
        : "";

    if (address !== "") values.push(`${address} ${line2}`.trim());
    if (cityLine !== "") values.push(cityLine);

    if (this.countryOverride && this.countryOverride !== "") {
      values.push(this.countryOverride);
    } else if (this.countryField && this.countryField.value !== "") {
      values.push(this.countryField.value);
    }
    return values;
  };

  checkIsValid = () => {
    if (!this.addrField.required && this.addrField.value === "") {
      return true;
    } else if (isUSA || isValidateCountry) {
      return !this.awaitingAddressSelection && this.fieldIsValid
        ? true
        : new Promise((resolve, reject) =>
            this.addressValidation(resolve, reject)
          );
    } else {
      return this.internationalValidation();
    }
  };
}

export const init = () => {
  initComponent("address-block", (element) => new AddressBlock(element));
};
init();
