import styles from "./styles.css";

export function genericResult({ url, title, image, description }) {
  return `
        <a data-search-result-generic href="${url}">
            <div class="${styles.genericTitle}">${title}</div>
            <div class="${styles.genericUrl}">${url}</div>
            <div class="${styles.genericContent}">
                ${
                  image
                    ? `<div class="${styles.genericImageWrapper}">
                    <img src="${image}"/>
                </div>`
                    : ""
                }
                <div class="${
                  styles.genericDescription
                }" max-lines-wrapper style="--maxLinesMobile:6; --maxLinesDesktop:6;"><div max-lines-fader>${description}</div></div>
            </div>
        </a>
    `;
}
