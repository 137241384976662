export default class Autocomplete {
  constructor({
    inputEl,
    optionsContainer,
    returnNewOptionsArr,
    maxOptions = 10,
    onReturnKey = () => {},
    optionToString = dataFromOption => dataFromOption,
    onSelectOption = dataFromSelectedOption => dataFromSelectedOption,
    cancelBrowserAutoComplete = false
  }) {
    this.inputEl = inputEl;
    this.optionsContainer = optionsContainer;
    this.returnNewOptionsArr = returnNewOptionsArr;
    this.maxOptions = maxOptions;
    this.onReturnKey = onReturnKey;
    this.optionToString = optionToString;
    this.onSelectOption = onSelectOption;
    if (cancelBrowserAutoComplete) {
      this.inputEl.autocomplete = "false";
      this.defaultAutocomplete = "false";
    } else {
      this.defaultAutocomplete = this.inputEl.autocomplete;
    }

    this.optionsContainer.style.display = "none";

    this.optionTemplate =
      optionsContainer.querySelector("SPAN") || document.createElement("SPAN");
    this.optionNodes = this.addOptionsToDom();
    this.hasFocus = false; // should be true whenever this.inputEl or an element in this.optionNodes has focus

    inputEl.addEventListener("blur", () => this.hideOptionsIfBlurred());

    inputEl.addEventListener("focus", () => {
      if (inputEl.value.trim() && !this.hasFocus) this.getNewOptions();
      this.hasFocus = true;
    });

    inputEl.addEventListener("input", () => {
      if (inputEl.value.trim()) this.getNewOptions();
      else this.hideOptions();
    });

    inputEl.addEventListener("keyup", e => {
      const code = this.getKeyCode(e);
      if (code === "Enter" || code === 13) {
        this.hideOptions();
        this.onReturnKey();
      }
      if (code === "Escape" || code === 27) this.hideOptions();
      if (code === "ArrowDown" || code === 40) {
        e.preventDefault();
        this.showOptions();
        this.navigateOptions(1);
      }
    });

    optionsContainer.addEventListener("click", e => {
      if (e.target.tagName === this.optionTemplate.tagName) {
        this.selectOption(e.target);
      }
    });

    optionsContainer.addEventListener("keydown", e => {
      const code = this.getKeyCode(e);
      if (code === "ArrowUp" || code === 38) {
        e.preventDefault();
        this.navigateOptions(-1);
      }
      if (code === "ArrowDown" || code === 40) {
        e.preventDefault();
        this.navigateOptions(1);
      }
    });

    optionsContainer.addEventListener("keyup", e => {
      const code = this.getKeyCode(e);
      if (code === "Enter" || code === 13) this.selectOption(e.target);
      if (code === "Escape" || code === 27) {
        this.hideOptions();
        this.inputEl.focus();
      }
      if (code === "Backspace" || code === 8) {
        inputEl.focus();
        if (inputEl.value)
          inputEl.value = inputEl.value.slice(0, inputEl.value.length - 1);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getKeyCode(keyEvent) {
    return keyEvent.key || keyEvent.keyCode || keyEvent.which;
  }

  showOptions() {
    this.optionsContainer.style.display = "";
    this.inputEl.autocomplete = "off";
  }

  hideOptions() {
    this.optionsContainer.style.display = "none";
    this.inputEl.autocomplete = this.defaultAutocomplete;
  }

  addOptionsToDom() {
    const newNodes = [];
    this.optionsContainer.innerHTML = "";
    for (let i = 0; i < this.maxOptions; i++) {
      const newNode = this.optionTemplate.cloneNode(true);
      newNode.dataset.optionIndex = i;
      newNode.tabIndex = "0";
      newNode.addEventListener("blur", () => this.hideOptionsIfBlurred());
      this.optionsContainer.appendChild(newNode);
      newNodes.push(newNode);
    }
    return newNodes;
  }

  getNewOptions() {
    Promise.resolve(this.returnNewOptionsArr()).then(arr => {
      this.currentOptions = Array.isArray(arr) ? arr : [arr] || [];
      this.setOptionsInDom();
      this.showOptions();
    });
  }

  setOptionsInDom() {
    this.optionNodes.forEach((node, index) => {
      const option = this.currentOptions[index];
      if (option) {
        node.innerHTML = this.optionToString(option);
        node.style.display = "";
      } else {
        node.innerHTML = "";
        node.style.display = "none";
      }
    });
  }

  hideOptionsIfBlurred() {
    setTimeout(() => {
      const activeEl = document.activeElement;
      if (this.optionsContainer.contains(activeEl) || this.inputEl === activeEl)
        this.hasFocus = true;
      else {
        this.hasFocus = false;
        this.hideOptions();
      }
    });
  }

  navigateOptions(jump) {
    const activeEl = document.activeElement;
    const nextOption = parseInt(activeEl.dataset.optionIndex) + jump;
    if (nextOption >= 0 && nextOption < this.maxOptions)
      this.optionNodes[nextOption].focus();
    else if (nextOption < 0) this.inputEl.focus();
    else if (!nextOption) this.optionNodes[0].focus();
  }

  selectOption(element) {
    this.inputEl.value = element.innerHTML;
    this.inputEl.blur();
    this.hasFocus = false;
    this.hideOptions();
    this.onSelectOption(
      this.currentOptions[parseInt(element.dataset.optionIndex)],
      this.onReturnKey
    );
  }
}
