import {
  callbackWhenInView,
  hotloadStrings,
  initComponent
} from "../../utilities/common";
import { events } from "../media-player/lds-media-player/events";
import { remoteComponentPublish } from "../remote-trigger/browser";
import styles from "./styles.css";
export default class EmphasizedVideo {
  constructor(element) {
    this.element = element;
    this.poster = this.element.querySelector(`.${styles.poster}`);
    this.videoContent = this.element.querySelector(`.${styles.videoContent}`);
    this.viewCountElement = this.element.querySelector(`.${styles.viewCount}`);

    // Dynamically assigns all the properties from the componentData on the index side to this.whateverVariable variables for usage in the browser (rather than doing this.thing = componentData.thing over and over)
    Object.assign(this, JSON.parse(this.videoContent?.dataset?.componentData));
    this.isMuted =
      this.autoplay === true && this.startMuted === false
        ? false
        : this.autoplay || this.startMuted;
    this.firstAutoplay = this.autoplay;
    this.isBrightcoveVideo = !this.youTubeId;

    this.playButton = this.getButton("play");
    this.pauseButton = this.getButton("pause");
    this.volumeButton = this.getButton("volume");
    this.muteButton = this.getButton("volume-mute");

    this.video = this.element.querySelector(
      "[data-type='video']:not([looping]) [data-player]"
    );
    this.posterVideo = this.poster?.querySelector("[data-player]");

    // Loads the video and shows it if there is no poster
    if (!this.poster && this.video) {
      callbackWhenInView({
        elems: [this.videoContent],
        callback: () => this.video.videoLoad()
      });
    }

    if (this.videoContent) {
      const route = this.isBrightcoveVideo ? "brightcove" : "youTube";
      const id = encodeURIComponent(
        this.isBrightcoveVideo ? this.brightcoveId : this.youTubeId
      );
      const accountId = this.isBrightcoveVideo
        ? `&accountId=${encodeURIComponent(this.brightcoveAccountId)}`
        : "";
      if (this.viewCountElement) this.setViewCount(route, id, accountId);

      this.video.addEventListener(events.LOAD, () => {
        if (this.poster) this.poster.remove();
        this.video.videoToggleMute(this.autoplay && this.startMuted);
        let iframe = this.video?.querySelector("iframe")?.contentWindow;

        // Checks for audio change (for mute toggle) for the brightcove and youtube videos respectively
        this.isBrightcoveVideo
          ? this.brightCoveLogic()
          : this.youTubeLogic(iframe);
      });

      // Restarts looping poster if it passes the endTime
      if (this.posterVideo && this.autoplay) {
        this.posterVideo.addEventListener(events.TIMEUPDATE, (event) => {
          if (this.endTime > 0 && event.detail.position > this.endTime)
            this.posterVideo.videoRestart(this.startTime);
        });
      }
    }

    toggleButtons([this.playButton, this.muteButton], true);

    if (this.autoplay) {
      this.posterVideo.addEventListener(events.LOAD, () => {
        // Autoplay/pause video when in/out of view
        callbackWhenInView({
          elems: [this.videoContent],
          callback: () => {
            this.currentVideo(this.firstAutoplay)?.videoRestart(
              this.firstAutoplay ? this.startTime : 0
            );
          },
          callbackFalsy: () => {
            this.currentVideo(this.firstAutoplay)?.videoPause();
          },
          onceOnly: false
        });
      });
      // This handles the logic for restarting the video on autoplay
      if (this.firstAutoplay) {
        this.playButton.addEventListener(
          "click",
          (event) => {
            event.stopImmediatePropagation();

            // set both startMuted and isMuted
            this.startMuted = this.isMuted = !this.loopFullVideo
              ? this.isMuted
              : false;
            this.video.videoRestart();
            this.videoContent.classList.remove(styles.autoplay);

            // this variable is used as an indicator that this function has triggered already and doesn't need to trigger again
            this.firstAutoplay = false;

            // Brightcove doesn't trigger mute events while video is already playing so we need to manually update the icon...
            if (this.isBrightcoveVideo) {
              toggleButtons([this.getVolumeButton(this.startMuted)], true);
            }
          },
          { once: true }
        );
      }

      // We need to set the remoteTriggerId event listener AFTER the previous event or else they get in the way of each other
      [this.playButton, this.pauseButton].forEach((button) => {
        button.addEventListener("click", (event) => {
          remoteComponentPublish(this.remoteTriggerId, event);
        });
      });
    }

    // When video plays, hide all icons if it's not a horizontal variant on desktop
    this.video.addEventListener("play", () => {
      const keepButtons = this.isHorizontalDesktop();
      toggleButtons(
        keepButtons
          ? [this.pauseButton]
          : [
              this.playButton,
              this.volumeButton,
              this.volumeButton,
              this.muteButton
            ],
        keepButtons
      );
    });

    // Handle icon toggling when video is played or paused
    this.video.addEventListener("pause", () => {
      toggleButtons([this.playButton, this.getVolumeButton()], true);
    });

    [this.muteButton, this.volumeButton].forEach((button) => {
      if (!button) return;
      button.addEventListener("click", () => {
        // Only trigger the videoToggleMute if the video is loaded
        this.isMuted = !this.isMuted;
        toggleButtons([this.getVolumeButton()], true);
        if (
          this.video?.children?.length ||
          this.posterVideo?.children?.length
        ) {
          // if it's looping but it's not the full video, do not toggle the mute
          if (this.firstAutoplay && !this.loopFullVideo) return;
          this.currentVideo(
            this.autoplay && this.firstAutoplay && this.posterVideo
          ).videoToggleMute();
        }
      });
    });
  }

  getButton = (icon) =>
    [...this.element.querySelectorAll("button")].find(
      (button) => button.querySelector("span").dataset.svgTitle === icon
    );
  getVolumeButton = (isMuted = this.isMuted) => {
    return isMuted ? this.muteButton : this.volumeButton;
  };
  currentVideo = (condition) => (condition ? this.posterVideo : this.video);
  isVideoPlaying = () => this.video.hasAttribute("playing");
  isHorizontalDesktop = () => window.innerWidth > 600 && this.isHorizontal;

  setViewCount = (route, id, accountId) => {
    const url = `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/${route}?id=${id}${accountId}`;

    const req = new XMLHttpRequest();
    req.open("GET", url);
    req.addEventListener("load", () => {
      const viewCount = Number(req.response);
      this.viewCountElement.innerHTML =
        viewCount > 0 && this.viewsString
          ? `${hotloadStrings(this.viewsString, {
              "view-count": viewCount.toLocaleString()
            })}`
          : "";
    });

    req.send();
  };

  youTubeLogic = (iframe) =>
    window.addEventListener("message", (event) => {
      const messageHostname = event.origin;
      if(messageHostname !== window.location.origin) {
        return;
      }
      if (event.source === iframe) {
        const videoData = JSON.parse(event.data)?.info;
        const isMuted = videoData?.muted;
        // Checks if video is muted or not and sets our mute buttons accordingly
        if (
          isMuted !== undefined &&
          this.volumeButton &&
          !this.isVideoPlaying()
        ) {
          this.isMuted = isMuted;
          toggleButtons([this.getVolumeButton(isMuted)], true);
        }
      }
    });

  brightCoveLogic = () =>
    // Listens for mute/unmute to toggle our icon
    this.video.addEventListener(events.VOLUMECHANGE, (event) => {
      const isMuted = event.target
        .closest("[data-player]")
        .hasAttribute("muted");
      this.isMuted = isMuted;
      if (
        (!this.isVideoPlaying() || this.firstAutoplay) &&
        this.isHorizontalDesktop()
      ) {
        toggleButtons([this.getVolumeButton()], true);
      }
    });
}

const toggleButtons = (buttons, showButton) =>
  buttons.forEach((button) => {
    if (!button) return;
    button.hidden = !showButton;
    const otherButton =
      button.previousElementSibling || button.nextElementSibling;
    // If we're showing a button, hide it's sibling button so only one is showing at a time
    if (otherButton && showButton) {
      otherButton.hidden = true;
    }
  });

export const init = () => {
  initComponent("emphasized-video", (element) => new EmphasizedVideo(element));
};
init();
