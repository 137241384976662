/*
The 'ddo' module provides some methods for updating the DDO object.
*/

export default (transactionId) => {
  // When a referral or chat form is submitted, the transaction ID which it creates should also be sent to the DDO for analytics tools to pick it up.
  
    if (window.DDO) {
      // The following two lines will be removed once DTM and GTM start pulling from the `DDO.referralServiceData.transactionId` populated below.
      DDO.eventData || (DDO.eventData = {});
      DDO.eventData.meta = transactionId;

      DDO.referralServiceData || (DDO.referralServiceData = {});
      DDO.referralServiceData.transactionId = transactionId;
    }
}
