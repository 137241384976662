import { inIframe } from "./common";
const events = window.digitalDataEventsCUC?.component;

// Dynamically grabs the contentTitle, interactionText, and componentType
// `contentTitle` - Nearest heading
// `interactionText` - the text the user actually clicked on (or the nearest we could find)
// `componentType` - returns a breadcrumb trail of the most parent component down to the component the user clicked on
export function recordInteraction(component, interactionText, interaction) {
  const { componentName, breadcrumb } = getComponentDetails(component);

  // User can specify the interaction type (i.e. content like, download, etc.) or it will default to a "Component Click"
  let event = events?.[interaction] || events?.click || "Component Click";

  setTimeout(
    () => {
      postAnalytics({
        event,
        component: {
          info: {
            contentTitle: getHeading(component),
            interactionText:
              interactionText?.trim() || findInteractionText(component),
            link: component?.closest("a")?.href || "No Link",
            offerId: component?.closest("[offerId]")?.getAttribute("offerid")
          },
          category: {
            primary: breadcrumb
          }
        }
      });
    },
    componentName !== "check-box" ? 0 : 500 // Add a delay if the component is a check-box so that the formStart will trigger first
  );
}

export function postAnalytics(data) {
  inIframe()
    ? window.top.postMessage(
        { filter: "ComeUntoChrist", ...data },
        document.referrer
      )
    : window.digitalData.push(data);
}

// The function that dynamically grabs the nearest content heading on the page
// Starts where the user clicked and goes all the way to the "top" component and then goes back (up on the page) 3 components before stopping
export function getHeading(element, componentsChecked = 0) {
  if (!element) {
    return "No Heading Found";
  }
  const { componentName, parentComponentName } = getComponentDetails(element);

  // If the parent is a footer or header we'll use the component's name if there is no analytics title
  const badParentTitle =
    parentComponentName === "footer" || parentComponentName === "header"
      ? componentName
      : null;

  // Grab the Content Title and if there isn't one use some other values
  const analyticsTitleElement = element?.querySelector("[analytics-title]");
  const contentTitle =
    analyticsTitleElement?.getAttribute("analytics-title") ||
    analyticsTitleElement?.innerText ||
    element?.getAttribute("analytics-title") ||
    badParentTitle;

  // Backup title in case nothing else is found
  const backupTitle = (
    parentComponentName ||
    componentName ||
    "No Heading Found"
  ).trim();

  // Checking if heading exists and heading.offsetParent checks if the element or any of it's parents are display: none
  const titleExists =
    contentTitle && analyticsTitleElement?.offsetParent !== null;
  const parent = element?.parentElement;
  const reachedTheTop =
    parent?.nodeName === "MAIN" ||
    parent?.nodeName === "HTML" ||
    parent?.parentElement?.id === "root";
  const previous = element?.previousElementSibling;

  return titleExists
    ? // Use the title
      contentTitle.trim()
    : reachedTheTop && previous && componentsChecked < 3
    ? // go backwards,
      getHeading(previous, ++componentsChecked)
    : !reachedTheTop && element
    ? // go upwards,
      getHeading(parent)
    : // or just use the backup title if nothing else works
      backupTitle;
}

// Function to record analytics for components with steps (i.e. Enhanced UI Slider, Card Stack, etc.)
export function stepChangeAnalytics(
  element,
  stepElement,
  currentIndex,
  totalSteps,
  componentType
) {
  postAnalytics({
    event: events.stepChange || "Step Change",
    component: {
      info: {
        title: getHeading(element),
        stepTitle: getHeading(stepElement),
        stepNumber: currentIndex + 1,
        totalSteps
      },
      category: {
        primary: componentType
      }
    }
  });
}

// Grabs the component name and breadcrumb trail of the component
function getComponentDetails(element) {
  const componentTrail = getComponentTrail(element);
  return {
    componentName: componentTrail[0],
    parentComponentName: componentTrail[componentTrail.length - 1],
    breadcrumb: componentTrail.slice().reverse().join(" > ")
  };
}

// Dynamically grabs the element the user clicked on and creates a breadcrumb trail from that element to the "top" most component
function getComponentTrail(element, previousElements = []) {
  if (!element) return [];
  const componentType = element?.dataset?.type;
  const parent = element?.parentElement;
  if (componentType && !element.hasAttribute("skip-analytics")) {
    previousElements.push(componentType);
  }
  return parent?.nodeName !== "MAIN" && parent?.nodeName !== "HTML"
    ? getComponentTrail(parent, previousElements)
    : previousElements;
}

// Dynamically grabs the nearest text the user "clicked" on (i.e. the "Click Here" on a button)
function findInteractionText(element) {
  let interactionText;
  const { componentName } = getComponentDetails(element);
  const parent = element?.parentElement;
  // This Attribute will tell the logic to combine the text from the siblings
  const interactionTextParent = parent?.closest(
    "[analytics-interaction-text-parent]"
  );
  const formattedTags = [
    "B",
    "STRONG",
    "I",
    "EM",
    "MARK",
    "SMALL",
    "DEL",
    "INS",
    "SUB",
    "SUP"
  ];

  interactionText = element?.children
    ? [...element.children]
        .filter(
          (c) =>
            // Check if there is text, and that it's display doesn't equal "none"
            c.innerText && // Check that there is text
            !formattedTags.includes(c.tagName) && // i.e. checking that the text isn't nested in a `strong` tag or any of the tags listed above so that the function will go up a level to get ALL the text
            c.innerText.trim() !== "" && // Check that the text isn't empty even after it gets trimmed
            window.getComputedStyle(c, null).display !== "none" // Check that the text isn't display: none
        )
        .map((c) => c.innerText.trim())
        .join("_")
        .replace(/(\r\n|\n|\r)/, "_")
    : "";

  // If it's empty recursion
  // If there's text return it
  // If it's empty and we've hit main/html/footer return default value
  // If the parent doesn't have the `analytics-interaction-text-parent` attribute
  return (interactionText !== "" ||
    parent.classList.contains("footer_pageFooter") ||
    parent.classList.contains("persistent-cta_extra") ||
    parent.nodeName === "MAIN" ||
    parent.nodeName === "HTML") &&
    !interactionTextParent
    ? interactionText === ""
      ? componentName
      : interactionText?.trim()
    : parent
    ? findInteractionText(parent)
    : null;
}
