import { unveil } from "../unveil/browser";
import { callbackWhenInView, initComponent } from "../../utilities/common";
import {
  remoteComponentPublish,
  remoteComponentSubscribe
} from "../remote-trigger/browser";
import Flickity from "../flickity/flickity.pkgd.js";
import { flickityConfig } from "./config.js";

export default class FlipCard {
  constructor(element) {
    this.element = element;
    this.cardStacks = element.querySelectorAll(`[data-flip-card-stack]`);
    this.numOfCardStacks = this.cardStacks.length;
    this.allCards = element.querySelectorAll("[data-flip-card]");
    this.subscriptionId = element.dataset.subscriptionRemoteTriggerId;
    this.publishId = element.dataset.publishRemoteTriggerId;

    const swipeElem = element.querySelector("[data-swipe-wrapper]");
    this.isMobile = function () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    };

    if (element.hasAttribute("data-shuffle")) {
      callbackWhenInView({
        elems: [this.element],
        callback: () => {
          this.shuffleStacks();
        }
      });
    }

    if (this.subscriptionId)
      remoteComponentSubscribe(this.subscriptionId, () => this.shuffleStacks());

    // make the first card in each stack active/visible
    this.cardStacks.forEach((stack) => {
      stack.firstElementChild.toggleAttribute("active");
    });

    this.cardStacks.forEach((cardStack) => {
      // Listens for clicks, "Enter" and "Space" key inputs
      cardStack.addEventListener("click", () => this.flipCard(cardStack));
      cardStack.addEventListener("keyup", (e) => {
        if (e.key && (e.key === "Enter" || e.key === " "))
          this.flipCard(cardStack);
      });
    });

    // swipe max-width: 1024px for easy JS enbable not desktop
    // optimize opp: add handler for onchange
    const mediaQuery = window.matchMedia("(max-width: 1024px)").matches;
    const useFlickity = this.isMobile() && mediaQuery && swipeElem;
    const flkty = useFlickity && new Flickity(swipeElem, flickityConfig);
    const arrows = useFlickity && swipeElem?.querySelectorAll("svg");
    this.isMobile() &&
      useFlickity &&
      arrows?.forEach((arrow) => {
        arrow.setAttribute("viewBox", "0 0 24 24");
      });

    if (flkty) {
      //  toggle arrows at start and end
      const anchorPrev = swipeElem?.querySelectorAll(".flickity_previous");
      const anchorNext = swipeElem?.querySelectorAll(".flickity_next");
      useFlickity
        ? (anchorPrev[0].style.display = "none")
        : (anchorPrev[0].style.display = "block");

      useFlickity &&
        flkty.on("cellSelect", function () {
          var target = flkty.selectedCell.target;
          if (target === flkty.cells[0].target) {
            anchorPrev[0].style.display = "none";
            anchorNext ? null : (anchorNext[0].style.display = "block");
          } else if (target === flkty.getLastCell().target) {
            anchorNext[0].style.display = "none";
            anchorPrev[0].style.display = "block";
          } else {
            anchorPrev[0].style.display = "block";
            anchorNext[0].style.display = "block";
          }
        });
    }
  }
  /* eslint-disable-next-line class-methods-use-this */
  expandCard(cardStack) {
    // active attr is front or top card when you flip the cards swtich order on the DOM
    for (const child of cardStack.children) {
      if (
        !child.getAttribute("active") &&
        child.classList.value.includes("testimonial-back")
      ) {
        cardStack.setAttribute("style", "height: fit-content !important");
      } else {
        cardStack.removeAttribute("style", "height: fit-content !important");
        // cardStack.classList.remove('testimonialExpand'); use class instead?
      }
    }
  }
  // Passing a "manual" value if the user is manually triggering the shuffle, otherwise we don't want to focus the cards for keyboard users
  flipCard(cardStack, manual = true) {
    this.animateFlip(cardStack, manual);
    this.unveilImgsInNextCard(cardStack);
    this.expandCard(cardStack);
    if (this.publishId && manual) remoteComponentPublish(this.publishId);
  }

  /* eslint-disable-next-line class-methods-use-this */
  animateFlip(cardStack, manual) {
    cardStack.setAttribute("animate", "");
    const curElem = cardStack.firstElementChild;

    cardStack.addEventListener(
      "animationend",
      () => {
        cardStack.append(curElem);
        cardStack.removeAttribute("animate");
        if (manual) cardStack.firstElementChild.focus(); // Only happens if the user is manually triggering the card flip
      },
      { once: true }
    );
  }

  shuffleStacks() {
    this.cardStacks.forEach((stack) => {
      shuffleArr([...stack.children]).forEach((card) =>
        // Reminder that appendChild actually *moves* the element to the end of the array, it doesn't add a new one
        stack.appendChild(card)
      );
      this.flipCard(stack, false);
    });
  }

  /* eslint-disable-next-line class-methods-use-this */
  unveilImgsInNextCard(cardStack) {
    const nextCard = cardStack.firstElementChild.nextElementSibling;
    if (nextCard) {
      // need to show then hide the nextCard so unveil will see images as "visible" to allow them to pre-load. Needs to be hidden after to prevent keyboard users from tabbing to things they can't see
      nextCard.style.display = "block";
      nextCard.querySelectorAll("img").forEach((img) => unveil(img));
      nextCard.style.display = "";
    }
  }
}

const shuffleArr = (array) => {
  // Shuffles the array but preserves the first item so that the "new" array starts on the same card as before to make the shuffle look smoother
  const firstItem = array.shift();
  const shuffledArray = array.toSorted(() => 0.5 - Math.random()); // Keep in mind the .sort function changes the original array
  shuffledArray.unshift(firstItem);
  return shuffledArray;
};

export const init = () => {
  initComponent("flip-card", (element) => new FlipCard(element));
};
init();
