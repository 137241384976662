import { callbackWhenInView } from "../../utilities/common";

export default class CounterGroup {
  constructor(counters, interval) {
    this.counters = counters;
    this.counters.forEach((c) => (c.simpleCounter.cachingInterval = interval));
    this.interval = interval;
    this.visibleCounters = [];
    this.isThrottled = false;
    callbackWhenInView({
      elems: this.counters.map((c) => c.element),
      callback: (p) => this.onCounterInView(p),
      callbackFalsy: (p) => this.onCounterOutOfView(p),
      onceOnly: false
    });
  }

  update(remainingUpdates = []) {
    const counter = remainingUpdates.shift();
    if (counter) {
      counter.simpleCounter
        .readSocialCounter()
        .then(() => this.update(remainingUpdates));
    } else this.restartTimer();
  }

  readUpdates() {
    const pendingUpdates = [...this.visibleCounters];
    this.update(pendingUpdates);
  }

  restartTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.readUpdates(), this.dynamicInterval);
  }

  watch() {
    this.restartTimer();
  }

  unwatch() {
    clearTimeout(this.timer);
  }

  onCounterInView(element) {
    const counter = this.counters.find((c) => c.element.isEqualNode(element));
    if (counter) {
      if (counter.simpleCounter.currentCounterValue === null) {
        // if there's no number yet, read the social counter
        counter.simpleCounter.readSocialCounter();
      }
      this.visibleCounters.push(counter);
    }
  }

  onCounterOutOfView(element) {
    const index = this.counters.findIndex((c) =>
      c.element.isEqualNode(element)
    );
    if (index >= 0) this.visibleCounters.splice(index, 1);
  }

  get dynamicInterval() {
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    const effectiveType = connection?.effectiveType;
    // throttles interval under these conditions
    if (
      this.visibleCounters.length > 10 ||
      (effectiveType && !["3g", "4g"].includes(effectiveType))
    ) {
      this.isThrottled = true;
      return 60000;
    } else {
      this.isThrottled = false;
      return this.interval;
    }
  }
}
