import { callbackWhenInView, initComponent } from "../../utilities/common";

export default class SVG {
  constructor(element) {
    const name = element.dataset.name;
    const url = `${window.PUBLIC_ENV.STATIC_DIRECTORY}/svgs/${name}.svg`;
    const titleText = element.dataset.svgTitle;

    if (name) SVG.renderSVG(url, element, titleText);
  }

  static renderSVG(url, element, titleText) {
    const addTitle = () => {
      if (titleText) {
        const svg = element.getElementsByTagName("svg")[0];
        const titleElem = svg.getElementsByTagName("title")[0];
        const guid = Math.floor(Math.random() * 1000);
        if (!titleElem) {
          svg.insertAdjacentHTML(
            "afterBegin",
            `<title id="${guid}">${titleText}</title>`
          );
        } else {
          titleElem.textContent = titleText;
          titleElem.id = guid;
        }
        // add aria to point to title
        svg.setAttribute("aria-labelledby", guid);
      }
    };
    // if it already contains an SVG and it isn't in sessionStorage, put it there so we don't make a request for it later
    if (element.querySelector("svg") && !sessionStorage.getItem(url)) {
      sessionStorage.setItem(url, element.innerHTML);
      addTitle();
    } else if (sessionStorage.getItem(url)) {
      element.innerHTML = sessionStorage.getItem(url);
      // add a title if needed
      addTitle();
    } else {
      const getSVG = new XMLHttpRequest();
      getSVG.addEventListener("load", () => {
        if (getSVG.status === 200) {
          // inject the SVG
          element.innerHTML = getSVG.responseText;

          // add a title if needed
          addTitle();
          sessionStorage.setItem(url, getSVG.responseText);
        }
      });

      getSVG.open("GET", url);
      getSVG.send();
    }
  }
}

export const init = () => {
  initComponent("svg", (svg) => {
    callbackWhenInView({
      elems: [svg],
      callback: (svg) => {
        new SVG(svg);
      }
    });
  });
};
init();
