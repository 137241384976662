import {
  aggregateCalls,
  createTransactionID,
  getParam
} from "../../utilities/common";
import Autocomplete from "../autocomplete/browser";
import GeolocateBtn from "../geolocate-btn/browser";
import customEvent from "../custom-event/browser";
import { scrollIntoViewIfNeeded } from "../scroll-logic/browser";
import missionaryPhoneNum from "../missionary-phone-numbers/browser";
import { getHeading, postAnalytics } from "../../utilities/analytics";
import formstyles from "../form-components/form-styles/styles.css";
import Map from "@churchofjesuschrist/maps-google-map";
import MapsService, {
  MapProviderType,
  FieldType,
  OperatorType
} from "@churchofjesuschrist/maps-service";
import { LocationType } from "@churchofjesuschrist/maps-google-clusters";
import Marker, { IconType } from "@churchofjesuschrist/maps-google-marker";
/* google object will come up as undefined by eslint */
/* eslint-disable no-undef */
const meetinghouseLimit = 10;
let currentSearchCenterCoordinates;
let repositionCoordinates;
let searchFieldCoordinates;
let markersArray = [];
let userMarkersArray = [];
const analyticsEvents = window.digitalDataEventsCUC.component;

function smartyStreet(string) {
  return new Promise((resolve) => {
    const req = new XMLHttpRequest();
    req.addEventListener("load", () => {
      if (req.status === 200) {
        const options = req.response;
        resolve(options);
      } else {
        resolve([]);
      }
    });
    req.open(
      "GET",
      `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/autocomplete?address=` +
        encodeURIComponent(string)
    );
    req.responseType = "json";
    req.send();
  });
}

export default class ChurchProductMap {
  constructor(element, spinner) {
    this.element = element;
    this.spinner = spinner;
    this.variant = element.dataset.variant;
    this.givingMachinesInfo = element.querySelector(
      '[data-type="giving-machines-info"]'
    );
    this.gmData = this.givingMachinesInfo
      ? JSON.parse(
          this.givingMachinesInfo
            ?.getAttribute("data-giving-machines")
            .replaceAll("^", "/")
        )
      : "";
    this.gmMapCssInfo = element.querySelector(
      '[data-type="giving-machines-map-css-info"]'
    );
    this.gmMapCss = this.gmMapCssInfo
      ? JSON.parse(this.gmMapCssInfo?.getAttribute("data-gm-map-css"))
      : "";
    this.searchField = element.querySelector(
      '[data-type="church-unit-search-field"]'
    );
    this.searchBtn = element.querySelector("[map-search-btn]");
    this.searchThisArea = element.querySelector("[map-search-this-area]");
    this.mapErrors = element.querySelector(
      '[data-type="church-unit-map-errors"]'
    );
    this.errorDisplay = element.querySelector(
      "[data-church-unit-map-error-display]"
    );
    this.locale = element.dataset.unitMapLocale;
    this.countryCode = element.dataset.countryCode;
    this.searchRadius = parseInt(element.dataset.searchRadius) * 1609; // miles to meters
    this.minZoomLevel = Number(element.dataset.minZoomLevel);
    this.maxZoomLevel = Number(element.dataset.maxZoomLevel);
    this.scope = {
      layer: LocationType.MEETINGHOUSE,
      onMap: true,
      markers: [],
      userMarker: null
    };
    this.searchResults = [];
    this.formStarted = false;
    const name = getHeading(this.element);
    this.analyticsData = {
      info: {
        name,
        id: undefined,
        formType: "location",
        transactionID: createTransactionID()
      },
      category: {
        primary: "form"
      }
    };
    this.interactionCount = 0;
    this.analyticsMapData = (data) => {
      return {
        info: { name, ...data },
        category: { primary: "map" }
      };
    };

    /* console log results in lower lanes */
    if (element.hasAttribute("dev-env")) {
      this.inDevEnv = true;
      this.element.addEventListener("new-search-results", (e) => {
        // eslint-disable-next-line no-console
        console.log({ mapResults: e.detail });
      });
    }

    if (element.hasAttribute("auto-geolocate")) this.autoGeolocate = true;
    this.resetFilter();
    this.loadMap();
    if (this.variant === "givingMachines") this.loadGivingMachines();

    if (this.searchField) {
      this.searchField.addEventListener("keydown", (e) => {
        let code = e.key || e.keyCode || e.which;
        if (!this.formStarted) {
          this.formStarted = true;
          // Sends form start data to the Data Layer for Adobe Launch
          postAnalytics({
            event: analyticsEvents.formStart,
            component: this.analyticsData
          });
        }
        if (code === "Enter" || code === 13) {
          this.executeSearch();
        }
      });
      this.searchField.addEventListener("input", () => {
        this.searchFieldRawValue = null;
        this.searchField.classList.remove(formstyles.invalid);
      });
    }

    this.searchBtn?.addEventListener("click", () => {
      this.executeSearch();
    });
    this.searchThisArea?.addEventListener("click", () => {
      repositionCoordinates = this.map.getCenter();
      this.setMapCenter(repositionCoordinates);
      this.searchThisAreaMeetinghouses(repositionCoordinates);
    });
    if (this.countryCode.toLowerCase() === "us" && this.searchField) {
      new Autocomplete({
        inputEl: this.searchField,
        optionsContainer: element.querySelector('[data-type="autocomplete"]'),
        returnNewOptionsArr: () => smartyStreet(this.searchField.value),
        optionToString: (option) => option.text,
        onReturnKey: () => this.executeSearch(),
        onSelectOption: () => this.executeSearch()
      });
    }

    this.geolocateBtn = new GeolocateBtn({
      element: element.querySelector("[data-map-geolocate-btn]"),
      onPositioned: (position) => {
        this.displayErrorToUser(null);
        const currentLocationLabel = this.getCurrentLocationLabel(position);
        const positionCoordinates = `${position.coords.latitude}, ${position.coords.longitude}`;
        if (currentLocationLabel) {
          this.searchFieldRawValue = positionCoordinates;
          this.searchField.value = currentLocationLabel;
          this.executeSearch({
            updateURL: false,
            withCurrentLocationLabel: true
          });
        } else {
          this.searchField.value = positionCoordinates;
          this.executeSearch({ updateURL: false });
        }
      },
      handleNoBrowserSupport: () => this.createBrowserAlert("browserLocation"),
      handleUserBlockedGeo: () => this.createBrowserAlert("userLocation"),
      spinner: this.spinner
    });
  }

  fireEvent(eventString, data = null) {
    customEvent(this.element, eventString, {
      bubbles: true,
      detail: { data }
    });
  }

  loadMap() {
    const mapDiv = this.element.querySelector(
      '[data-type="church-unit-map-canvas"]'
    );

    try {
      const isProd = this.element.hasAttribute("dev-env") ? false : true;

      const loadMapsOptions = {
        ip: true,
        language: this.locale,
        libraries: ["geometry"]
      };

      const defaultMapOptions = {
        mapTypeControl: false,
        zoomControl: true,
        geolocateControl: false,
        legalControl: false
      };

      const gmMapOptions = {
        mapTypeControl: false,
        zoomControl: true,
        geolocateControl: false,
        legalControl: false,
        minZoom: this.minZoomLevel,
        maxZoom: this.maxZoomLevel,
        styles: this.gmMapCss
      };

      const mapOptions =
        this.variant === "default" ? defaultMapOptions : gmMapOptions;

      MapsService.configure({
        client: "MormonOrg",
        language: this.locale,
        production: isProd
      });
      MapsService.loadMaps(MapProviderType.GOOGLE, loadMapsOptions).then(() => {
        this.map = new Map(mapDiv, mapOptions);
        this.isGoogleAvailable = true;
      });
      this.scope.browserGeolocationError =
        this.searchField?.dataset.browserLocationError;
      this.scope.userGeolocationError =
        this.searchField?.dataset.userLocationError;
    } catch (googleMapsErr) {
      this.isGoogleAvailable = false;
      this.displayErrorToUser("googleUnavailable");
      if (this.inDevEnv) {
        // eslint-disable-next-line no-console
        console.error(googleMapsErr);
      }
    }
  }

  updateURL(options = {}) {
    const { withCurrentLocationLabel } = options;
    const searchFieldValue = withCurrentLocationLabel
      ? this.searchFieldRawValue
      : this.searchField.value;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("location", searchFieldValue);
    window.history.pushState(
      { location: searchFieldValue },
      `Find Congregation near ${searchFieldValue}`,
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  loadGivingMachines() {
    let query = `${this.gmData[0].latitude}, ${this.gmData[0].longitude}`;
    return new Promise(() => {
      MapsService.geocodeAddress(query)
        .then(() => {
          const defaultStyle = {
            type: IconType.PIN,
            layer: "GivingMachines",
            symbol: "LightTheWorld",
            color: "#FFFFFF",
            foreground: "#D50032"
          };
          const selectedStyle = {
            type: IconType.PIN,
            layer: "GivingMachines",
            symbol: "LightTheWorld",
            color: "#D50032",
            foreground: "#FFFFFF"
          };

          /* the icon styles are changed when clicking on an icon */
          this.defaultIcon = defaultStyle;
          this.selectedIcon = selectedStyle;

          for (const gmData of this.gmData) {
            let myLatlng = new google.maps.LatLng(
              gmData.latitude,
              gmData.longitude
            );
            const marker = new Marker({
              icon: defaultStyle,
              position: myLatlng,
              map: this.map,
              ...gmData
            });
            marker.addListener("click", () => {
              this.selectMarker(marker);
              this.fireEvent("clicked-map-marker", gmData.id);
              // Analytics. Once this gets add to both the default AND givingMachine variants you can just move this analytics into the `selectMarker` function
              postAnalytics({
                event: analyticsEvents.click,
                component: this.analyticsMapData({ location: gmData.name })
              });
            });
            markersArray.push(marker);
          }
          this.zoomOnMarkers();

          this.element.addEventListener("clicked-map-back-button", () => {
            this.unselectMarkers();
          });

          this.map.addListener("click", () => {
            this.unselectMarkers();
            this.fireEvent("clicked-map-marker", false); // If there's no information it'll just toggle it closed
          });

          // Analytics
          google.maps.event.addListener(this.map, "dragend", () => {
            this.interactionCount++;
            this.timeoutId = aggregateCalls(
              1000,
              () => {
                postAnalytics({
                  event: analyticsEvents.mapDragEnd,
                  component: this.analyticsMapData({
                    interactionCount: this.interactionCount
                  })
                });
                this.interactionCount = 0;
              },
              this.timeoutId
            );
          });

          google.maps.event.addListener(this.map, "zoom_changed", () => {
            this.interactionCount++;
            this.timeoutId = aggregateCalls(
              1000,
              () => {
                postAnalytics({
                  event: analyticsEvents.mapZoomChanged,
                  component: this.analyticsMapData({
                    interactionCount: this.interactionCount
                  })
                });
                this.interactionCount = 0;
              },
              this.timeoutId
            );
          });
        })
        .then(() => {
          this.fireEvent("new-search-results", this.gmData);
        });
    });
  }

  /* rewrites the data in this.searchResults and then returns the results in a promise */
  executeSearch(options = {}) {
    postAnalytics({
      event: analyticsEvents.formSubmit,
      component: this.analyticsData
    });
    let updateURL = options.updateURL !== undefined ? options.updateURL : true;
    let withCurrentLocationLabel = options.withCurrentLocationLabel;
    this.searchField.classList.remove(formstyles.invalid);
    if (this.searchFieldRawValue && !options.repositionPin) {
      updateURL = false;
      withCurrentLocationLabel = true;
    }
    if (updateURL && withCurrentLocationLabel) {
      this.updateURL({ withCurrentLocationLabel: true });
    } else if (updateURL) {
      this.updateURL();
    }
    if (!this.searchField.value.trim()) {
      this.noSearchValue();
      return Promise.resolve();
    } else {
      this.displayErrorToUser(null);
      this.searchField.blur();
    }

    this.clearOldResults(options.repositioning);
    if (!options.repositioning) {
      this.fireEvent("loading-search-results");
    }
    let isExactAddress;
    return new Promise((resolve) => {
      this.resetTimer(resolve);
      this.identifyStartingAddress({
        repositioning: options.repositioning,
        repositionPin: options.repositionPin,
        withCurrentLocationLabel
      })
        .then(({ isExactAddress, validAddress }) => {
          /* isExactAddress variable is used when calculating distances and sorting distances */
          /* eslint-disable-next-line no-self-assign */
          this.fireEvent("exact-address", isExactAddress);
          return { isExactAddress, validAddress };
        })
        .then(({ isExactAddress, validAddress }) =>
          this.identifyLocations(
            isExactAddress,
            validAddress,
            options.repositioning
          )
        )
        .then(({ locations, layersType }) => {
          if (layersType?.includes?.(LocationType.MEETINGHOUSE)) {
            /* if the layersType includes meetinghouses, do an extra search for the units */
            /* results are pushed into this.searchResults */
            return this.identifyUnitsFromMeetinghouses(locations);
          } else return locations;
        })
        .then((newResults) =>
          ChurchProductMap.getMissionaryPhoneNumbers(newResults)
        )
        .then((newResults) =>
          this.calculateDistances(newResults, isExactAddress)
        )
        .then((newResults) => {
          const filteredResults = ChurchProductMap.filterDuplicates(newResults);
          const sortedResults = isExactAddress
            ? filteredResults
            : ChurchProductMap.sortByDistance(filteredResults);
          const finalResults = this.returnNewResults(sortedResults);
          resolve(finalResults);
          this.searchThisArea.style.display = "none";
          currentSearchCenterCoordinates = this.map.getCenter();
        });
    });
  }

  identifyStartingAddress(options = {}) {
    let query = "";
    if (options.repositioning) {
      query = `${repositionCoordinates.lat()}, ${repositionCoordinates.lng()}`;
    } else {
      query =
        options.withCurrentLocationLabel && !options.repositionPin
          ? this.searchFieldRawValue
          : this.searchField.value;
    }
    return new Promise((resolve) => {
      MapsService.geocodeAddress(query).then((locations) => {
        google.maps.event.clearListeners(this.map, "dragend");
        if (!locations.length || locations.length < 1)
          resolve({ isExactAddress: false, validAddress: false });
        const result = locations[0];
        if (!options.repositioning) {
          this.setMapCenter(result.coordinates);
          searchFieldCoordinates = this.mapCenter;
          this.userMarker = new Marker({
            icon: {
              type: IconType.PIN,
              layer: LocationType.LOCALITY
            },
            position: result.coordinates,
            zIndex: 15,
            map: this.map
          });
          userMarkersArray.push(this.userMarker);
        }

        google.maps.event.addListener(this.map, "dragend", () => {
          let dragDistance;
          repositionCoordinates = this.map.getCenter();
          dragDistance = google.maps.geometry.spherical.computeDistanceBetween(
            currentSearchCenterCoordinates,
            repositionCoordinates
          );
          dragDistance >= 1000
            ? (this.searchThisArea.style.display = "block")
            : (this.searchThisArea.style.display = "none");
        });

        this.userMarker?.addListener("click", () => {
          this.selectMarker(this.userMarker);
          this.fireEvent("clicked-map-marker", null);
        });

        const value =
          result.match.accuracy === "ROOFTOP" ||
          result.match.accuracy === "INTERPOLATION" ||
          result.match.accuracy === "INTERPOLATION_OFFSET" ||
          result.match.accuracy === "APPROXIMATE" ||
          result.match.confidence === "HIGH" ||
          result.match.confidence === "MEDIUM"
            ? true
            : false;
        resolve({ isExactAddress: value, validAddress: true });
      });
    });
  }

  searchThisAreaMeetinghouses(coordinates) {
    let isExactAddress = true;
    this.clearOldResults();
    this.identifyLocations(isExactAddress, true, coordinates)
      .then(({ locations, layersType }) => {
        if (layersType?.includes?.(LocationType.MEETINGHOUSE)) {
          return this.identifyUnitsFromMeetinghouses(locations);
        } else return locations;
      })
      .then((newResults) =>
        ChurchProductMap.getMissionaryPhoneNumbers(newResults)
      )
      .then((newResults) => this.calculateDistances(newResults, isExactAddress))
      .then((newResults) => {
        const filteredResults = ChurchProductMap.filterDuplicates(newResults);
        this.returnNewResults(filteredResults);
        this.zoomOnMarkers();
        this.searchThisArea.style.display = "none";
        currentSearchCenterCoordinates = this.map.getCenter();
      });
  }

  clearOldResults(repositioning) {
    this.searchResults = [];
    markersArray.forEach((m) => {
      m.setMap(null);
    });
    markersArray = [];
    this.scope.markers = [];
    if (this.userMarker && !repositioning) {
      userMarkersArray.forEach((m) => {
        m.setMap(null);
      });
    }
  }

  setMapCenter(position) {
    this.mapCenter = position;
    this.map.setCenter(position);
  }

  identifyLocations(isExactAddress, validAddress, repositioning) {
    if (!validAddress) return { locations: [], layersType: "" };
    /*
      isExactAddress && !filters.length -> find the normal "family ward"
      isExactAddress && filters.length -> find assigned wards within the filters
      ALL OTHERS -> find close meetinghouses within filters
    */
    const filters = this.filterParams;
    const options = isExactAddress
      ? {
          layers: [LocationType.WARDS],
          filters
        }
      : {
          layers: [LocationType.MEETINGHOUSE, LocationType.WARDS],
          filters,
          nearest: meetinghouseLimit,
          radius: this.searchRadius
        };

    return new Promise((resolve) => {
      MapsService.identifyLocations(
        { coordinates: this.mapCenter },
        options
      ).then((locations) => {
        if (!locations.length)
          return resolve({
            locations,
            layersType: params.layers
          });
        /* Add an index to the locations so we can associate map markers with units */
        const indexedLocations = locations.map((l, index) => ({
          ...l,
          locationIndex: index
        }));
        this.createMarkers(indexedLocations);
        if (!repositioning) this.zoomOnMarkers();
        resolve({ locations: indexedLocations, layersType: options.layers[0] });
      });
    });
  }

  resetFilter() {
    this.filterData = { unitTypes: [], languages: [] };
  }

  get filterParams() {
    const { unitTypes, languages } = this.filterData;
    const langFilter = languages.length
      ? [
          {
            field: FieldType.LANGUAGE_CODE,
            operator: OperatorType.EQUALS,
            operand: languages
          }
        ]
      : [];

    const unitFilter = [
      {
        field: FieldType.NAME,
        operator: OperatorType.NOT_CONTAINS,
        operand: "CARE CENTER"
      },
      {
        field: FieldType.NAME,
        operator: OperatorType.NOT_CONTAINS,
        operand: "RETIREMENT"
      },
      {
        field: FieldType.NAME,
        operator: OperatorType.NOT_CONTAINS,
        operand: "CORRECTIONAL"
      },
      {
        field: FieldType.TYPE,
        operator: OperatorType.NOT_EQUALS,
        operand: "WARD.SEASONAL"
      }
    ];
    if (unitTypes.length)
      unitFilter.push({
        field: FieldType.TYPE,
        operator: OperatorType.EQUALS,
        operand: unitTypes
      });
    if (languages.length)
      unitFilter.push({
        field: FieldType.TYPE,
        operator: OperatorType.NOT_EQUALS,
        operand: "WARD.NATIVEAMERICAN"
      });
    /* filterParams is an array that can be set as search parameters for the church api */
    return [...langFilter, ...unitFilter];
  }

  createMarkers(locations) {
    const defaultStyle = {
      type: locations.length > 1 ? IconType.DOT : IconType.PIN,
      layer: [LocationType.MEETINGHOUSE],
      scale: locations.length > 1 ? 1.0 : 1.5,
      invert: locations.length > 1 ? true : false
    };
    const selectedStyle = {
      type: IconType.PIN,
      layer: [LocationType.MEETINGHOUSE],
      scale: 1.5
    };

    /* the icon styles are changed when clicking on an icon */
    this.defaultIcon = defaultStyle;
    this.selectedIcon = selectedStyle;

    this.scope.markers = locations.map((l, index) => {
      // check if the l.id already exist in markersArray
      const buildingExists = ChurchProductMap.locationExists(l.id);
      if (!buildingExists) {
        const marker = new Marker({
          icon: {
            type: IconType.DOT,
            layer: LocationType.MEETINGHOUSE
          },
          position: l.coordinates,
          map: this.map
        });
        marker?.addListener("click", () => {
          this.selectMarker(marker);
          this.fireEvent("clicked-map-marker", locations[index].locationIndex);
        });
        marker.buildingId = l.id;
        marker.address = l.address;
        marker.name = l.name;
        marker.nameDisplay = l.nameDisplay;
        markersArray.push(marker);
        return marker;
      }
    });
  }

  static locationExists(buildingId) {
    const len = markersArray.length;
    for (let i = 0; i < len; i++) {
      if (markersArray[i].buildingId === buildingId) {
        return true;
      }
    }
    return false;
  }

  selectMarker(marker) {
    markersArray.forEach((m) => {
      const markerId = this.variant === "default" ? m.buildingId : m.id;
      const selectedMarkerId =
        this.variant === "default" ? marker.buildingId : marker.id;
      if (markerId !== selectedMarkerId) {
        m.setIcon(this.defaultIcon);
      } else {
        marker.setIcon(this.selectedIcon);
        marker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(() => marker.setAnimation(null), 700);
      }
    });
  }

  selectMarkerByIndex(index) {
    const marker = markersArray[index];
    if (marker) this.selectMarker(marker);
    else this.unselectMarkers();
  }

  unselectMarkers() {
    if (this.variant === "default") {
      markersArray.forEach(() => this.selectMarker(null));
    } else {
      markersArray.forEach((m) => {
        m.setIcon(this.defaultIcon);
      });
    }
  }

  zoomOnMarkers() {
    const bounds = new google.maps.LatLngBounds();
    this.mapCenter && bounds.extend(this.mapCenter);
    markersArray.forEach((m) => {
      /* sometimes the church api doesn't provide valid coordinates */
      try {
        bounds.extend(m.getPosition());
      } catch (err) {
        null;
      }
    });

    /* zoom out a little if the area is only one point */
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.01,
        bounds.getNorthEast().lng() - 0.01
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    /* fitBounds fits all the bounds that have been extended
     adds padding to all sides and extra padding on the top */
    this.map.fitBounds(bounds, {
      top: 100,
      right: 50,
      bottom: 50,
      left: 50
    });
  }

  async identifyUnitsFromMeetinghouses(meetinghouses) {
    const waitForUnits = meetinghouses.map((m) =>
      this.filterUnitsInMeetinghouse(m)
    );
    const arrays = await Promise.all(waitForUnits);
    return arrays.flat().filter((validUnit) => validUnit);
  }

  filterUnitsInMeetinghouse(meetinghouse) {
    const { unitTypes, languages } = this.filterData;

    /* filters are arrays of strings. This checks if a filter exists then if the string is not included in the filter. */
    const checkFilter = (arr, string) => {
      if (arr.length && !arr.includes(string)) return false;
      else return true;
    };

    const checkUnitTypes = (unit) =>
      !unit.type.includes("STAKE") &&
      checkFilter(unitTypes, unit.type) &&
      checkFilter(languages, unit.language.code) &&
      !unit.name.includes("CORRECTIONAL") &&
      !unit.name.includes("RETIREMENT") &&
      !unit.name.includes("CARE CENTER") &&
      unit.type !== "WARD.SEASONAL";

    return new Promise((resolve) => {
      /* reapply filters here to only return the units that have specific attributes */
      if (checkUnitTypes(meetinghouse)) {
        resolve(meetinghouse);
      } else resolve();
    });
  }

  static getMissionaryPhoneNumbers(newResults) {
    return new Promise((resolve) => {
      const units = {};
      const ids = newResults.map((unit) => {
        // also making a copy of the units with the ids as keys to keep
        // track of which missionaryPhoneNumbers belong to each unit
        units[unit.id] = unit;
        return { unit: unit?.id, parent: unit?.parent?.id || "" };
      });
      missionaryPhoneNum(ids).then((phoneNumberObj) => {
        const updatedResults = Object.entries(phoneNumberObj).map(
          ([unitId, missionaryPhoneNumbers]) => {
            return { ...units[unitId], missionaryPhoneNumbers };
          }
        );
        resolve(updatedResults);
      });
    });
  }

  calculateDistances(newResults, isExactAddress) {
    return newResults.map((result) => {
      let calculatedDistance = undefined;
      let directionsLink = null;

      if (result.coordinates && this.mapCenter) {
        /* We're using an "as-the-crow-flies" calculation because it's much faster and cheaper than using google.maps.directions */
        calculatedDistance =
          google.maps.geometry.spherical.computeDistanceBetween(
            searchFieldCoordinates,
            result.coordinates
          );
        /* when an exact address is not provided, the centerPos is left blank which feeds into the directionsLink
          Google usually fills in the user's location when it's left blank */
        const centerPos = isExactAddress
          ? encodeURIComponent(this.searchField.value)
          : "";
        const meetinghousePos = encodeURIComponent(
          result.coordinates.toUrlValue()
        );
        directionsLink = `https://www.google.com/maps/dir/?api=1&origin=${centerPos}&destination=${meetinghousePos}`;
      }
      return { ...result, calculatedDistance, directionsLink };
    });
  }

  /* processes a search using the "location" param in the URL or geolocation */
  processInitialSearch() {
    const location = getParam("location");
    this.scrollIntoView = false;
    if (location === "GEOLOCATE" || this.autoGeolocate === true) {
      this.geolocateBtn.element.click();
    } else if (location !== "") {
      this.searchField.value = location;
      this.executeSearch({ updateURL: false });
    }
  }

  returnNewResults(newResults) {
    this.searchResults = newResults;
    this.fireEvent("new-search-results", newResults);
    if (this.scrollIntoView === false) {
      this.scrollIntoView = true;
    } else {
      scrollIntoViewIfNeeded(this.element, {
        behavior: "smooth",
        block: "center"
      });
    }
    return newResults;
  }

  static filterDuplicates(newResults) {
    /* removes any possible duplicates using the unit ids */
    const unitIds = [];
    return newResults.filter((result) => {
      const alreadyIncluded = unitIds.includes(result.id);
      unitIds.push(result.id);
      return !alreadyIncluded;
    });
  }

  static sortByDistance(newResults) {
    return newResults.sort(
      (a, b) => a.calculatedDistance - b.calculatedDistance
    );
  }

  noSearchValue() {
    this.displayErrorToUser("noValue");
    scrollIntoViewIfNeeded(this.searchField, {
      behavior: "smooth",
      block: "center"
    });
  }

  displayErrorToUser(string) {
    if (string && this.searchField?.dataset[string]) {
      this.searchField.classList.add(formstyles.invalid);
      this.errorDisplay.innerText = this.searchField?.dataset[string] || "";
    }
  }

  createBrowserAlert(string) {
    if (this.searchField?.dataset[string]) {
      this.displayErrorToUser(string);
      alert(this.searchField.dataset[string]);
    }
  }

  getCurrentLocationLabel(position) {
    if (!position) return null;
    if (
      window.innerWidth < 640 &&
      this.searchField.hasAttribute("current-location-label-shortened")
    ) {
      return this.searchField.getAttribute("current-location-label-shortened");
    } else if (
      window.innerWidth >= 640 &&
      this.searchField.hasAttribute("current-location-label")
    ) {
      return `${this.searchField.getAttribute("current-location-label")} [${
        position.coords.latitude
      }, ${position.coords.longitude}]`;
    } else {
      return null;
    }
  }

  resetTimer(/* abandonSearch */) {
    this.startTime = new Date();
    /* TODO: Make a mechanism to tell the user if a search may fail */
    // setTimeout(() => {
    //   if (this.startTime) {
    //     const userWantsToAbandon = window.confirm(
    //       "This search is taking a long time and something could have gone wrong. Would you like to quit the search?"
    //     );
    //     if (userWantsToAbandon) {
    //       this.noResults(abandonSearch);
    //     }
    //   }
    // }, 20 * 1000);
  }
}
/* eslint-enable no-undef */
