import { initComponent } from "../../utilities/common";
export default class EmphasizedMediaTile {
  constructor(element) {
    element.parentNode.setAttribute("data-tile-container", ""); //used to resize tiles based off of the parent container's width
  }
}
export const init = () => {
  initComponent(
    "emphasized-media-tile",
    (element) => new EmphasizedMediaTile(element)
  );
};
init();
