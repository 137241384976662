import styles from "./styles.css";
import { renderChecker } from "../server.js";

export default (props) => {
  return renderChecker(component, props, "invert-text-color");
};

export const component = (invertTextColor) => {
  if (invertTextColor && invertTextColor.toLowerCase?.() !== "false")
    return styles.invertTextColor;
  else return "";
};
