import { initComponent } from "../../../utilities/common";
export default class LocationField {
  constructor(element) {
    this.element = element;
    this.form = element.closest("form");
    const searchBtn = element.querySelector("[map-search-btn]");
    const compass = this.form?.querySelector("[data-location-field-compass]");

    compass?.addEventListener("click", () => {
      const guid = compass.dataset.formGuid;
      element.querySelector(`[data-location-field]`).value = "GEOLOCATE";
      document.querySelector(`[data-guid-target="${guid}"]`).submit();
    });

    searchBtn.addEventListener("click", () => {
      this.form.submit();
    });

    element.checkIsValid = () => this.checkIsValid();
  }

  checkIsValid = () => {
    const input = this.element.querySelector("input");
    if (input.required) {
      return input.value !== "" && input.value !== null;
    }
    return true;
  };
}

export const init = () => {
  initComponent("location-field", (element) => new LocationField(element));
};
init();
