import styles from "./styles.css";
import { initComponent } from "../../utilities/common";

// Adds the `currentLink` class to any links where the `[data-link]` value equals the origin + pathname of window.location.
export const init = () => {
  initComponent("data-link", (link) => {
    const URL = link.getAttribute("data-link");
    const { origin, pathname } = window.location;

    if (URL === `${origin}${pathname}`) {
      link.classList.add(styles.currentLink);
    }
  });
};
init();
