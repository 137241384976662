/*
LDSMediaPlayer
(c) Copyright 2017 by Intellectual Reserve, Inc. All rights reserved.
*/

/*
LDSMediaPlayer handles building, displaying, and controlling some media.

    import LDSMediaPlayer from 'lds-media-player';
    import YouTube from 'lds-media-player/adapters/youtube';
    import Vimeo from 'lds-media-player/adapters/vimeo';

    const myVideoPlayerElement = document.querySelector('#some-element');

    new LDSMediaPlayer(myVideoPlayerElement, {
        media: [
            new YouTube('Some YouTube id'),
            new Vimeo('Some Vimeo id')
        ]
    });

The code above will attempt to build a YouTube player and append it as a child of `myVideoPlayerElement`. If the YouTube player is not able to be built, it will attempt to build a Vimeo player.

The adapter modules should each export an object which can be used for creating an "asset." An asset is associated with a media platform (e.g., YouTube), knows how to build a player for that platform, and tells an `LDSMediaPlayer` object when to dispatch custom media events.
*/

import { postAnalytics } from "../../../utilities/analytics.js";
import dispatchEvent, { progressEvents } from "./events.js";
import download from "downloadjs";

/*
The default options for a new LDSMediaPlayer.
*/
const defaultOptions = {
  media: [],

  // How long each asset is allowed to try and build its player.
  buildTimeoutDelay: 5000,

  // How long to increase the timeout each failed cycle.
  buildTimeoutIncrease: 3000,

  // Whether to attempt autoplay. A media asset's `playerOptions` will override this. This feature may not be available on all devices.
  autoplay: false
};

// The LDSMediaPlayer
export default class LDSMediaPlayer {
  constructor(element, options) {
    // Ensure there is an `element`
    if (!element) {
      return Promise.reject(
        "LDSMediaPlayer: An `element` is required when creating a LDSMediaPlayer."
      );
    }

    this.element = element;

    // Merge options with the defaults
    this.options = { ...defaultOptions, ...options };

    // Separate any options meant for the asset build
    this.buildOptions = {
      autoplay: this.options.autoplay
    };

    // Ensure the `media` option is an array
    if (!Array.isArray(this.options.media)) {
      this.options.media = [this.options.media];
    }

    /*
        Create a reverse reference on the `element` to this instance of LDSMediaPlayer. Then you can do something like the following:

            document.querySelector('some-player-dom-element').LDSMediaPlayer.play()
        */
    this.element.LDSMediaPlayer = this;

    // A reference to "asset" which is created by an adapter.
    this.asset = undefined;

    // An internal object
    this.internal = {};

    // Dispatch the first progress event.
    dispatchEvent(this.element, progressEvents.LOADSTART);
  }

  // Returns a memoized Promise which resolves with a value of `this` once `this.asset` has been built. If no adapters are able to build, the Promise will be rejected.
  load() {
    // If errors occur during the promise chain, store them here so they can be returned as the reject value.
    const errors = [];

    if (!this.internal.load) {
      dispatchEvent(this.element, progressEvents.PROGRESS);

      // Use Array.prototype.reduce() to create a promise chain that determines if assets can be built for the LDSMediaPlayer in the order of `this.media` priority. We use a promise chain because there are asynchronous events that happen with each media's adapter, but these still need to occur in order. The chain begins with the Promise.resolve() sent as the second argument to `reduce`.
      this.internal.load = this.options.media
        .reduce((chain, asset) => {
          return chain
            .then(() => this.shouldTryToBuild(asset))
            .then(() =>
              asset.constructor.loadAPI(asset.options.API, asset.options.player)
            )
            .then(() => this.build(asset))
            .then(() => (this.asset = asset))
            .catch((reason) => {
              errors.push(reason);
              return false;
            });
        }, Promise.resolve())
        .then(() => {
          let result;

          if (this.asset) {
            dispatchEvent(this.element, progressEvents.LOAD);
            result = Promise.resolve(this);
          } else {
            dispatchEvent(this.element, progressEvents.ERROR, {
              errors: errors
            });
            result = Promise.reject(errors);
          }

          dispatchEvent(this.element, progressEvents.LOADEND);

          return result;
        });
    }

    return this.internal.load;
  }

  // Asset should be a Brightcove player
  async downloadVideo(asset) {
    // TODO: Investigate why there are console errors, even when the video downloads successfully
    try {
      await asset.constructor.loadAPI(asset.options.API);
      const booleanPlayerAttributes = [
        "autoplay",
        "compatibility",
        "controls",
        "loop",
        "muted",
        "playsInline",
        "preload"
      ];

      // create video element
      const video = document.createElement("video");
      video.classList.add("video-js");
      video.setAttribute("id", Math.random());
      video.setAttribute("data-video-id", asset.platform.id);
      video.setAttribute("data-account", asset.options.player.account);
      video.setAttribute("data-player", asset.options.player);
      // CUC-7339 setting the poster parameter to blank to skip the thumbnail image when the Brightcove video plays.
      video.setAttribute("poster", "");
      // ensure video is not visible to user
      video.style.display = "none";

      // add in boolean attributes
      booleanPlayerAttributes.forEach((attr) =>
        asset.options[attr] ? video.setAttribute(attr, "") : null
      );

      // add to DOM
      document.innerHTML = "";
      document.body.appendChild(video);

      this.brightcovePlayer = window.bc(video);

      const { videoURL, fileName } = await new Promise((resolve) => {
        this.brightcovePlayer.on("loadstart", () => {
          const fileName = this.brightcovePlayer.mediainfo["name"].replace(
            /\s/g,
            ""
          );
          // retreive the highest quality rendition of the brightcove video
          const renditions = this.brightcovePlayer.mediainfo.sources;
          const mp4Array = renditions
            .filter((r) => r.container === "MP4" && r.src)
            .sort((a, b) => b.size - a.size);
          const highestQuality = mp4Array[0].src;
          resolve({ videoURL: highestQuality, fileName });
        });
      });

      // request the video from brightcove
      const videoReq = new XMLHttpRequest();
      videoReq.open("GET", videoURL);
      videoReq.responseType = "blob";
      const waitForDownload = new Promise((resolve) => {
        videoReq.onload = () => {
          download(videoReq.response, fileName + ".mp4", "video/mp4");
          resolve();
        };
      });

      videoReq.send();

      // Analytics
      postAnalytics({
        event: window.digitalDataEventsCUC.component.download,
        component: {
          info: {
            name: fileName,
            link: videoURL
          },
          category: {
            primary: "Video"
          }
        }
      });

      return waitForDownload;
    } catch {
      throw new Error("Video download failed");
    }
  }

  // Throws an error if the LDSMediaPlayer should not even try to build with a given `asset`. We throw errors here instead of returning false so that it can be easily used in the build promise chain.
  shouldTryToBuild(asset) {
    if (this.asset) {
      throw new Error("An asset is already built.");
    } else if (!asset.platform.id) {
      throw new Error("No platform id", asset.platform);
    } else {
      return asset;
    }
  }

  /*
    Returns a "build Promise" that resolves when the given `asset` has built its platform player before a `buildTimeout` expires. The `asset` is responsible for clearing the `buildTimeout` once successful. Otherwise, the `buildTimeout` expires rejecting the build Promise.

    NOTE: The logic for a cookie-based "permanent" failing of platforms has been removed for now if favor of a timeout.
    */
  build(asset) {
    // Return a "build Promise"
    return new Promise((resolve, reject) => {
      // Start a timer that will reject the build Promise unless it is cleared before `this.options.buildTimeoutDelay`.
      const buildTimeout = setTimeout(() => {
        dispatchEvent(this.element, progressEvents.TIMEOUT, {
          asset: asset
        });

        // Now reject the build Promise for this build attempt
        return reject(
          `${asset.platform.name} took too long to build the asset.`
        );
      }, this.options.buildTimeoutDelay);

      // Now that the `buildTimeout` timer is running, have the `asset` attempt to build its platform player inside of `this.element`. Since `asset.build` returns a Promise, we can then resolve or reject this build Promise.
      return asset
        .build(this.element, this.buildOptions)
        .then((asset) => {
          clearTimeout(buildTimeout);

          asset.player = this;

          resolve(asset);
        })
        .catch(reject);
    });
  }

  // Resets by clearing the `asset` and `internal`
  reset() {
    // TODO: Move this increase to build()?
    // Increase the timeout
    this.options.buildTimeoutDelay =
      this.options.buildTimeoutDelay + this.options.buildTimeoutIncrease;

    this.asset = undefined;
    this.internal = {};
  }

  /*
    Returns a memoized Promise with a return value of an object containing information about the built asset:

        {
            title: "The media's title (from the platform)",
            platform: "The name of the platform",
            id: "The platform's ID for the media"
        }

    Note that this will load the LDSMediaPlayer first.
    */
  getInfo() {
    if (!this.internal.info) {
      this.internal.info = this.load()
        .then(() => this.asset.getInfo())
        .then(
          (info) =>
            (info = {
              ...info,
              platform: this.asset.platform.name,
              id: this.asset.platform.id
            })
        );
    }

    return this.internal.info;
  }

  // Returns a Promise with a Boolean value indicating whether the asset is currently paused. If a platform's player hasn't actually been built (no `asset` property assigned), return a resolved promise with the return value of true.
  getPaused() {
    return this.asset ? this.asset.getPaused() : Promise.resolve(true);
  }

  // Returns a Promise with a Boolean value indicating whether the asset is currently paused. If a platform's player hasn't actually been built (no `asset` property assigned), return a resolved promise with the return value of false.
  getPlaying() {
    return this.asset ? this.asset.getPlaying() : Promise.resolve(false);
  }

  // Plays the player after ensuring it has been loaded.
  play() {
    return this.load()
      .then(
        () => this.asset.play(),
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        console.log("An error occurred while attempting to play the video.");
        console.error(error);
      });
  }

  // Pauses the player after ensuring it has been loaded.
  pause() {
    return this.load()
      .then(() => this.asset.pause())
      .catch(console.error);
  }

  // Restarts the player after ensuring it has been loaded
  restart(time) {
    return this.load()
      .then(() => this.asset.seek(time))
      .then(() => this.asset.play())
      .catch(console.error);
  }

  // Sets the video to the set time
  seek(time) {
    return this.load()
      .then(() => this.asset.seek(time))
      .catch(console.error);
  }

  // Toggles the mute value of the player after ensuring it has been loaded.
  toggleMute(force) {
    return this.load().then(() => this.asset.toggleMute(force));
  }
}
