export default class SimpleCounter {
  constructor({ group, id, onDataIsSet, updateSocialCounter, counter }) {
    this.group = group;
    this.id = id;
    this.onDataIsSet = onDataIsSet;
    this.updateSocialCounter = (count) => {
      // before we run the passed in updateSocialCounter, lets do some things
      // if the new count is the same as a previously tracked count
      if (counter.prevCount === count) {
        // track/update how many times it has been the same
        counter.sameCount = counter.sameCount + 1 || 1;
      } else {
        // must have been different, so reset the sameCount
        counter.sameCount = 0;
      }
      // track how many iterations we've gone through so we can have an idea of how long people sit (purposefully or accidentally) watching the numbers go up
      counter.iteration = counter.iteration + 1 || 1;
      counter.prevCount = count;

      updateSocialCounter(count);
    };
    this.currentCounterValue = null;
    this.cachingInterval = 10000;
    this.counter = counter;
  }

  readSocialCounter() {
    // if the count has been the same a few times, skip a request to save on server load
    // the longer it has been the same (sameCount), the more times we will skip it (skipCount) to have a longer gap between requests
    if (
      this.counter?.sameCount > 0 &&
      this.counter?.skipCount < this.counter?.sameCount
    ) {
      // update that we have skipped it another time
      this.counter.skipCount = this.counter?.skipCount + 1 || 1;

      // return a promise that resolves immediately instead of doing the fetch/request
      return new Promise((resolve) => {
        return resolve();
      });
    } else {
      // initial setup of the skipCount counter
      this.counter.skipCount = 0;
    }

    // prettier-ignore
    const url = `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/readSocialCounter?group=${this.group}${this.id ? `&id=${this.id}` : ""}&timestamp=${this.getTimeStamp()}&iteration=${this.counter.iteration}`;
    return fetch(url)
      .then((raw) => {
        if (raw.ok) return raw.json();
        else throw new Error();
      })
      .then((res) => {
        const value = res.value;
        // if something cached is showing a lower value, we'll keep the highest remembered value
        this.currentCounterValue = Math.max(value, this.currentCounterValue);
        this.updateSocialCounter(this.currentCounterValue);
      });
  }

  checkForData() {
    const name = "participationCounter";

    if (localStorage.getItem(name)) {
      const value = JSON.parse(decodeURIComponent(localStorage.getItem(name)));
      const group = value[this.group];

      if (group && group.length > 0) {
        if (group.filter((i) => i === this.id).length > 0) {
          this.onDataIsSet();
        }
      }
    }
  }

  incrementSocialCounter(time, hashed, remainingTries) {
    const url = `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/incrementSocialCounter?group=${this.group}&id=${this.id}&transaction=${time}&code=${hashed}`;
    // show an update to the user immediately without changing the currentCounterValue
    this.updateSocialCounter(this.currentCounterValue + 1, true);
    return fetch(url, { method: "POST" })
      .then((raw) => {
        if (raw.ok) return raw.json();
        else throw new Error();
      })
      .then((res) => {
        // only set the data in localStorage if the response comes back as ok
        if (remainingTries < 1) this.setData();
        const value = parseInt(res.value);
        if (value > this.currentCounterValue) {
          this.currentCounterValue = value;
          // for user perception, if the increase is more than 1, we wait to display it
          setTimeout(() => this.updateSocialCounter(value, true), 10000);
        }
      });
  }

  setData() {
    const name = "participationCounter";
    let data;
    let group;

    if (localStorage.getItem(name)) {
      data = JSON.parse(decodeURIComponent(localStorage.getItem(name)));
      group = data[this.group];
    } else {
      data = {};
    }
    if (group) {
      if (!group.includes(this.id)) {
        group.push(this.id);
      }
    } else {
      group = [this.id];
    }

    data[`${this.group}`] = group;
    localStorage.setItem(name, JSON.stringify(data));
    this.onDataIsSet();
  }

  /*
    Used to break caching of counter web service calls every
    so often.
  */
  getTimeStamp() {
    return Math.round(new Date() / this.cachingInterval);
  }
}
