export function onUpOrDown(ifDown = () => {}, ifUp = () => {}, threshold = 0) {
  let startPos,
    prevPos = window.pageYOffset || document.documentElement.scrollTop;
  let startDirec,
    prevDirec = "up";
  let fullScroll =
    document.documentElement.scrollHeight -
    (window.outerHeight || window.innerHeight);

  function scrollFunction() {
    const pos = window.pageYOffset || document.documentElement.scrollTop;
    /* newPos checks that the pos is within the viewable html document */
    /* This prevents the animation from firing when mobile browsers bounce at the top and bottom */
    const newPos = pos > 0 && pos < fullScroll ? pos : prevPos;
    const newDirec = newPos > prevPos ? "down" : "up";

    if (newDirec !== prevDirec) {
      startPos = newPos;
    }

    const distance = Math.abs(startPos - newPos);

    if (distance > threshold && newDirec !== startDirec) {
      newDirec === "down" ? ifDown() : ifUp();
      startPos = newPos;
      startDirec = newDirec;
      fullScroll =
        document.documentElement.scrollHeight -
        (window.outerHeight || window.innerHeight);
    }

    prevPos = newPos;
    prevDirec = newDirec;
  }

  document.addEventListener("scroll", scrollFunction);
}

export function scrollIntoViewIfNeeded(element, options = {}) {
  /* This function mainly exists because "smooth" behavior in element.scrollIntoView and element.scrollIntoViewIfNeeded is not supported across all browsers */
  const { block = "start", inline = "nearest", debug } = options;
  let { behavior = "auto" } = options;
  if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
    behavior = "auto";
  }

  const clientRect = (tolerance = 0) => {
    const rect = element.getBoundingClientRect();
    const { top, left, bottom, right, height, width } = rect;
    const vpHeight = window.innerHeight;
    const vpWidth = window.innerWidth;
    const gapTop = Math.abs(top - 0);
    const visTop = gapTop <= tolerance;
    const visBottom = Math.abs(bottom - vpHeight) <= tolerance;
    const visVertical = top >= 0 && bottom <= vpHeight;
    const gapLeft = Math.abs(left - 0);
    const visLeft = gapLeft <= tolerance;
    const visRight = Math.abs(right - vpWidth) <= tolerance;
    const visHorizontal = left >= 0 && right <= vpWidth;
    return {
      top,
      left,
      bottom,
      right,
      height,
      width,
      visTop,
      visBottom,
      visLeft,
      visRight,
      vpHeight,
      vpWidth,
      visHorizontal,
      visVertical,
      gapTop,
      gapLeft
    };
  };
  const {
    visHorizontal,
    visVertical,
    top: initialTop,
    left: initialLeft,
    bottom: initialBottom,
    right: initialRight,
    vpHeight: initialHeight,
    vpWidth: initialWidth
  } = clientRect();
  if (visVertical && visHorizontal) {
    debug &&
      console.log("%cAlready Visible 😎", "font-size: 2rem; color: limegreen;");
    return;
  } else if ("scrollBehavior" in document.documentElement.style) {
    element.scrollIntoView({ block, inline, behavior });
  } else if (behavior === "auto") {
    element.scrollIntoView({ block, inline });
  } else {
    /*
    This entire else statement is only used because safari does not support "smooth" behavior for element.scrollIntoView
    It can be removed once safari supports "smooth" scrolling
    */
    debug && console.log("%cCatching", "font-size: 2rem; color: yellow;");

    let vDir;
    if (block === "start") {
      vDir = initialTop > 0 ? 1 : -1;
    } else if (block === "end") {
      vDir = initialBottom > initialHeight ? 1 : -1;
    } else {
      vDir = initialTop > initialHeight / 2 ? 1 : -1;
    }

    let hDir;
    if (inline === "start") {
      hDir = initialLeft > 0 ? 1 : -1;
    } else if (inline === "end") {
      hDir = initialRight > initialWidth ? 1 : -1;
    } else {
      hDir = initialLeft > initialWidth / 2 ? 1 : -1;
    }

    let vCondiditon;
    if (block === "nearest" && !visVertical) {
      vDir === 1
        ? (vCondiditon = ({ visBottom }) => visBottom)
        : (vCondiditon = ({ visTop }) => visTop);
    } else if (block === "center") {
      vCondiditon = ({ top, height, vpHeight }) =>
        Math.abs(top - (vpHeight / 2 - height / 2)) <= scrollConst;
    } else if (block === "start") {
      vCondiditon = ({ visTop }) => visTop;
    } else if (block === "end") {
      vCondiditon = ({ visBottom }) => visBottom;
    } else {
      vCondiditon = () => true;
    }

    let hCondition;
    if (inline === "nearest" && !visHorizontal) {
      hDir === 1
        ? (hCondition = ({ visRight }) => visRight)
        : (hCondition = ({ visLeft }) => visLeft);
    } else if (inline === "center") {
      hCondition = ({ left, width, vpWidth }) =>
        Math.abs(left - (vpWidth / 2 - width / 2)) <= scrollConst;
    } else if (inline === "start") {
      hCondition = ({ visLeft }) => visLeft;
    } else if (inline === "end") {
      hCondition = ({ visRight }) => visRight;
    } else {
      hCondition = () => true;
    }

    /* determines the pixels per interval the function scrolls */
    const scrollConst = 25;
    const parent = window;
    const lastPos = { lastTop: null, lastLeft: null };

    let scrollSpeed = scrollConst;
    const scrollIfNeeded = setInterval(() => {
      const rect = clientRect(scrollSpeed);
      scrollSpeed = Math.min(scrollConst, rect.gapTop / 2);

      const verticallyAligned = vCondiditon(rect);
      const horizontallyAligned = hCondition(rect);
      if (!verticallyAligned) {
        parent.scrollBy(0, vDir * scrollConst);
        debug &&
          console.log(
            "%cScrolling Vertially",
            "font-size: 1rem; color: lightgreen;"
          );
      }
      if (!horizontallyAligned) {
        parent.scrollBy(hDir * scrollConst, 0);
        debug &&
          console.log(
            "%cScrolling Horizontally",
            "font-size: 1rem; color: lightgreen;"
          );
      }
      const { top, left } = rect;
      const { lastTop, lastLeft } = lastPos;
      if (
        (verticallyAligned && horizontallyAligned) ||
        (top === lastTop && left === lastLeft)
      ) {
        debug &&
          console.log(
            "%cFinished Scrolling 😎",
            "font-size: 2rem; color: limegreen;"
          );
        clearInterval(scrollIfNeeded);
      }
      lastPos.lastTop = rect.top;
      lastPos.lastLeft = rect.left;
    }, 20);
  }
}
