/* global YT */
/*
LDSMediaPlayer YouTube Adapter
(c) Copyright 2017 by Intellectual Reserve, Inc. All rights reserved.
*/

/*
The YouTube adapter.

This default export for this module is an object which handles building a YouTube video player.
*/

import dispatchEvent, { mediaEvents } from "../events.js";

// This will hold a Promise once `YouTube.loadAPI` is called for the first time. We include this at this level so the actual request to get the API is made only once per page load.
let APILoadPromise;

// Set some default options
const defaultAPIOptions = {
  // How often to poll for the presence of `window.YT.Player` while trying to load the API.
  loadIntervalDelay: 20, // milliseconds

  // Because we will use an interval to test the presence of `window.YT.Player`, we need a way of clearing this interval if it's taking too long.
  loadTimeoutDelay: 10000, // milliseconds

  // YouTube doesn't fire "timeupdate" events, so we fake it at this interval. This must be smaller than `seekedEventThreshold`.
  timeupdateIntervalDelay: 300, // milliseconds

  // YouTube doesn't natively support a "seeked" event. Instead, when a user seeks, YouTube fires a "paused" state change, sometimes fires a "buffering" state change, and ends with a "playing" state change. If the difference in playback position between when it was paused and played is greater than or equal to the following, we will consider it a "seeked" event.
  seekedEventThreshold: 1000 // milliseconds
};

// Handles YouTube player state changes and dispatches necessary events. `event.target` will be the `YT.Player` instance.
function onStateChange(event) {
  // Ignore some states
  if (event.data === window.YT.PlayerState.CUED) {
    return;
  }

  // A reference to LDSYouTube instance was previously stored in the "onReady" function when creating the YouTube player:
  const asset = event.target.LDSYouTube;

  // Get the current playback position
  const currentPosition = asset.platform.player.getCurrentTime();

  // Pull out some asset options
  const { seekedEventThreshold, timeupdateIntervalDelay } = asset.options.API;

  // YouTube doesn't natively support a "seeked" event. Instead, when a user seeks, YouTube fires a "paused" state change, sometimes fires a "buffering" state change, and ends with a "playing" state change. We can fake a "seeked" event by detecting whether the difference between the `currentPosition` and previously stored time from the last state change is greater than `seekedEventThreshold`. (Modification of http://stackoverflow.com/a/29296014/538400). This assumes the `timeupdateIntervalDelay` is smaller than `seekedEventThreshold`.
  if (
    Math.abs(currentPosition - asset.eventStore.previousPosition) >=
    seekedEventThreshold / 1000
  ) {
    // Trigger the "seeked" event:
    asset.dispatch(mediaEvents.SEEKED);
  }

  // Now just do a switch on any other event types.
  switch (event.data) {
    case YT.PlayerState.PLAYING:
      asset.dispatch(mediaEvents.PLAY);

      // Start up the "timeupdate" interval that will fire the callbacks for the "timeupdate" event.
      asset.eventStore.timeupdateIntervalId = setInterval(
        asset.timeupdate.bind(asset),
        timeupdateIntervalDelay
      );
      break;

    case YT.PlayerState.PAUSED:
      // Clear the "timeupdate" interval if the YouTube player is not playing.
      clearInterval(asset.eventStore.timeupdateIntervalId);
      asset.dispatch(mediaEvents.PAUSE);
      break;

    case YT.PlayerState.ENDED:
      asset.dispatch(mediaEvents.ENDED);

      // Clear the "timeupdate" interval since the YouTube player is no longer playing.
      clearInterval(asset.eventStore.timeupdateIntervalId);
      break;
    case YT.PlayerState.BUFFERING:
      clearInterval(asset.eventStore.timeupdateIntervalId);
      asset.dispatch(mediaEvents.BUFFERING);
      break;
  }

  // Reset the "previousPosition". Note that the `Projector.prototype.timeupdate` function below also updates the "previousPosition" value.
  asset.eventStore.previousPosition = currentPosition;
}

// The YouTube object handles creating and controlling a YT.Player instance.
export default class YouTube {
  // Handles loading the adapter's API and returns a resolved Promise. This should only happen once per page load. This uses some of the same options as the constructor.
  static loadAPI(options = {}) {
    if (!APILoadPromise) {
      APILoadPromise = new Promise((resolve, reject) => {
        // Pull out some options
        const { loadTimeoutDelay, loadIntervalDelay } = {
          ...defaultAPIOptions,
          ...options
        };

        // Create an internal timeout so it doesn't continue indefinitely if `window.YT.Player` never shows up.
        const internalTimeout = setTimeout(() => {
          if (interval) {
            clearInterval(interval);
          }

          reject(
            "LDSMediaPlayer: YouTube: Internal timeout while loading API."
          );
        }, loadTimeoutDelay);

        // Set an interval which will clear itself once `window.YT.Player` is present. Once it is present, the APILoadPromise will be resolved.
        const interval = setInterval(() => {
          // Check if the YT API has made it onto the page from somewhere else.
          if (window?.YT?.Player) {
            clearTimeout(internalTimeout);
            clearInterval(interval);
            resolve();
          }
        }, loadIntervalDelay);

        // Now that the timeout and intervals are running, attempt to load the API. The YouTube iframe API CORS policy doesn't allow fetch, so we'll create a <script>.
        const firstScriptTag = document.getElementsByTagName("script")[0];
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      });
    }
    return APILoadPromise;
  }

  // Expects a YouTube video ID. The playerOptions are exactly as they are outlined in https://developers.google.com/youtube/player_parameters#Parameters
  constructor(id, playerOptions = {}, APIOptions = {}) {
    this.options = {
      player: playerOptions,
      API: { ...defaultAPIOptions, ...APIOptions }
    };

    // In order for the `onStateChange` function to work properly when it is called during the `build` function, we first check that intervals are valid
    if (
      this.options.API.timeupdateIntervalDelay >=
      this.options.API.seekedEventThreshold
    ) {
      return;
    }

    // Used to identify object class
    this.name = "youtube";

    // A place to store "internal" data such as memoized Promises.
    this.internal = {};

    // Set some platform properties
    this.platform = {
      name: "YouTube",

      // The ID of the YouTube video.
      id: id,

      // The platform's player object which will be set during build()
      player: undefined
    };

    // We need to store some things that will be used when determining which events to fire in the "onStateChange" callback.
    this.eventStore = {
      // A place to store the time update interval ID.
      timeupdateIntervalId: false,

      // YouTube doesn't natively support the "seeked" event. We will use this to calculate the time difference between state changes to detect if a "seeked" event actually happened.
      previousPosition: 0,

      // Because of how YouTube handles a "seeked" event, we will use this determine if the "play" event should actually fire.
      previousSeeked: false
    };
  }

  // Builds a YT.Player and appends it as a child of the given `element` DOM Element. Once the player is built and ready to control the returned build Promise is resolved. The return value of the Promise is `this`.
  build(element, options = {}) {
    return new Promise((resolve, reject) => {
      if (!element) {
        reject("An `element` is required.");
      }

      // Create a reference to the element
      this.element = element;

      // YT.Player requires an element that already exists in the DOM. However, because YouTube _replaces_ that element with its iframe, we can't use `element` since this may mess up the client's DOM. Instead we will create a temporary <div> which will be appended as a child of `element`.
      const playerElement = document.createElement("div");

      // For now, this seems to prevent the iframes from caching (http://stackoverflow.com/a/26191196/538400):
      playerElement.setAttribute("name", Math.random());

      // Now put the `playerElement` in the DOM.
      this.element.innerHTML = "";
      this.element.appendChild(playerElement);
      // Attempt to build the YT.Player. If this is successful, the `buildTimer` will be cleared and the promise resolved.
      new window.YT.Player(playerElement, {
        videoId: this.platform.id,
        // Send the merged player options
        playerVars: { ...options, ...this.options.player, ...this.options },
        events: {
          onReady: (event) => {
            // Create a reverse reference in the YT.Player instance to the Auditorium. This will be used in the "onStateChange" callback.
            event.target.LDSYouTube = this;

            // Set the platform player to the YT.Player instance.
            this.platform.player = event.target;

            this.dispatch(mediaEvents.LOADEDMETADATA); // YouTube needs to manually dispatch the loadedmetadata event, because normal eventListeners in the video components don't catch it

            return resolve(this);
          },
          onStateChange: onStateChange,
          onError: (event) =>
            reject("YouTube player was built with errors", event)
        }
      });
    });
  }

  // Dispatches the event for the given event `mediaEventType` with some data.
  dispatch(mediaEventType) {
    // Dispatch the event on `this.element`
    dispatchEvent(this.element, mediaEventType, {
      duration: this.platform.player.getDuration(),
      position: this.platform.player.getCurrentTime(),
      title: this.platform.player.videoTitle
    });
  }

  // This function will be called on the `timeupdate` event.
  timeupdate() {
    this.dispatch("timeupdate");
    this.eventStore.previousPosition = this.platform.player.getCurrentTime();
  }

  // Returns a memoized Promise which resolves with an object containing the asset info.
  getInfo() {
    if (!this.internal.info) {
      const data = this.platform.player.getVideoData();

      this.internal.info = Promise.resolve({
        title: data.title
      });
    }

    return this.internal.info;
  }

  // Whether the platform.player is currently paused
  getPaused() {
    return Promise.resolve(this.platform.player.getPlayerState() === 2);
  }

  // Whether the platform.player is currently playing
  getPlaying() {
    return Promise.resolve(this.platform.player.getPlayerState() === 1);
  }

  // Pauses the platform.player
  pause() {
    return Promise.resolve(this.platform.player.pauseVideo());
  }

  // Plays the platform.player
  play() {
    return Promise.resolve(this.platform.player.playVideo());
  }

  // Seeks the time designated on the platform.player
  seek(time) {
    return Promise.resolve(this.platform.player.seekTo(time));
  }

  // Toggles the mute value of the platform.player
  async toggleMute(force) {
    const muteBoolean = await Promise.resolve(
      typeof force !== "undefined" ? force : !this.platform.player.isMuted()
    );
    this.platform.player[muteBoolean ? "mute" : "unMute"]();
  }
}
