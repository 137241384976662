/*
LDSMediaPlayer Brightcove Adapter
(c) Copyright 2017 by Intellectual Reserve, Inc. All rights reserved.
*/

/*
The Brightcove adapter.

This default export for this module is an object which handles building a Brightcove video player.
*/

import dispatchEvent, { mediaEvents } from "../events.js";
import { makeGuid } from "../../../../utilities/common";
import { remoteComponentPublish } from "../../../remote-trigger/browser";

// This will hold a Promise once `Brightcove.loadAPI` is called for the first time. We include this at this level so the actual request to get the API is made only once per page load.
let APILoadPromise;

// The boolean player attributes which will need some special handling in `build`:
const booleanPlayerAttributes = [
  "autoplay",
  "compatibility",
  "controls",
  "loop",
  "muted",
  "playsInline",
  "preload"
];

// Set some default options
const defaultPlayerOptions = {
  // Brightcove player name
  player: "default",

  // Whether to include the Brightcove controls
  controls: true
};
const defaultAPIOptions = {
  // How often to poll for the presence of `window.bc` while trying to load the API.
  loadIntervalDelay: 20,

  // Because we will use an interval to test the presence of `window.bc`, we need a way of clearing this interval if it's taking too long.
  loadTimeoutDelay: 10000
};

export default class Brightcove {
  // Handles loading the adapter's API and returns a resolved Promise. This should only happen once per page load. This uses some of the same options as the constructor.
  static loadAPI(options = {}, optionsPlayer = {}) {
    if (!APILoadPromise) {
      APILoadPromise = new Promise((resolve, reject) => {
        // Pull out some options
        const { account, player, loadTimeoutDelay, loadIntervalDelay } = {
          ...defaultAPIOptions,
          ...options
        };

        if (!account) {
          reject('LDSMediaPlayer: Brightcove: "account" is required');
        }

        // Create an internal timeout so it doesn't continue indefinitely if the API never shows up.
        const internalTimeout = setTimeout(() => {
          if (interval) {
            clearInterval(interval);
          }

          reject(
            "LDSMediaPlayer: Brightcove: Internal timeout while loading API."
          );
        }, loadTimeoutDelay);

        // Set an interval which will clear itself and resolve APILoadPromise once the API is present.
        const interval = setInterval(() => {
          // Check if the Brightcove API has made it onto the page.
          if (window.bc) {
            clearTimeout(internalTimeout);
            clearInterval(interval);
            resolve();
          }
        }, loadIntervalDelay);

        // Now that the timeout and intervals are running, attempt to load the API.
        const tag = document.createElement("script");
        tag.src = `https://players.brightcove.net/${account}/${player}_default/index.min.js`;

        document.head.appendChild(tag);

        if (optionsPlayer.overlays) {
          const tag2 = document.createElement("script");
          tag2.src = `https://players.brightcove.net/videojs-overlay/2/videojs-overlay.min.js`;
          document.head.appendChild(tag2);
        }
      });
    }

    return APILoadPromise;
  }

  // Expects an `id` of the asset. The `playerOptions` are exactly as outlined in https://docs.brightcove.com/en/player/brightcove-player/guides/in-page-embed-player-implementation.html#attributes
  constructor(id, playerOptions = {}, APIOptions = {}) {
    // Handle the options
    playerOptions = { ...defaultPlayerOptions, ...playerOptions };
    this.options = {
      player: playerOptions,
      // Brightcove needs some of the `playerOptions` added to the APIOptions.
      API: {
        player: playerOptions.player,
        account: playerOptions.account,
        ...defaultAPIOptions,
        ...APIOptions
      }
    };

    // Used to identify object class
    this.name = "brightcove";

    // A place to store "internal" data such as memoized Promises.
    this.internal = {};

    // Set some platform properties
    this.platform = {
      name: "Brightcove",
      id, // The platform's ID for the asset.
      player: undefined // The platform's player object which will be set during build()
    };
  }

  // Creates a Brightcove player and appends it as a child of the given `element` DOM Element. Once the player is built and ready to control the returned, memoized build Promise is resolved. The return value of the Promise is `this`.
  build(element, options = {}) {
    return new Promise((resolve, reject) => {
      if (!element) {
        reject("An `element` is required.");
      }

      // Create a reference to the element
      this.element = element;

      // this.options.player will override.
      options = { ...options, ...this.options.player };
      const vidID = makeGuid();

      const video = document.createElement("video");
      video.classList.add("video-js");
      video.setAttribute("id", vidID);
      video.setAttribute("data-video-id", this.platform.id);
      video.setAttribute("data-account", options.account);
      video.setAttribute("data-player", options.player);
      // CUC-7339 setting the poster parameter to blank to skip the thumbnail image when the Brightcove video plays.
      video.setAttribute("poster", "");
      // Handle Brightcove's boolean attribute options
      booleanPlayerAttributes.forEach((attr) =>
        options[attr] ? video.setAttribute(attr, "") : null
      );

      // Now put the `video` in the DOM.
      this.element.innerHTML = "";
      this.element.appendChild(video);

      // Set the platform player to the Brightcove video instance.
      this.brightcovePlayer = window.bc(video);
      this.platform.player = this.brightcovePlayer;
      const brightcovePlayer = this.brightcovePlayer;

      if (options.captions) {
        let browserLanguage = document
          .querySelector("html")
          .getAttribute("lang");
        let runningIndex = -1;
        let finalIndex = -1;
        this.brightcovePlayer
          .textTracks()
          .addEventListener("addtrack", (event) => {
            const track = event.track;
            runningIndex++; // Keep a running tab of the indices
            if (
              track !== null &&
              track !== undefined &&
              track.language.substring(0, 2) === browserLanguage
            ) {
              finalIndex = runningIndex; // Grab the index of the captions matching the language of the browser
            }

            // Add event to show subtitles once it's loaded
            track.addEventListener("cuechange", () => {
              if (finalIndex >= 0) {
                this.brightcovePlayer.textTracks()[finalIndex].mode = "showing";
                finalIndex = 0;
              }
            });
          });
      }

      // eslint-disable-next-line no-undef
      videojs.getPlayer(vidID).ready(function () {
        var myPlayer = this;
        if (myPlayer.overlay && options.overlays) {
          if (options.overlays !== null && options.overlays !== undefined) {
            // loop through defined overlays
            options.overlays.overlays.forEach((overlay) => {
              // if the content starts with a # we presume it is a reference to an element on the page and grab that element and replace the content
              if (overlay.content.indexOf("#") === 0) {
                overlay.content = document.querySelector(overlay.content);
              }
            });
          }
          myPlayer.overlay(options.overlays);
        }
        // if there are remoteTriggers to call based on time, setup a timer
        if (options.timeTriggers) {
          const triggers = options.timeTriggers;
          setInterval(() => {
            // needs to be based on the played time, not the elapsed time since start (someone could pause or jump forward)
            const curTime = Math.round(brightcovePlayer.currentTime());
            if (options.timeTriggers[`second${curTime}`]) {
              remoteComponentPublish(triggers[`second${curTime}`]);
              // remove it so it doesn't fire multiple times
              delete triggers[`second${curTime}`];
            }
          }, 1000);
        }
        [...document.querySelectorAll(".vjs-dock-text")].forEach((element) => {
          element.style.visibility = "hidden";
        });
      });
      // allow access to brightcovePlayer from outside
      this.element.player = this.brightcovePlayer;

      // Setup event listeners
      // eslint says data is unused in the dispatcher, but when removed brightcove stops working. So ignoring the error
      // eslint-disable-next-line no-unused-vars
      const dispatcher = (eventType) => (data) => {
        if (!options.loop || options.dispatchEvents) {
          let title = "";
          if (
            brightcovePlayer !== null &&
            brightcovePlayer !== undefined &&
            brightcovePlayer.title !== null &&
            brightcovePlayer.title !== undefined &&
            brightcovePlayer.title.title !== null &&
            brightcovePlayer.title.title !== undefined
          ) {
            title = brightcovePlayer.title.title.innerHTML;
          }
          dispatchEvent(this.element, eventType, {
            duration: brightcovePlayer.duration(),
            position: brightcovePlayer.currentTime(),
            title
          });
        }
      };

      Object.values(mediaEvents).forEach((eventType) => {
        this.brightcovePlayer.on(eventType, dispatcher(eventType));
      });

      // Resolve once it's ready.
      this.brightcovePlayer.on("loadstart", () => {
        resolve(this);
      });

      // if we are looping, we hide the player while the video loads. Show it again via an event that fires when the video is ready to start playing
      if (options.loop) {
        this.brightcovePlayer.on("loadedmetadata", () => {
          element.parentElement.style.opacity = "1";
        });
      }
    });
  }

  // Returns a memoized Promise which resolves with an object containing the asset info:
  getInfo() {
    if (!this.internal.info) {
      this.internal.info = Promise.resolve({
        title: "Not implemented"
      });
    }

    return this.internal.info;
  }

  // Returns a Promise which resolves with a Boolean of whether the platform.player is paused
  getPaused() {
    return Promise.resolve(this.platform.player.paused());
  }

  // Returns a Promise which resolves with a Boolean of whether the platform.player is playing
  getPlaying() {
    return Promise.resolve(!this.platform.player.paused());
  }

  // Returns a Promise which resolves when the platform.player has paused.
  pause() {
    return Promise.resolve(this.platform.player.pause());
  }

  // Returns a Promise which resolves when the platform.player has begun playing.
  play() {
    return new Promise((resolve, reject) => {
      this.platform.player
        .play()
        .then(resolve, reject)
        .catch((error) => {
          console.log("An error occurred in the brightcove.js");
          console.error({ error });
        });
    });
  }

  // Seeks the time designated on the platform.player
  seek(time) {
    return Promise.resolve(this.platform.player.currentTime(time));
  }

  // Toggles the mute value of the platform.player
  async toggleMute(force) {
    const muteBoolean = await Promise.resolve(
      typeof force !== "undefined" ? force : !this.platform.player.muted()
    );
    this.platform.player.muted(muteBoolean);
  }
}
