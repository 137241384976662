import styles from "./styles.css";
import { scrollIntoViewIfNeeded } from "../scroll-logic/browser";
import { initComponent } from "../../utilities/common";

export default class InlineComponentGallery {
  constructor(element) {
    this.element = element;
    this.slider = element.querySelector('[data-type="thumbnail-slider"]');
    this.slider.addEventListener("gallery-item-select", (e) => {
      this.selectComponent(e.detail.galleryId, e.detail.shouldScroll);
    });
    this.components = element.querySelectorAll("[data-gallery-id-component]");
  }

  selectComponent(galleryId, shouldScroll) {
    const selectedBlock = this.element.querySelector(
      `[data-gallery-id-component="${galleryId}"]`
    );

    this.components.forEach((m) =>
      m === selectedBlock
        ? m.classList.add(styles.show)
        : m.classList.remove(styles.show)
    );

    window.dispatchEvent(new CustomEvent("pause-all-players"));

    /* Triggers image to render with a sensical aspect ratio */
    window.dispatchEvent(new Event("resize"));

    if (shouldScroll) {
      scrollIntoViewIfNeeded(this.element, {
        block: "nearest",
        behavior: "smooth"
      });
    }
  }
}

export const init = () => {
  initComponent(
    "inline-component-gallery",
    (element) => new InlineComponentGallery(element)
  );
};
init();
