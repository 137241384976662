const makeAPIUrl = (unitIds) =>
  `${
    window.PUBLIC_ENV.CONSOLIDATION_PREFIX
  }/api/missionaryPhoneNumbers?unitIds=${unitIds.join(",")}`;

const fetchphoneNumbers = (url) =>
  new Promise((resolve) => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        resolve({});
      });
  });

export default function getMissionaryPhoneNumbers(unitIds) {
  let unitIdsOnly = unitIds.map(({ unit }) => unit);
  if (!unitIds.length) return Promise.resolve({});
  else
    return new Promise((resolve) => {
      fetchphoneNumbers(makeAPIUrl(unitIdsOnly)).then((response) => {
        try {
          // eslint-disable-next-line no-undef
          // if there's no phone numbers, check at the stake / mission level
          const noNumbersArray = Object.entries(response)
            .filter(([, phoneNumbers]) => {
              return phoneNumbers?.length === 0;
            })
            .map(([unitId]) => {
              let punit = unitIds.find((i) => i.unit)?.parent;
              return { unitId, punit };
            });
          if (!noNumbersArray?.length) resolve(response);
          else {
            Promise.all(noNumbersArray).then((unitsWithParentIds) => {
              const parentIds = unitsWithParentIds.map(({ punit }) => punit);
              fetchphoneNumbers(makeAPIUrl(parentIds)).then(
                (parentResponse) => {
                  let keys = Object.keys(parentResponse);
                  keys.forEach((parent) => {
                    let unit = unitsWithParentIds?.find(
                      (o) => o?.punit === parent
                    )?.unitId;
                    if (unit !== undefined)
                      response[unit] = parentResponse[parent];
                  });
                  resolve(response);
                }
              );
            });
          }
        } catch {
          resolve(response);
        }
      });
    });
}
