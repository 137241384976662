export default {
  // Giving Machine placeholders
  name: "{name-placeholder}",
  title: "{title-placeholder}",
  details: "{details-placeholder}",
  id: "{id-placeholder}",
  link: "{link-placeholder}",
  socialLabel: "{socialLabel-placeholder}",
  socialLink: "{socialLink-placeholder}",

  // Meetinghouse placeholders
  content: "{content-placeholder}",
  form: "{form-placeholder}",
  address: "{address-placeholder}",
  triggerLabel: "{trigger-label-placeholder}",
  unitId: "{unit-id-placeholder}"
};
