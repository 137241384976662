import styles from "./styles.css";
import { initComponent, isOnMobile } from "../../utilities/common.js";

export default class ContentBlock {
  constructor(element) {
    this.element = element;
    this.styles = styles;
    this.dataExpandable = element.querySelector("[data-expandable]");
    this.showMoreBtn = element.querySelector("[show-more-btn]");
    this.showLessBtn = element.querySelector("[show-less-btn]");

    if (this.showMoreBtn && this.showLessBtn) {
      this.firstLoad = true;
      this.isPortrait = window.matchMedia("(orientation: portrait)").matches;
      this.isMobile = isOnMobile();
      window.addEventListener("resize", () => this.resizeShowLessBtn());
      this.resizeShowLessBtn();

      this.showMoreBtn.addEventListener("click", () => {
        this.showMoreBtn.hidden = true;
        this.showLessBtn.hidden = false;
        this.dataExpandable.setAttribute("data-expandable-expanded", "");
      });

      this.showLessBtn.addEventListener("click", () => {
        this.showLessBtn.hidden = true;
        this.showMoreBtn.hidden = false;
        this.dataExpandable.removeAttribute("data-expandable-expanded");
        this.dataExpandable.scrollIntoView({
          block: "center",
          behavior: "smooth"
        });
      });
    }
  }

  resizeShowLessBtn() {
    const portrait = window.matchMedia("(orientation: portrait)").matches;
    const isExpanded = this.dataExpandable.hasAttribute(
      "data-expandable-expanded"
    );

    if (this.isMobile) {
      if (portrait !== this.isPortrait || this.firstLoad) {
        this.cleanUp();
        this.recalculateShowLessBtn(isExpanded);
        this.isPortrait = portrait;
      }
    } else {
      this.cleanUp();
      this.recalculateShowLessBtn(isExpanded);
    }
    if (this.firstLoad) {
      this.firstLoad = false;
    }
  }

  recalculateShowLessBtn(isExpanded) {
    if (
      this.dataExpandable?.scrollHeight === this.dataExpandable?.clientHeight
    ) {
      this.showMoreBtn.hidden = true;
      this.showLessBtn.hidden = true;
      this.dataExpandable.setAttribute("data-expandable-expanded", "");
    } else if (
      this.dataExpandable?.scrollHeight > this.dataExpandable?.clientHeight &&
      !isExpanded
    ) {
      this.showMoreBtn.hidden = false;
      this.showLessBtn.hidden = true;
      this.dataExpandable.removeAttribute("data-expandable-expanded");
    } else {
      this.showMoreBtn.hidden = true;
      this.showLessBtn.hidden = false;
      this.dataExpandable.setAttribute("data-expandable-expanded", "");
    }
  }

  cleanUp() {
    this.dataExpandable.removeAttribute("data-expandable-expanded");
  }
}

export const init = () => {
  initComponent("content-block", (element) => new ContentBlock(element));
};
init();
