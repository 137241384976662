import { initComponent } from "../../utilities/common";
export default class FlickitySwipeWrapper {
  constructor(element) {
    this.element = element;

    this.element.addEventListener("touchstart", (e) => this.dragStart(e));
    this.element.addEventListener("touchmove", (e) => this.dragging(e));
  }

  dragStart(event) {
    this.startX = event.changedTouches[0].pageX;
  }

  dragging(event) {
    const minValue = 5;

    this.clientX = event.changedTouches[0].pageX - this.startX;

    if (Math.abs(this.clientX) > minValue && event.cancelable) {
      //Prevent vertical scroll when user is swiping horizontally
      event.preventDefault();
    }
  }
}
export const init = () => {
  initComponent(
    "swipe-wrapper",
    (element) => new FlickitySwipeWrapper(element),
    "[data-swipe-wrapper]"
  );
};
init();
