import { initComponent } from "../../../utilities/common";

export const init = () => {
  initComponent("text-area", (element) => {
    element.getValues = () => {
      const value = element.querySelector("textarea").value;
      const readValue = !element
        .querySelector("textarea")
        .hasAttribute("data-do-not-read");
      return value && value !== "" && readValue ? [value] : [];
    };

    element.checkIsValid = () => {
      const input = element.querySelector("textarea");
      if (input.required) {
        return input.value !== "" && input.value !== null;
      }
      return true;
    };
  });
};
init();
