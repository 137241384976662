import { initComponent } from "../../../utilities/common";
import formStyles from "../form-styles/styles.css";

export default class CheckBoxRadio {
  constructor(element) {
    const checkBox = element?.querySelector?.("input[type='checkbox']");
    if (checkBox) {
      const conditionalFieldName = element.dataset.conditionalField;
      const form = element.closest("form");
      const conditionalInputContainer =
        form && conditionalFieldName
          ? form.querySelector(`[data-type="${conditionalFieldName}"]`)
          : "";
      const conditionalInputField = conditionalInputContainer?.querySelector?.(
        `input.${formStyles.inputField}`
      );

      if (conditionalInputField) {
        // Changing the subscriptions name to be unique and will be changed back to the subscriptions value before submission
        const isPhone = conditionalFieldName === "phone-field";
        if (checkBox.name === "subscriptions")
          checkBox.name = isPhone ? "phoneSubscription" : "emailSubscription";

        const conditionalValidityMessage = element.dataset.conditionalMessage;
        const originallyRequired = conditionalInputField.required;

        checkBox.addEventListener("change", () => {
          if (checkBox.checked) {
            conditionalInputField.required = true;
            conditionalInputContainer.dataset.conditionalFieldRequired =
              conditionalValidityMessage;
          } else {
            // If the user unchecks it
            delete conditionalInputContainer.dataset.conditionalFieldRequired;
            if (!originallyRequired) {
              // Original input wasn't required
              conditionalInputField.required = false;
            }
          }
          conditionalInputContainer.checkIsValid(false);
        });

        // Hides the checkbox until the user focuses the conditional input
        if (element.ariaHidden) {
          const parent = element.closest(`.${formStyles.formInputs}`); // We need to manually get rid of the extra margin/height of the parent element as well
          parent.hidden = true;
          conditionalInputField.addEventListener(
            "focus",
            () => {
              parent.hidden = false;
              element.style.setProperty(
                "max-height",
                `${element.scrollHeight}px`
              );
              element.ariaHidden = false;
            },
            { once: true }
          );
        }
      }
    }
  }
}

export const init = () => {
  initComponent("checkbox-radio", (element) => new CheckBoxRadio(element));
};
init();
