import styles from "./styles.css";
import cookies from "../cookies/browser";
import { initComponent } from "../../utilities/common";

export default class SimpleSignUp {
  constructor(element) {
    this.element = element;
    this.form = element.querySelector("form");
    this.listId = this.form.querySelector("input[name=sourceId]").value;
    this.offerType = this.form.querySelector("input[name=offerType]").value;
    this.thankYouPage = this.element.getAttribute("thankYouPage");
    this.input =
      this.form.querySelector("input[type=email]") ||
      this.form.querySelector("input[type=phone]") ||
      "";

    const close = element.querySelector(`.${styles.close}`);
    this.cookie = element.dataset.cookieName;
    this.consolidationStatus = element.dataset.consolidationstatus;

    this.form && this.form.addEventListener("submit", () => this.onSubmit());
    close && close.addEventListener("click", () => this.onClose());
  }

  onSubmit() {
    this.element.classList.add(styles.formSubmitted);
    this.cookie ? this.setCookie() : "";
    if (this.thankYouPage) {
      // Direct user to the designated Thank-You Page on a separate page
      window.location.href = this.thankYouPage;
    }
  }

  onClose() {
    this.element.hidden = true;
    this.cookie ? this.setCookie() : "";
  }

  setCookie() {
    cookies.set(this.cookie, true, 365, this.consolidationStatus);
  }
}

export const init = () => {
  initComponent("simple-sign-up", (element) => new SimpleSignUp(element));
};
init();
