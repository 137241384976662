import { renderChecker } from "../server.js";

export default (props) => {
  return renderChecker(component, props, "svg");
};

export const component = ({ name = "", title }) => {
  return `<span data-type="svg" data-name="${name}" ${
    title ? `data-svg-title="${title}"` : ""
  } analytics-title="${title || name}"></span>`;
};
