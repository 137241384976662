import cookies from "../cookies/browser";
import { initComponent } from "../../utilities/common";

export default class CookieBasedDisplay {
  constructor(element) {
    this.element = element;
    this.cookieName = element.getAttribute("data-cookiename");
    this.hideOrShow = element.getAttribute("data-hideorshow");
    element.reevaluate = () => {
      this.evaluate();
    };
    this.evaluate();
  }
  evaluate = () => {
    if (this.cookieName) {
      const cookieVal = cookies.get(this.cookieName);
      // if we show when we have a cookie and have a cookie, or if we hide when we have a cookie but don't have a cookie, show it
      this.element.hidden = !(
        (cookieVal && this.hideOrShow === "show") ||
        (!cookieVal && this.hideOrShow === "hide")
      );
    }
  };
}
export const init = () => {
  initComponent(
    "cookie-based-display",
    (element) => new CookieBasedDisplay(element)
  );
};
init();
