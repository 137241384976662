const displayDependencyIndicator = "display-dependency"; // if updated, change index.js as well
import { triggerAttribute as triggerIndicator } from "../../remote-trigger/browser";

export class DisplayDependency {
  constructor(element) {
    this.element = element;
    this.dependencies = DisplayDependency.makeDependencies(element);

    this.toggleDisplay();
  }

  static makeDependencies(element) {
    /*
      converts an element that has attributes with display-dependency-${id}="${value}" into an object
      {
        display-dependency-2545: {
          id: 2545,
          trigger: <input data-remoteTriggerId="2545">,
          targetValue: true,
          currentValue: false
        },
        // more display-dependencies could follow
      }
    */
    const dds = {};
    for (let attr of element.attributes) {
      if (attr.name.includes(displayDependencyIndicator)) {
        const id = attr.name.replace(displayDependencyIndicator + "-", "");
        const trigger = document.querySelector(
          `[data-${triggerIndicator}='${id}']`
        );
        const value = attr.value;
        const currentValue = DisplayDependency.getValue(trigger);
        /* converts the strings "true" or "false" to booleans if the trigger is type="checkbox" */
        const targetValue =
          typeof currentValue === "boolean" ? value === "true" : value;
        dds[attr.name] = {
          id,
          trigger,
          targetValue /* in the future, targetValue could be changed to an array of targetValues */,
          currentValue
        };
      }
    }
    return dds;
  }

  static getValue(inputElement) {
    return inputElement?.type === "checkbox" || inputElement?.type === "radio"
      ? inputElement.checked
      : inputElement?.value;
  }

  updateDependency(id) {
    const dep = this.dependencies[`${displayDependencyIndicator}-${id}`];
    if (dep) {
      dep.currentValue = DisplayDependency.getValue(dep.trigger);
      this.toggleDisplay();
    }
  }

  toggleDisplay() {
    if (this.isFulfilled) this.element.removeAttribute("hidden");
    else this.element.setAttribute("hidden", "");
  }

  get isFulfilled() {
    /* Checks if any of the this.dependencies currentValue === targetValue */
    return (
      Object.values(this.dependencies).filter(
        ({ currentValue, targetValue }) => currentValue === targetValue
      ).length > 0
    );
  }
}

/* Delegates a group of display dependencies to one message event listener */
export class Dependencies {
  constructor(element) {
    this.displayDependencies = element
      ? Dependencies.makeDependencies(element)
      : [];
    this.isWatching = false;
    this.boundMsgHandler = this.handleMessage.bind(this);
  }

  static makeDependencies(parentElement) {
    return [...parentElement.querySelectorAll(`[display-dependencies]`)].map(
      (elem) => new DisplayDependency(elem)
    );
  }

  watch() {
    window.addEventListener("message", this.boundMsgHandler);
    this.isWatching = true;
    return this;
  }

  unwatch() {
    window.removeEventListener("message", this.boundMsgHandler);
    this.isWatching = false;
    return this;
  }

  handleMessage(e) {
    const id = e?.data?.remoteId;
    const isTrigger = e?.data?.type === triggerIndicator;
    const isDisplayDependency = document.querySelector(
      `[${displayDependencyIndicator}-${id}]`
    );
    if (id && isTrigger && isDisplayDependency) {
      const affectedDDs = this.displayDependencies.filter(
        (item) => item.dependencies[`${displayDependencyIndicator}-${id}`]
      );
      affectedDDs.forEach((dd) => dd.updateDependency(id));
    }
  }
}
