import {
  initComponent,
  toggleTrueFalseAttribute
} from "../../utilities/common";

export const setAriaExpanded = (element) => {
  toggleTrueFalseAttribute(element, "aria-expanded");
};

export const init = () => {
  initComponent("floating-action-button", (element) => {
    const remoteTriggerId = element.dataset.remoteTriggerId;

    //If the button triggers the opening of an overlay, listen for overlay to close to set aria-expanded to false
    remoteTriggerId
      ? document.addEventListener("overlay-closing", (e) => {
          if (e.detail.remoteTriggerId === remoteTriggerId)
            setAriaExpanded(element);
        })
      : "";

    element.addEventListener("click", () => {
      setAriaExpanded(element);
    });
    element.toggle = () => {
      setAriaExpanded(element);
    };
    element.hide = () => {
      element.setAttribute("hidden", "");
    };
    element.show = () => {
      element.removeAttribute("hidden");
    };
    element.open = () => {
      element.setAttribute("aria-expanded", "true");
    };
    element.close = () => {
      element.setAttribute("aria-expanded", "false");
    };
  });
};
init();
