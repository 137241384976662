import styles from "./styles.css";
import formStyles from "../form-styles/styles.css";
import SteppedForms from "../../stepped-form/browser";
import { initComponent } from "../../../utilities/common";
import { AsYouType } from "libphonenumber-js";

export default class Phone {
  constructor(element) {
    this.element = element;
    this.flagDisplay = element.querySelector(`.${styles.displayedFlag}`);
    this.countryDropdown = element.querySelector(`select`);
    const geocountry = document
      ?.querySelector('meta[name="geocountry"]')
      ?.getAttribute("content");
    this.phoneInput = element.querySelector(`[type="tel"]`);
    this.phoneCountryCode = element.querySelector('[name="phoneCountryCode"]');
    this.phoneCountryAlpha3 = element.querySelector(
      '[name="phoneCountryAlpha3"]'
    );
    this.phoneCountry = element.querySelector('[name="phoneCountry"]');
    this.countryCodeOutput = element.querySelector("[country-code-output]");
    this.customWhitelist = element.querySelector('[name="customWhitelist"]');
    this.reqForm = element.closest("form");
    this.currentFlag;
    this.malformedMessage = element.dataset.malformed;
    this.requiredMessage = element.dataset.required;
    this.notInWhitelistMessage = element.dataset.notInWhitelist;
    this.validityMessageContainer = this.element.querySelector(
      "[data-custom-message]"
    );
    this.label = this.element.querySelector("label");

    this.phoneInput.addEventListener("input", (event) => {
      this.phoneInput.value = this.phoneInput.value.replace(/[^0-9\s]/gi, "");
      this.phoneInput.value = this.phoneInput.value.replace(
        /\+[0-9]{1,3}/gi,
        ""
      );

      this.phoneInput.value = new AsYouType(
        this.phoneCountry.value.toUpperCase()
      ).input(event.target.value);
    });

    this.phoneInput.addEventListener("blur", () => {
      if (this.label && !this.phoneInput.value) {
        this.countryCodeOutput.classList.remove(styles.countryCodePadding);
      }
      this.checkIsValid(false);
    });

    this.phoneInput.addEventListener("focus", () => {
      // Adds padding to the countryCode. this can be done in css when the :has selector is fully supported
      if (this.label)
        this.countryCodeOutput.classList.add(styles.countryCodePadding);
    });

    // Intercept and handle a change to Input element before formatter is applied. When adding keyup/keydown events to phoneInput element, refactor beforeinput logic.
    // Events flow: keydown -> beforeinput -> keyup -> input
    // Replacing formatter AsYouType section would require adding 2 new formatters from same library and also adding new sections for events: change, keyup/keydown to reflect as you type formatting.
    // Instead of modifying multiple sections, we're adding an extra layer with beforeinput to handle the removal of closing parentheses and preceding number.
    this.phoneInput.addEventListener("beforeinput", (event) => {
      if (event.inputType !== "deleteContentBackward") return;
      // check non-selected text only
      const isSelection =
        event.target.selectionEnd - event.target.selectionStart > 0;
      if (
        !isSelection &&
        this.phoneInput.value.length > 1 &&
        event.target.selectionStart === this.phoneInput.value.length
      ) {
        const charToBeRemoved = event.target.value.substring(
          event.target.selectionStart - 1,
          event.target.selectionStart
        );
        // when closing unicode character marked for removal, remove preceding value as well
        if (charToBeRemoved.match(/\p{Pe}/gu) != null) {
          this.phoneInput.value = event.target.value.substring(
            0,
            event.target.selectionStart - 1
          );
        }
      }
    });

    // These functions are called in the Stepped Forms Submit Handler. They are used to check that the input field is valid and to retrieve the phone field input value
    element.getValues = () => this.getValues();
    element.checkIsValid = (asynchronous = true) =>
      this.checkIsValid(asynchronous);
    this.countryDropdown.addEventListener("change", () => {
      this.switchFlag(this.countryDropdown.value, true);
    });

    if (geocountry) {
      this.countryDropdown.value = geocountry;
      const newCountryIndex = this.countryDropdown.selectedIndex;
      const newCountryOption = this.countryDropdown.options[newCountryIndex];
      const parent = this.countryDropdown.parentNode;
      const flagDisplay = parent.querySelector(".phone-field_displayedFlag");
      if (flagDisplay)
        flagDisplay.style = newCountryOption?.getAttribute("style");
      const phoneCountryCode = parent.querySelector(
        '[name="phoneCountryCode"]'
      );
      if (phoneCountryCode)
        phoneCountryCode.value = newCountryOption?.dataset?.phoneCode;

      const phoneCountry = parent.querySelector('[name="phoneCountry"]');
      if (phoneCountry) phoneCountry.value = newCountryOption?.dataset?.value;

      const phoneCountryAlpha3 = parent.querySelector(
        '[name="phoneCountryAlpha3"]'
      );
      if (phoneCountryAlpha3)
        phoneCountryAlpha3.value = newCountryOption?.dataset?.alpha3;

      const customWhitelist = parent.querySelector('[name="customWhitelist"]');
      if (customWhitelist)
        customWhitelist.value = newCountryOption?.hasAttribute("in-whitelist");

      const countryCodeOutput = parent.querySelector("[country-code-output]");
      if (countryCodeOutput)
        countryCodeOutput.innerHTML = newCountryOption?.dataset?.phoneCode;

      const reqForm = parent.closest("form");
      let confirmationPrefComponent = reqForm?.querySelector(
        '[data-type="confirmation-pref"]'
      );
      if (confirmationPrefComponent) {
        confirmationPrefComponent.querySelector(
          '[value="SMS"]'
        ).disabled = true;
        confirmationPrefComponent
          .querySelector('[value="SMS"]')
          .removeAttribute("checked");
        confirmationPrefComponent.querySelector(
          '[value="SMS"]'
        ).checked = false;
        confirmationPrefComponent.querySelector(
          '[value="EMAIL"]'
        ).checked = true;
      }
    }
  }

  phoneValidation(req, resolve, reject) {
    let response = req.response;

    this.element.removeAttribute("checking"); // remove the loading animation

    if (!this.phoneInput.required && !this.phoneInput.value) {
      resolve();
    } else if (this.customWhitelist.value === "false") {
      reject(this.notInWhitelistMessage);
    } else if (this.phoneInput.required && !this.phoneInput.value) {
      const externalRequirement = this.element.dataset.conditionalFieldRequired; // This is used by the checkbox-radio, specifically with subscriptions which have custom errors
      reject(externalRequirement || this.requiredMessage);
    } else if (!response.valid) {
      reject(this.malformedMessage);
    } else {
      this.phoneInput.value = response.formatted;
      resolve();
    }
  }

  setValidity(asynchronous, isValid, validationMessage) {
    if (isValid) {
      SteppedForms.setAsValid(this.phoneInput);
      this.phoneInput.setCustomValidity("");
      return asynchronous && Promise.resolve(); // Only return a Promise if it's asynchronous
    } else {
      this.phoneInput.setCustomValidity(validationMessage);
      this.validityMessageContainer.innerText = validationMessage;
      SteppedForms.setAsInvalid(this.phoneInput);
      return asynchronous && Promise.reject();
    }
  }

  processFlagChange() {
    if (this.currentFlag === undefined) {
      const newCountryIndex = this.countryDropdown.selectedIndex;
      const newCountryOption = this.countryDropdown.options[newCountryIndex];
      this.currentFlag = newCountryOption;
    }
    this.phoneCountryCode.value = this.currentFlag.dataset.phoneCode;
    this.phoneCountry.value = this.currentFlag.dataset.value;
    this.phoneCountryAlpha3.value = this.currentFlag.dataset.alpha3;
    this.customWhitelist.value = this.currentFlag.hasAttribute("in-whitelist");
    let confirmationPrefComponent = this.reqForm.querySelector(
      '[data-type="confirmation-pref"]'
    );
    this.countryCodeOutput.innerHTML = this.phoneCountryCode.value;
    if (confirmationPrefComponent) {
      confirmationPrefComponent
        .querySelector('[value="SMS"]')
        .setAttribute("disabled", "disabled");
      confirmationPrefComponent
        .querySelector('[value="SMS"]')
        .removeAttribute("checked");
      confirmationPrefComponent.querySelector('[value="SMS"]').checked = false;
      confirmationPrefComponent.querySelector('[value="EMAIL"]').checked = true;
    }
  }

  switchFlag(countryCode, validate) {
    if (countryCode) this.countryDropdown.value = countryCode;
    const newCountryIndex = this.countryDropdown.selectedIndex;
    const newCountryOption = this.countryDropdown.options[newCountryIndex];
    this.currentFlag = newCountryOption;
    this.flagDisplay.style = newCountryOption.getAttribute("style");
    validate ? this.checkIsValid(false) : this.processFlagChange(); // when the page initially loads, there is no need to validate the phone field, but there is need to process the flag change which sets the country code
  }

  getValues = () => {
    const value = this.phoneCountryCode.value + " " + this.phoneInput.value;
    return value && value !== "" ? [value] : [];
  };

  checkIsValid = (asynchronous = true) => {
    return new Promise((resolve, reject) => {
      this.processFlagChange();
      this.element.setAttribute("checking", ""); // will cause a loading animation to be injected
      this.phoneInput.classList.remove(formStyles.invalid);
      this.phoneInput.classList.remove(formStyles.valid);
      const req = new XMLHttpRequest();
      req.addEventListener("load", () => {
        this.phoneValidation(req, resolve, reject);
      });
      let param = encodeURIComponent(
        `${this.phoneCountryCode.value} ${this.phoneInput.value}`
      );
      req.open(
        "POST",
        `${window.PUBLIC_ENV.CONSOLIDATION_PREFIX}/api/phoneVerify?phone=${param}&country=${this.currentFlag.dataset.value}`
      );
      req.responseType = "json";
      req.send();
    }).then(
      () => this.setValidity(asynchronous, true),
      (error) => this.setValidity(asynchronous, false, error)
    );
  };
}

export const init = () => {
  initComponent("phone-field", (element) => new Phone(element));
};
init();
