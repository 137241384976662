import { initComponent } from "../../../utilities/common";

export const init = () => {
  initComponent("text-field", (element) => {
    const input = element.querySelector("input");

    element.getValues = () => {
      const value = input.value;
      return value && value !== "" ? [value] : [];
    };

    element.checkIsValid = () => {
      if (input.required) {
        return input.value !== "" && input.value !== null;
      }
      return true;
    };
  });
};
init();
