import {
  initComponent,
  toggleTrueFalseAttribute
} from "../../utilities/common";

export default class CardTile {
  constructor(element) {
    const button = element.querySelector("[aria-expanded]");

    if (button) {
      button.addEventListener("click", () => {
        toggleTrueFalseAttribute(button, "aria-expanded");
      });
    }
  }
}
export const init = () => {
  initComponent("card-tile", (element) => new CardTile(element));
};
init();
