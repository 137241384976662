import styles from "./styles.css";
import formStyles from "../form-components/form-styles/styles.css";
import { makeGuid } from "../../utilities/common";
import verificationWarning from "../form-components/verificationWarning";
import labelComponent from "../form-components/label";
import { renderChecker } from "../server.js";

export default (props) => {
  return renderChecker(component, props, "select-box");
};

export const component = (props) => {
  const {
    name = "",
    label,
    options,
    validate = { include: false },
    questionGuid,
    guid = makeGuid(),
    componentsiteid,
    verificationDisplay = true,
    optionalDescription,
    questionDetail,
    noAutofill,
    addToNote
  } = props;
  // prettier-ignore
  return `
  <div class="${styles.selectBoxContainer} ${formStyles.formInputs}" data-type="select-box" data-select="${guid}" ${!verificationDisplay ? `hide-verification-display` : ""}>
    <select id="${guid}" ${componentsiteid ? `data-component-site-id="${componentsiteid}"` : ""}${questionGuid ? `data-question-guid="${questionGuid}"` : ""} name="${name || questionGuid}"  ${questionDetail ? `data-question-detail='${JSON.stringify(questionDetail)}'` : ""} ${addToNote ? `data-add-to-note="${addToNote}"` : ""} data-target-guid="${guid}" class="${formStyles.inputField}" ${validate.include ? "required" : ""} ${noAutofill ? `autocomplete="autocompleteDisabled"` : ""}>
      ${options?.map((option) => {
    return `
          <option value="${option?.value}" ${option?.code ? `data-country-code="${option?.code}"` : ""}  ${option?.alpha3 ? `data-country-alpha3="${option?.alpha3}"` : ""}>
            ${option?.text}
          </option>`;
  })
    .join("")}
    </select>
  ${label ? labelComponent({label, id: guid}) : ""}
  ${validate.include || optionalDescription ? verificationWarning({label, verificationText: validate.message, optionalDescription}) : ""}
  </div>`;
};
